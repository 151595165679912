//
//  Footer Contact
//  ---------------------------------------------

.footer-contact {
    p {
        font-size: 1.3rem;
        color: $color-grey2;
    }

    a[href^="tel:"] {
        display: inline-block;
        margin-right: 10px;
        font-size: 1.8rem;
        font-weight: $font-weight__semibold;

        &:before {
            @include icon($icon: mobile);
            position: relative;
            top: 4px;
            margin-right: 7px;
        }
    }
}

//
//  Footer Column Contact Block
//  ---------------------------------------------

.footer-column--has-contact {
    .footer-column__content {
        padding-bottom: 60px;
    }

    .footer-contact {
        position: absolute;
    }
}
