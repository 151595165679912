//
//  Footer Bottom
//  ---------------------------------------------

.footer-bottom {
    padding: 30px 0;
    text-align: center;
}

.footer-bottom__links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;


    @include min-screen($screen__sm) {
        flex-direction: row;
    }

    ul {
        @include list-inline(0);
        margin-bottom: 0;
    }

    li {
        &:before {
            content: '•';
            margin: 0 10px;
        }
    }

    a {
        @include unstyled-link();
        font-size: 1.3rem;
    }

    small.copyright {
        font-size: 1.3rem;
    }

    > div {
        display: inline-block;
    }
}

.footer-bottom__title {
    margin-bottom: 20px;
    font-size: 1.6rem;
    font-weight: $font-weight__semibold;
}

.footer-bottom__text {
    p {
        font-size: 1.3rem;
    }
}
