.c-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 1.5em - 0.125;
}

.c-price__label {
  &::after {
    content: ':';
  }
}

.c-price__price {
  font-size: 1.375em;
}
