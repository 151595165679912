.pagebuilder-slider {
    position: relative;
    .slick-dots {
        display: block;
        margin: 6px auto;
        padding: 0;
        li {
            display: inline-block;
            list-style: none;
            margin: 0 4px;
            padding: 0;
            button {
                background: #fff;
                border: 1px solid $color-matt-black;
                border-radius: 20px;
                color: transparent;
                font-size: 2px !important;
                padding: 0;
                width: 10px;
                height: 10px;
                &:hover {
                    background: $color-matt-black;
                }
            }
            &.slick-active {
                button {
                    background: $color-matt-black;
                }
            }
        }
    }
    .slick-arrow {
        background: transparent;
        border: none;
        color: transparent;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 50%;
        width: 5px;
        z-index: 100;
        &:active {
            box-shadow: none;
            outline: none;
        }
        &:before {
            margin: 0;
            transform: scale(2);
            opacity: 0.5;
            @include icon($icon: 'angle-right');
        }

        &:hover {
            &:before {
                opacity: 0.8;
            }
        }
        &.slick-prev {
            margin-right: 0;
            left: 10px;
            &:before {
                transform: scale(-2);
            }
        }
        &.slick-next {
            margin-left: 0;
            right: 10px;
        }
    }
}

//
//  Media queries
//  ---------------------------------------------

@include min-screen($screen__xs) {
    .pagebuilder-slider {
        .slick-dots {
            li {
                margin: 0 6px;
                button {
                    width: 14px;
                    height: 14px;
                }
            }
        }
        .slick-arrow {
            width: 36px;
            &:before {
                transform: scale(4);
            }
            &.slick-next {
                right: 25px;
            }
            &.slick-prev {
                left: 15px;
                &:before {
                    transform: scale(-4);
                }
            }
        }
    }
}

@include min-screen($screen__m) {
    .pagebuilder-slider {
        .slick-arrow {
            width: 36px;
            &:before {
                transform: scale(4);
            }
            &.slick-next {
                right: 55px;
            }
            &.slick-prev {
                left: 15px;
                &:before {
                    transform: scale(-4);
                }
            }
        }
    }
}
