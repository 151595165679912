//
//  Typography
//  _____________________________________________

//
//  Secondary Text
//  _____________________________________________

$text-secondary__font-size: false;
$text-secondary__color: false;
$text-secondary__font-family: $font-family__secondary;
$text-secondary__font-weight: $font-weight__regular;
$text-secondary__line-height: false;
$text-secondary__font-style: false;

@mixin text-secondary(
    $_font-size: $text-secondary__font-size,
    $_color: $text-secondary__color,
    $_font-family: $text-secondary__font-family,
    $_font-weight: $text-secondary__font-weight,
    $_line-height: $text-secondary__line-height,
    $_font-style: $text-secondary__font-style
) {
    @include lib-typography(
        $_font-size: $_font-size,
        $_color: $_color,
        $_font-family: $_font-family,
        $_font-weight: $_font-weight,
        $_line-height: $_line-height,
        $_font-style: $_font-style
    );
    text-transform: none;
}

//
//  Text styles
//  _____________________________________________

@mixin text-large() {
    @include lib-typography(
        $_font-size: $font-size__l
    );
}

@mixin text-normal() {
    @include lib-typography();
}

@mixin text-small() {
    @include lib-typography(
        $_font-size: $font-size__s,
        $_line-height: $line-height__xl
    );
}

//
//  Lists
//  ---------------------------------------------

@mixin list-unstyled() {
    list-style: none;
    padding-left: 0;
}

@mixin list-inline(
    $padding: 0 10px 0 0
) {
    @include list-unstyled;

    li {
        display: inline-block;
        padding: $padding;
    }
}

//
//  Unstyled link
//  ---------------------------------------------

@mixin unstyled-link() {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}