.price-inputs-wrapper {
    display: flex;
    flex-direction: row;

    .price-from,
    .price-to {
        flex: 50%;
        display: flex;
        align-items: center;

        .label {
            text-transform: lowercase;

            & + span {
                margin-left: 0.5em;
                color: $color-grey3;
            }
        }
    }

    .price-from {
        text-align: left;
    }

    .price-to {
        text-align: right;
        justify-content: flex-end;
    }
}

// sass-lint:disable-all
input#price_from,
input#price_to {
    display: inline-block;
    width: 70px;
    color: $color-grey3;
}
// sass-lint:enable-all

.ui-slider {
    .ui-slider-handle {
        @extend .icon-slider-handle-left-dims;
        @extend .icon-slider-handle-left;
        width: 16px;
        height: 14px;
        border-radius: 0;
        margin-top: -7px;
        cursor: grab;

        &+.ui-slider-handle {
            @extend .icon-slider-handle-right-dims;
            @extend .icon-slider-handle-right;
        }

        @include max-screen($screen__l) {
            transform: scale(1.6);
        }
    }

    .ui-slider-range {
        height: 1px;
        background: $color-black;
        position: absolute;
    }
}

.ui-slider-horizontal {
    height: 1px;
    background: $color-grey5;
    position: relative;
    margin: 20px 20px 20px 7px;
}

button.price-filter-button {
    @include btn-small-white();
    display: inline-block;
    font-weight: normal;
    font-family: $font-family__base;
    float: right;
    font-size: 13px !important; // sass-lint:disable-line no-important
    margin-top: 20px;
    text-transform: lowercase;
    padding: 8px 10px;
}
