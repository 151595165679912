.c-button {
  display: inline-block;
  vertical-align: middle;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 1em;
  font-family: inherit;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  color: inherit;

  &:focus {
    outline: none;
  }
}

.c-button--full-width {
  display: block;
  width: 100%;
}

.c-button--primary {
  color: white;
  background: $color-gray-1;
  font-size: 1.125em;
  padding: 0.5em;
  font-weight: 600;
}

.c-button:disabled,
.c-button--disabled {
  background: $color-gray-3;
  cursor: not-allowed;
}

.c-button__icon-container {
  display: inline-block;
  vertical-align: middle;
  max-width: 1.5em;

  svg, img {
    max-width: 1.5em;
    height: 1.5em;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
  }

  img {
    display: inline-block;
    vertical-align: middle;
  }
}

.c-button__label {
  display: inline-block;
  vertical-align: middle;
  margin: 0 1ch;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.c-button--link {
  .c-button__label {
    text-decoration: underline;
  }

  &:hover,
  &:focus {
     .c-button__label {
      text-decoration: none;
    }
  }
}
