
.pagebuilder-column-group {
    margin-right: -20px;
    margin-left: -20px;
}

.pagebuilder-column {
    box-sizing: border-box;

    @include min-screen($screen__m) {
        padding-right: 20px !important; // sass-lint:disable-line no-important
        padding-left: 20px !important; // sass-lint:disable-line no-important

        &--no-inset-right {
            padding-right: 0 !important; // sass-lint:disable-line no-important
        }

        &--no-inset-left {
            padding-left: 0 !important; // sass-lint:disable-line no-important
        }
    }
}

@include max-screen($screen__m) {
    .pagebuilder-column-group {
        @include pb--responsive();
    }
}

@include screen($screen__s, $screen__m) {
    .pb--responsive-s {
        .pagebuilder-column-group {
            @include pb--unset-responsive();
        }
    }
}

@include screen($screen__xs, $screen__m) {
    .pb--responsive-xs {
        .pagebuilder-column-group {
            @include pb--unset-responsive();
        }
    }
}
