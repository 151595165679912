.pagebuilder-video-wrapper {
    min-height: 200px;
}

.pagebuilder-video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 60%;

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}

@include min-screen($screen__l) {
    .pagebuilder-video-container {
        padding-bottom: 50%;
    }

    .pagebuilder-column {
        .pagebuilder-video-container {
            padding-bottom: 60%;
        }
    }
}
