.autheos-video-widget {
    .autheos-videothumbnail {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: (9/16) * 100%; // calculate aspect ratio
    }
}

body.autheos-popup-open {
    overflow: visible !important;
}

.autheos-videothumbnail {
    &__language-label {
        display: none;
    }
}

body {
    .autheos-videothumbnail {
        background-color: $color-white;
    }
}

.autheos-popup__close {
    &:hover {
        background: none;
        border: none;
    }
}
