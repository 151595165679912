//
//  Footer Main
//  ---------------------------------------------

.footer-top__inner,
.footer-bottom__inner {
    padding-left: $layout__width-xs-indent;
    padding-right: $layout__width-xs-indent;
}

.footer-top {
    padding: 50px 0;
    background: $color-grey7;
}

//
//  Media queries
//  ---------------------------------------------

@include max-screen($screen__m) {

    .footer-bottom__inner {
        padding-bottom: 40px;
    }

}

@include min-screen($screen__m) {

    .footer-top__inner,
    .footer-bottom__inner {
        @include layout-inner();
    }

}

@include min-screen($screen__l) {

    .footer-top__inner {
        display: flex;
    }

}
