.product.info.detailed {
    [id^=table-row] {
        order: 20;
    }

    .attributes-container {
        [id^=table-row]:not(:last-child) {
            margin-bottom: $indent__l;
        }
    }

    #table-row-features {
        order: 10;
    }

    .items {
        .item {
            &.title {
                margin: 0;

                &:not(:first-child) {
                    margin-left: -1px;
                }

                .switch {
                    font-size: 1.8rem;
                    padding: $indent__s $indent__xl;
                    color: $color-grey3;
                    border-color: $border-color__base;
                    background-color: #f7f7f7;
                }

                &.active {
                    .switch {
                        color: $color-matt-black;
                        padding-bottom: #{1 + $indent__s}; // +1 px for overlapping the border of the content.
                        pointer-events: none;
                        background-color: white;
                    }

                    &:after {
                        @include icon($icon: angle-down);
                        display: none;
                    }
                }

                &:after {
                    @include icon($icon: angle-down);
                    display: none;
                }
            }

            &.content {
                margin-top: #{1 +  $indent__xl}; // +1 px for overlapping the border of the content.
                padding: $indent__l;
            }

            &:not(.active) {
                & + .item.content {
                    display: none;
                }
            }
        }
    }

    .table-wrapper,
    .additional-attributes-wrapper {
        width: 100%;

        .inner-wrapper {
            display: flex;

            .col.label {
                text-transform: uppercase;
                text-decoration: none;
                font-size: 2rem;
                font-weight: $font-weight__bold;
                color: $color-matt-black;
                margin-bottom: $indent__s;
            }

            .col.data {
                padding: 0;
                column-count: 2;
            }

            #table-col-features {
                ul {
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        list-style-type: none;
                        position: relative;
                        margin-left: 20px;

                        &:not(:last-child) {
                            margin-bottom: $indent__m;
                        }

                        &:before {
                            @include icon($icon: checkmark-small);
                            position: absolute;
                            left: -20px;
                            top: 8px;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    #table-col-features {
        column-count: 1;
    }
}

@include min-screen($screen__l) {
    #table-col-features {
        column-count: 2;
        margin-bottom: 0;
    }
}

@include min-screen($screen__m) {
    .additional-attributes-wrapper {
        flex-direction: column;

        .attributes-container {
            width: 33%;
            order: 20;
            padding-left: $indent__s;
        }

        .features-container {
            width: 66%;
            order: 10;
            padding-right: $indent__s;
        }

        #table-col-features {
            ul {
                li {
                    margin-right: $indent__xl;
                }
            }
        }
    }

    .content__show {
        display: none;
    }

    .product.data.items {
        .item {
            &.content {
                &[aria-hidden="true"] {
                    opacity: 0;
                    border: 1px solid white;
                }

                &[aria-hidden="false"] {
                    opacity: 1;
                    border: 1px solid $border-color__base;
                }
            }
        }
    }
}

@include max-screen($screen__m) {

    .product.info.detailed {
        position: relative;
        width: 100vw;
        left: calc(-50vw + 50%);

        .items {
            &:last-child {
                border-bottom: 1px solid $border-color__base;
            }

            .items {
                &:last-child {
                    border-bottom: none;
                }
            }

            .item {
                &.title {
                    position: relative;


                    .switch {
                        border: none;
                        background: none;
                        padding: 0 20px;
                        border-top: 1px solid $border-color__base;
                    }

                    &:after,
                    &.active:after {
                        display: inline-block;
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        transition: 0.1s ease-in-out;
                        transition-delay: 0.1s;
                    }

                    &.active:after {
                        transform: rotate(180deg);
                    }
                }

                &.content {
                    margin-top: 0;
                    border: 0;
                    padding: 0 20px;
                    position: relative;
                    transition: 0.1s ease-in-out;

                    &.content--collapsable {
                        position: relative;
                        height: 50vh;
                        overflow: hidden;
                        margin-bottom: 0;

                        .content__show {
                            position: absolute;
                            //z-index: 100;
                            bottom: 0;
                            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 1) 100%);
                            padding: 40px 15px 20px;
                            width: 100%;
                            margin-left: -15px;
                            text-decoration: underline;
                        }
                    }

                    &.show-more--active {
                        .content__show {
                            position: static;
                            padding: 0 15px 20px;
                        }
                    }

                    &.show-more--active {
                        height: auto;
                        transition: 0.1s ease-in-out;
                    }
                }
            }
        }

        .additional-attributes-wrapper {
            #table-col-features {
                ul {
                    li {
                        margin-right: 15px;
                    }
                }
            }

            .inner-wrapper {
                flex-direction: column;

                .table-row {
                    width: 100%;

                    .col.label {
                        display: block;
                        text-transform: uppercase;
                        text-decoration: none;
                        font-size: 2rem;
                        font-weight: $font-weight__bold;
                        color: $color-matt-black;

                        &:before {
                            display: none;
                            content: none;
                        }
                    }

                    .col.data {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: $indent__l;
                        padding: 0;

                        &:before {
                            display: none;
                            content: none;
                        }
                    }
                }
            }
        }
    }
}
