//
//  Product Detail Page Filter header
//  ---------------------------------------------

$filter-offset-top: 50px;

.filter-header {
    margin-bottom: 16px;

    @include max-screen($screen__s) {
        display: flex;
        align-items: center;
    }
}

.filter-header__icon {
    display: block;
    width: 18px;
    height: 20px;
    position: relative;
    top: 1px;
    background-repeat: no-repeat;
    transform: scale(0.7);
}

.filter-header__title {
    font-size: 2rem;
    font-weight: $font-weight__semibold;
    text-transform: uppercase;
    margin-top: $filter-offset-top;
    display: inline-block;
    margin-right: 0.5em;

    @include min-screen($screen__l) {
        font-size: 2.5rem;
        margin-top: 0;
    }
}

.filter-header__label {
    color: $color-grey3;
    font-size: 1.6rem;
    margin-top: $filter-offset-top;

    @include min-screen($screen__l) {
        margin-top: 0;
        display: block;
    }
}

.filter-header__selected {
    @include max-screen($screen__s) {
        display: none;
    }
}

.filter-header__sticky {
    display: none;

    @include max-screen($screen__l) {
        align-items: center;
        position: fixed;
        top: 80px;
        right: 0;
        left: 0;
        padding: 0 40px;
        background-color: $color-grey7;
        z-index: 100;
    }

    @include max-screen($screen__m) {
        top: 0;
        padding: 5px 15px;
    }

    .filter--expanded & {
        @include max-screen($screen__l) {
            display: flex;
        }
    }
}

.filter-header__close-button {
    margin-left: auto;
    border-radius: 2px;
}

.filter-header__sticky-label {
    display: flex;
    align-items: center;
    color: $color-grey1;
    font-weight: $font-weight__semibold;
}

.filter-header__sticky-selected-filters {
    display: none;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    border-radius: 50%;
    background-color: $color-grey2;
    color: white;
    font-size: 1.2rem;

    &.is--active {
        display: inline-flex;
    }
}

.filter-header__sticky-button {
    margin-left: 15px;
}

// Clear filter button
.filter-header__button {
    display: none;
    justify-content: flex-end;
    font-size: 1.3rem;

    .filter-header__sticky & {
     
        &.is--active {
            display: flex;
        }
    }
   
    &.is--active {
        @include min-screen($screen__l) {
            display: block;     
            position: absolute;
            top: 0;
            right: 0;
            transform: translateY(-52px);   
        }
    }
}

.filter-header__button-label {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 25px;

    &::before {
        @extend .icon-trash-dims;
        @extend .icon-trash;
        position: absolute;
        left: 0;
        content: '';
        transform: scale(0.6);
    }
}

.aw-layered-nav-popover__action {
    display: none;
}
