.minicart-wrapper {
    right: 10px;

    .action.showcart {
        width: 25px;

        &:hover::before,
        &::before,
        &.active::before {
            @include icon(
                $icon: 'cart-black'
            );
        }

        .counter.qty {
            position: relative;
            border-radius: 50%;
            background: $color-grey2;
            line-height: 16px;
            height: 16px;
            min-width: 10px;
            font-size: 1.2rem;
            border: 2px solid $color-white;
            top: -12px;
            left: -12px;
        }
    }

    .minicart-items-wrapper {
        border: none;
        padding: 30px;
        max-height: 40vh;

        .minicart-items {

            .product-item:not(:first-child) {
                border-top: 1px solid $color-grey6;
            }

            .update-cart-item {
                background-color: transparent;
                border: none;
                padding: 0;
                margin-top: 10px;
                margin-left: 10px;

                @include icon(
                    $icon: 'cw-refresh'
                );
            }
        }
    }

    .block.block-minicart {
        margin-top: 0;
        border: 1px solid $color-grey6;
        padding: 25px 30px;
        width: 330px;
        box-shadow: none;

        .minicart-items-wrapper {
            padding: 30px 20px 10px 20px;
        }

        .subtitle {
            display: block;
            font-weight: 400;
        }

        #top-cart-btn-checkout {
            @include btn-arrowed();
            font-size: 1.4rem;
            width: auto;
        }

        .block-content {
            #btn-minicart-close {
                &:before {
                    color: $color-grey6;
                }

                &:hover:before {
                    color: $color-grey4;
                }
            }

            > .actions {
                text-align: right;
                margin-top: 0;

                > .primary {
                    margin: 0;
                }
            }

            .subtotal {
                border-top: 1px solid $color-grey6;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 20px 0;
                margin: 0;

                .label {
                    font-size: 16px;
                }

                .price {
                    font-size: 16px;
                }
            }
        }

        .actions {
            .details-qty.qty {
                width: 100%;

                input {
                    margin-right: 0;
                }
            }

            .secondary {
                font-size: 1.4rem;
                margin-top: 11px;

                .action.delete {
                    &:before {
                        @include icon($icon: 'trash');
                    }
                }
            }
        }

        .subtotal {
            .label {
                font-size: 1.4rem;

                &:after {
                    font-size: 1.4rem;
                    content: ':';
                    margin-left: -2px;
                }
            }
        }

        .product-item {
            .product.actions {
                display: flex;
                flex-direction: row;
                float: none;
                margin: 0;
                justify-content: space-between;
            }
        }

        .product-item-details {

            &__subtitle {
                font-size: 1.4rem;
            }

            .details-qty {
                .label {
                    font-size: 1.4rem;
                }
            }

            .product-item-pricing {
                .price {
                    font-size: 16px;
                }
            }
        }

        .product-item-name {
            font-weight: 600;
            font-size: 1.6rem;
            margin: 0;
        }
    }
}

@include max-screen($screen__s) {
    .minicart-wrapper {
        margin-top: 0;

        .block.block-minicart {
            margin-top: 10px;
        }

        .minicart-items-wrapper {
            max-height: calc(100vh - 360px) !important; // sass-lint:disable-line no-important
        }
    }
}

@include min-screen($screen__m) {
    .minicart-wrapper {
        margin-left: 10px;

        .action.showcart {
            margin-top: 10px;
        }
    }
}


@include min-screen($screen__l) {
    .minicart-wrapper {
        margin-left: 20px;
    }
}

@include max-screen($screen__l) {
    .minicart-wrapper {
        .block-minicart {
            position: fixed;
            left: 0 !important; // sass-lint:disable-line no-important
            top: 0 !important; // sass-lint:disable-line no-important
            width: 100% !important; // sass-lint:disable-line no-important
            height: calc(100% - 50px) !important; // sass-lint:disable-line no-important
            margin-top: 0 !important; // sass-lint:disable-line no-important
        }

        .minicart-items-wrapper {
            max-height: calc(100vh - 320px);
        }
    }
}

.minicart-wrapper .block.block-minicart .block-content #btn-minicart-close:before {
    color: black;
}
