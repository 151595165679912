.service__header {
    margin-bottom: $spacer;

    @include min-screen($screen__m) {
        background: url('../images/service/tasty-colours.jpg');
        background-size: cover;
        padding: $spacer-xxl 0;
    }
}

.service__dialog {
    background: white;
    padding: $spacer 0;

    @include min-screen($screen__m) {
        width: 50%;
        margin: 0 auto;
        padding: $spacer;
    }
}

.service__search-form {
    display: flex;
}

.service__dialog-question {
    width: auto !important; // sass-lint:disable-line no-important
    flex-grow: 1;
    padding-left: 0 !important; // sass-lint:disable-line no-important
}
