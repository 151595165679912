.tbw-codebox {
    position: relative;
    display: inline-block;
}

.tbw-codebox__field {
    display: inline-block;
    margin-right: 10px;
    position: relative;
}

.tbw-codebox--multi {
    .tbw-codebox__field {
        &:first-child {
            &:before,
            &:after {
                content: '.';
                font-size: 20px;
            }

            &:before {
                margin-right: 10px;
            }

            &:after {
                margin-left: 10px;
            }
        }

        &:last-child {
            &:before {
                content: '-';
                font-size: 20px;
                margin-right: 10px;
            }
        }
    }
}


.tbw-form__field.success {
    .tbw-codebox__field:last-child:after {
        @include icon($icon: checkmark);
        position: absolute;
        right: -20px;
        top: 14px;
    }
}

input.tbw-codebox__input {
    width: 65px;
}

input.tbw-codebox__input--small {
    width: 35px;
}

input.tbw-codebox__input--large {
    width: 80px;
}