.newsletter-widget {
    &__fieldset {
        margin: 0 -15px;
    }

    &__button {
        @include btn-arrowed();
    }

    .field {
        padding: 15px;
        box-sizing: border-box;
    }

    .label {
        font-weight: $font-weight__bold;
    }

    .agreements {
        @include text-small();
        li {
            margin-bottom: 15px;
        }
    }
}

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__m) {
    .newsletter-widget {
        &__fieldset {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: space-between;
        }

        //.field {
        //    width: 50%;
        //}

        .field--actions {
            text-align: right;
        }
    }

}
