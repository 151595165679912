//
//  Auto suggest variables
//  ---------------------------------------------

$search-autocomplete__min-width: 298px;
$search-autocomplete-arrow-m__right: 21px;
$search-autocomplete-arrow-l__right: 186px;
$search-autocomplete-arrow-xl__right: 260px;
$search-left-m__width: 296px;

//
//  Auto suggest
//  ---------------------------------------------

.block-search {
    .search-autocomplete {
        min-width: $search-autocomplete__min-width;
        border: 0;

        //
        // Autocomplete arrow
        // ---------------------------------------------

        &:before,
        &:after {
            display: block;
            position: absolute;
            height: 0;
            width: 0;
            content: "";
            border-bottom-style: solid;
        }

        &:before {
            @include min-screen($screen__m) {
                top: 17px;
                right: $search-autocomplete-arrow-m__right;
                border: 6px solid transparent;
                border-bottom-color: $color-white;
                z-index: 99;
            }

            @include min-screen($screen__l) {
                right: $search-autocomplete-arrow-l__right;
            }

            @include min-screen($screen__xl) {
                right: $search-autocomplete-arrow-xl__right;
            }
        }

        &:after {
            @include min-screen($screen__m) {
                top: 15px;
                right: $search-autocomplete-arrow-m__right - 1;
                border: 7px solid transparent;
                border-bottom-color: $border-color__base;
                z-index: 98;
            }

            @include min-screen($screen__l) {
                right: $search-autocomplete-arrow-l__right - 1;
            }

            @include min-screen($screen__xl) {
                right: $search-autocomplete-arrow-xl__right - 1;
            }
        }
        //  ---------------------------------------------

        .amsearch-results {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 28px;
            padding-bottom: 0;
            background-color: $color-white;
            border: 0;

            @include min-screen($screen__m) {
                flex-direction: row-reverse;
                float: right;
                border: 1px solid $border-color__base;
            }

            .amasty-xsearch-autocomplete-image {
                width: 90px;
                float: left;
                margin-right: 10px;
            }

            .amasty-xsearch-loader {
                background-image: 'none';
            }

            //
            // Products
            // ---------------------------------------------

            .amsearch-products {
                box-sizing: border-box;
                flex: 2;

                .amasty-xsearch-highlight,
                .amsearch-highlight {
                    padding: 0;
                    background-color: transparent;
                    color: inherit;
                    font-weight: $font-weight__semibold;
                }

                &.-columns {
                    display: block;
                    padding: 15px 10px;
                    border-left: 0;

                    @include min-screen($screen__m) {
                        display: block; // override display flex from amasy module on min-screen m
                        padding: 25px 20px;
                        border-right: 1px solid $border-color__base;
                    }

                    @include min-screen($screen__l) {
                        @supports (display: grid) {
                            display: grid;
                            grid-template-columns: repeat(2, 50%);

                            .amasty-xsearch-block-header {
                                grid-column-start: 1;
                                grid-column-end: 3;
                            }
                        }

                        .ie11 &,
                        .ie10 & {
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: row;

                            .amasty-xsearch-block-header {
                                width: 100%;
                            }
                            .amsearch-item.product-item {
                                width: 50%;
                            }
                        }
                    }
                }

                &.-waste {
                    display: none;
                }

                .product-item {
                    width: 100%;
                    display: flex;
                }

                .product-item-name,
                .product-item .product.name a {
                    font-size: 1.8rem;
                }

                .product-item-subtitle {
                    display: block;
                    margin-bottom: 0;
                }

                // Hide reviews in autosuggest
                .product-item-subtitle + [data-bv-show="inline_rating"] {
                    display: none;
                }
            }

            .amasty-xsearch-block-header {
                @include lib-heading(h3);
                margin-top: 0;
                padding: 0 10px;
            }

            .amsearch-item {
                padding: 8px 10px;

                &:hover {
                    background: transparent;
                }

                .product-image-wrapper {
                    padding-bottom: 100% !important; // sass-lint:disable-line no-important
                }
            }
        }

        //
        // Autocomplete sidebar results
        // ---------------------------------------------

        .amsearch-leftside {
            box-sizing: border-box;
            flex: 1;
            padding: 0 10px 10px;

            @include min-screen($screen__m) {
                width: $search-left-m__width !important; // sass-lint:disable-line no-important
                padding: 10px 5px;
            }

            .amasty-xsearch-block-header {
                display: none;
            }

            .amsearch-leftside__block {
                display: none;

                &.amsearch-leftside__block--popular_searches {
                    display: block;
                }
            }

            a.item-name {
                color: $color-grey3;
                text-decoration: none;

                .amsearch-highlight  {
                    padding: 0;
                    background-color: transparent;
                    color: $color-grey1;
                    font-weight: $font-weight__regular;
                }
            }

            .amsearch-show {
                padding: 8px 10px;
            }

            a.amsearch-show__link {
                display: block;
                color: $color-grey1;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    //
    //  Override Amasty module css
    //  ---------------------------------------------

    .form .search-autocomplete {
        @include min-screen($screen__m) {
            margin: 0 -10px;
        }

        @include screen($screen__m, $screen__xl) {
            max-width: calc(76vw + 20px);
        }

        @include min-screen($screen__xl) {
            max-width: calc(88vw + 20px);
        }
    }

    .amsearch-form-container .search-autocomplete .amsearch-leftside .amsearch-products {
        background-color: $color-white;

        .amasty-xsearch-block-header {
            display: block;
            margin-top: 2rem;
        }

        &.-columns {
            padding: 0;
        }
    }
}

//
//  Mobile only (show auto results full screen)
//  ---------------------------------------------

@include max-screen($screen__m) {
    .block-search {
        .form .search-autocomplete {
            width: 100% !important; // sass-lint:disable-line no-important
            max-width: none;
            max-height: none;
            position: fixed;
            top: 30px;
            bottom: 57px;
            left: 0;
            right: 0;
            margin: 0;
            padding: 0;
            margin-top: 0 !important; // sass-lint:disable-line no-important
            background: $color-white;

            .amsearch-results {
                margin-top: 0;
            }

            .amsearch-leftside {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }
}
