.pb--need-help {
    @include pb--no-margin();
    @include pb--no-padding();

    @include screen($screen__xs, $screen__m) {
        .pagebuilder-column-group {
            @include pb--unset-responsive();
        }
    }

    @include min-screen($screen__xs) {
        figure,
        [data-content-type="text"] {
            width: calc(100% - 20px);
        }
    }

    img {
        width: 100%;
    }
}