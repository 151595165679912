.stories__grid-item--stamp {
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #F1EBCC;
    color: #000;
    position: absolute;
    top: 50vw;
    left: 0;
    width: 100%;
    height: auto;
    box-sizing: border-box;

    h1 {
        @media screen and (min-width: $screen__l) {
            font-size: 45px;
            line-height: 1;
            margin-bottom: 0.25rem;
        }
    }

    h2 {
        margin: 1.5rem 0 0;
        text-transform: uppercase;

        span {
            display: block;
            margin-bottom: 0.5rem;
            margin-right: 10px;
            float: left;
        }
    }

    @media screen and (min-width: $screen__xs) and (max-width: $screen__s - 1) {
        width: calc((100% / 3)*2);
        height: calc((100vw / 3)*3);
        top: calc(100vw / 3);
        left: 0;
    }

    @media screen and (min-width: $screen__s) and (max-width: $screen__m - 1) {
        width: 60%;
        height: 60vw;
        top: 20vw;
        left: 20%;
    }

    @media screen and (min-width: $screen__m) and (max-width: $screen__l - 1) {
        width: 60%;
        height: 60vw;
        top: 40vw;
        left: 20%;
    }
    @media screen and (min-width: $screen__l) {
        width: calc((100% / 7)*3);
        height: calc((100vw / 7)*3);
        top: calc((100vw / 7)*1);
        left: calc((100% / 7)*2);
    }

    @media screen and (min-width: $screen__xl) {
        height: calc((100vw / 7)*2);
    }

    @media screen and (min-width: 1600px) {
        width: calc((100% / 9)*3);
        height: calc((100vw / 9)*2);
        top: calc((100vw / 9)*1);
        left: calc((100% / 9)*3);
    }

    @media screen and (min-width: 2000px) {
        width: calc((100% / 9)*3);
        height: calc((100vw / 9)*1);
    }

    h1,
    h2 {
        color: black;
    }
}
