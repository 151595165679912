.hundred-index-index {
    .columns {
        display: none;
    }

    .fancybox-overlay {
        .fancybox-wrap {
            max-width: $layout__max-width;
            left: 0 !important; // sass-lint:disable-line no-important
            right: 0;
            margin: 0 auto;
        }

        .fancybox-inner {
            max-width: calc(#{$layout__max-width} - 30px);
        }
    }
}

.stories {
    width: 100vw;
    min-height: 100vh;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.stories__loader {
    @include lib-loader();
    position: absolute;
    background-color: $color-white;
}

.stories__grid {
    overflow:hidden;
    min-height: 100vh;
}
