//
//  Cart
//  _____________________________________________

.checkout-cart-index {

    .cart.item {
        a.product-item-photo {
            width: inherit;
        }

        a.action.action-edit {
            display: none;
        }
    }

    .cart.table-wrapper .actions-toolbar > .action {
        float: right;
        background: none;
        border: none;
        padding: 0;

        &:before {
            @include icon($icon: 'trash');
        }

        &:hover,
        &:active {
            background: none;
            border: none;
        }
    }

    .cart-container {
        
        @include max-screen($screen__m) {
            display: flex;
            flex-direction: column;

            .cart-sidebar-container {
                order: 2;
            }
        }

        .gift-item-block {
            display: none;
        }

        .actions {
            .action {
                @include lib-button-as-link();
                margin-left: $indent__l;

                &.clear {
                    display: none;
                }
            }

            .action.continue {
                background: none;
                color: $color-grey1;
                border: none;
                text-decoration: underline;

                &:hover,
                &:active,
                &:focus,
                &:visited {
                    background: none;
                    color: $color-grey1;
                    border: none;
                    text-decoration: underline;
                }
            }

        }
    }

    &.page-layout-2columns-left {
        .sidebar-additional {
            width: 30%;
            margin-bottom: 50px;
            padding: 15px;
            float: right;
        }

        .sidebar-additional p strong {
            text-transform: uppercase;
        }
    }
}


@include max-screen($screen__xl) {

    .checkout-cart-index {
        &.page-layout-2columns-left {
            .sidebar-additional,
            .column.main {
                width: 100%;
            }
        }
    }

}
