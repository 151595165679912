.ui-tabs {
    border: 1px solid $color-grey6;
}

.ui-tabs-nav {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid $color-grey6;

    .tab-header {
        display: inline-block;
        border-bottom: 1px solid $color-grey6;
        text-align: center;
        font-weight: 600;
        margin-bottom: -1px;

        &:first-child {
            border-left: none;

            &.ui-state-active {
                border-top: 0;
            }
        }

        &.ui-state-active {
            background: $color-grey7;
            border-top: 1px solid $color-grey6;
        }

        span {
            text-decoration: none;
        }
    }

}

@include max-screen($screen__m) {

    .ui-tabs-nav {
        display: grid;
        grid-auto-flow: column;
        scroll-snap-type: x proximity;
        white-space: nowrap;
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        .tab-header.ui-state-active {
            position: relative;
            background-color: transparent;
            border-top: none;

            &:after {
                width: 100%;
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                border-bottom: 3px solid $color-black;
                content: '';
            }
        }

        .tab-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            scroll-snap-align: start;
            background-color: white;
            border-radius: 8px;
            white-space: nowrap;
        }

        .tab-header .tab-title {
            text-decoration: none;
        }

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-track {
            display: none;
            height: 0;
            width: 0;
        }
    }

}

@include min-screen($screen__l) {
    .ui-tabs-nav {
        flex-direction: row;

        .tab-header {
            border-right: 1px solid $color-grey6;
            border-bottom: 1px solid transparent;

            &.ui-state-active {
                border-bottom-color: white;
                border-top: none;
                background: white;
                color: $color-grey3;
                text-decoration: none;
            }

            &:hover {
                background: $color-grey7;
                border-bottom-color: $color-grey6;
            }
        }
    }
}

a.ui-tabs-anchor {
    display: block;
    padding: 1em 2em;
    text-decoration: none;
}
