//
//  Margin and padding helpers
//  ---------------------------------------------

.pb--full-width {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
}

// .pb--margin-0
// .pb--margin-10 to .pb--margin-100
// .pb--padding-10 to .pb--padding-100

/* stylelint-disable length-zero-no-unit */ // sass-lint:disable-line no-css-comments
@for $i from 0 to 10 {
    .pb--margin-#{$i*10} {
        margin-left: #{$i * 10}px !important; // sass-lint:disable-line no-important
        margin-right: #{$i * 10}px !important; // sass-lint:disable-line no-important
    }

    .pb--padding-#{$i * 10} {
        padding-left: #{$i * 10}px !important; // sass-lint:disable-line no-important
        padding-right: #{$i * 10}px !important; // sass-lint:disable-line no-important
    }
}

@include max-screen($screen__s) {
    // .pb--margin-0-mobile
    // .pb--margin-10-mobile to .pb--margin-100-mobile
    // .pb--padding-10-mobile to .pb--padding-100-mobile
    @for $i from 0 to 10 {
        .pb--margin-#{$i * 10}-mobile {
            margin-left: #{$i * 10}px !important; // sass-lint:disable-line no-important
            margin-right: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }

        .pb--padding-#{$i * 10}-mobile {
            padding-left: #{$i * 10}px !important; // sass-lint:disable-line no-important
            padding-right: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }
    }
}

@include screen($screen__s, $screen__m) {
    // .pb--margin-0-tablet
    // .pb--margin-10-tablet to .pb--margin-100-tablet
    // .pb--padding-10-tablet to .pb--padding-100-tablet
    @for $i from 0 to 10 {
        .pb--margin-#{$i * 10}-tablet {
            margin-left: #{$i * 10}px !important; // sass-lint:disable-line no-important
            margin-right: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }

        .pb--padding-#{$i * 10}-tablet {
            padding-left: #{$i * 10}px !important; // sass-lint:disable-line no-important
            padding-right: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }
    }
}

@include min-screen($screen__m) {
    // .pb--margin-0-desktop
    // .pb--margin-10-desktop to .pb--margin-100-desktop
    // .pb--padding-10-desktop to .pb--padding-100-desktop
    @for $i from 0 to 10 {
        .pb--margin-#{$i * 10}-desktop {
            margin-left: #{$i * 10}px !important; // sass-lint:disable-line no-important
            margin-right: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }

        .pb--padding-#{$i * 10}-desktop {
            padding-left: #{$i * 10}px !important; // sass-lint:disable-line no-important
            padding-right: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }
    }
}
/* stylelint-enable */ // sass-lint:disable-line no-css-comments

//
//  Display helpers
//  ---------------------------------------------

.hidden-mobile {
    @include max-screen($screen__s) {
        display: none !important; // sass-lint:disable-line no-important
    }
}

.show-mobile {
    @include max-screen($screen__s) {
        display: block !important; // sass-lint:disable-line no-important
    }
}

.hidden-tablet {
    @include screen($screen__s, $screen__m) {
        display: none !important; // sass-lint:disable-line no-important
    }
}

.show-tablet {
    @include screen($screen__s, $screen__m) {
        display: block !important; // sass-lint:disable-line no-important
    }
}

.hidden-desktop {
    @include min-screen($screen__m) {
        display: none !important; // sass-lint:disable-line no-important
    }
}

.show-desktop {
    @include min-screen($screen__m) {
        display: block !important; // sass-lint:disable-line no-important
    }
}

//
//  Alignment helpers
//  ---------------------------------------------

.align-center {
    margin-right: auto !important; // sass-lint:disable-line no-important
    margin-left: auto !important; // sass-lint:disable-line no-important
}

//
//  Ratio helpers
//  ---------------------------------------------

.pb--ratio-8-5 {
    width: 100%;
    padding-top: 62.5% !important; // sass-lint:disable-line no-important
}

.pb--ratio-3-2 {
    width: 100%;
    padding-top: 66.66% !important; // sass-lint:disable-line no-important
}

.pb--ratio-4-3 {
    width: 100%;
    padding-top: 75% !important; // sass-lint:disable-line no-important
}

.pb--ratio-16-9 {
    width: 100%;
    padding-top: 56.25% !important; // sass-lint:disable-line no-important
}

.pb--ratio-1-1 {
    width: 100%;
    padding-top: 100% !important; // sass-lint:disable-line no-important
}

//
//  Custom helpers
//  ---------------------------------------------

.pb-has-widget > p {
    margin: 0;
}

.pb-no-overflow {
    overflow: hidden;
}

//
//  No padding / no margin
//  ---------------------------------------------

.pb--no-margin {
    @include pb--no-margin();
}

.pb--no-padding {
    @include pb--no-padding();
}
