//
//  Product Detail Page Swatch options
//  ---------------------------------------------

.swatch-attribute-options__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}

.swatch-attribute-options__list-item {
    display: flex;
    width: 50%;
    margin-bottom: 7px;

    // Overwrite defaull styling
    .swatch-option {
        display: flex;
        align-items: center;
        padding: inherit;
        min-width: inherit;
        max-width: inherit;
        height: inherit;
        float: inherit;
        margin: inherit;
        text-align: inherit;
        border: none;
        overflow: hidden;
        text-overflow: ellipsis;

        &.image:not(.disabled):hover,
        &.color:not(.disabled):hover {
            outline: none;
            border: 0;
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.7;

            &:after {
                content: '';
                background: none;
            }
        }

        &:hover {
            .swatch-option__label {
                text-decoration: underline;
            }
        }

        &.active {
            .swatch-option__bullet:after {
                @include icon($icon: 'checkmark-white');
            }

            .swatch-option__bullet.swatch-option__bullet--has-border:after {
                @include icon($icon: 'checkmark');
            }
        }
    }

    .swatch-option__bullet {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        border-radius: 50%;
        pointer-events: none;

        &:after {
            position: relative;
            top: 1px;
            transform: scale(0.8);
        }

        &.swatch-option__bullet--has-border {
            box-shadow: inset 0 0 0 1px $border-color__base;
        }
    }
}

.swatch-option__label {
    color: $color-grey3;
    cursor: pointer;
    pointer-events: none;
}
