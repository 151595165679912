//
//  RMA New Guest
//  ---------------------------------------------

.aw-rma-new-guest {
    // border: 1px solid red;

    .block {
        .block-title {
            font-size: 1.8rem;
            margin-bottom: $indent__l / 2;
            padding-bottom: 1.2rem;
            border-bottom: 1px solid $color-grey6;
        }

        .block-title strong {
            font-weight: 500;
        }

       
    }

    .block.block-guest-order {
        .actions-toolbar {
            margin-left: 0 !important; // sass-lint:disable-line no-important
        }
    }

    @include min-screen($screen__m) {
        .block {
            width: 48.8%;
        }

        .block:nth-child(odd) {
            clear: left;
            float: left;
        }

        .block:nth-child(even) {
            float: right;
        }
    }
    
}
