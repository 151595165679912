.c-summary {
  $bottom-spacing: 1.5em;
  list-style-type: none;
  padding: 0 0 ($bottom-spacing - 0.375);
  margin: 0 0 $bottom-spacing;
  border-bottom: 1px solid $color-gray-4;
}

.c-summary__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: -1em;
  margin-bottom: 0.25em;
  color: $color-gray-2;
}

.c-summary__row--chosen {
  .c-summary__button {
    color: $color-gray-1;
  }
}

.c-summary__row-container {
  padding-left: 1em;
  flex: 1 0 auto;

  &:first-child {
    flex: 1 1 auto;
    .c-summary__button {
      text-align: left;
    }
  }

  &:last-child {
    text-align: right;
  }
}

.c-summary__button {
  font-weight: 600;

  &:hover,
  &:focus,
  &.active {
    color: $color-gray-1;
  }
}
