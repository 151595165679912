.tbw-layout-warranty {
    @include min-screen($screen__s) {
        max-width: 60%;
    }

    input[type="radio"]:checked + label:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='7'%3E%3Ccircle cx='3.5' cy='3.5' r='3.5' fill-rule='evenodd'/%3E%3C/svg%3E");
        background-position: center center;
        background-repeat: no-repeat;
    }

    input[type="radio"]:checked + label:after {
        display: none;
    }
}

.tbw-layout-warranty__row {
    margin-bottom: $spacer-lg;

    .question__label {
        strong {
            display: block;
            font-weight: $font-weight__semibold;
        }
    }
}

.tbw-layout-warranty__warning {
    padding: $spacer-md;
    margin-bottom: $spacer;
    border: 5px solid $color-grey4;

    h2{
        margin-top: 0;
    }

    &.tbw-layout-warranty__warning--alert {
        h2:before {
            content: '!';
            width: 1em;
            height: 1em;
            display: inline-block;
            border-radius: 50%;
            margin-right: $spacer-xxs;
            background: $color-warm-red;
            color: $color-white;
            text-align: center;
            line-height: 1em;
        }
    }
}
