.price-box {
    display: flex;
    align-items: baseline;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .price-wrapper {
        .price {
            font-size: 1.8rem;
            font-weight: $font-weight__regular;
        }
    }

    .price-label {
        display: none;
    }

    .old-price {
        margin-right: $indent__s;

        .price-wrapper {
            .price {
                font-size: 1.3rem;
                font-weight: $font-weight__regular;
            }
        }
    }
}
