
.gallery {
    .gallery__image-list-item {
        .image-list__image {
            @include object-fit(cover);

            &.alignment-- {
                &top-left {
                    object-position: top left;
                }

                &top-middle {
                    object-position: top center;
                }

                &top-right {
                    object-position: top right;
                }

                &middle-left {
                    object-position: center left;
                }

                &middle-middle {
                    object-position: center;
                }

                &middle-right {
                    object-position: center right;
                }

                &bottom-left {
                    object-position: bottom left;
                }

                &bottom-middle {
                    object-position: bottom center;
                }

                &bottom-right {
                    object-position: bottom right;
                }

                @for $i from 1 through 10 {
                    &vertical-#{$i}0 {
                        object-position: 100% ($i * 10%);
                    }

                    &horizontal-#{$i}0 {
                        object-position: ($i * 10%) 100%;
                    }
                }
            }
        }
    }
}