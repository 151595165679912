//
//  Buttons
//  _____________________________________________

@mixin btn(
    $size: default,
    $hover-background-transition: true
) {
    @if $size == large {
        @include lib-button-l();
    } @else {
        @include lib-button();
    }

    @if $hover-background-transition == true {
        transition: background-color $button__transition__time $button__transition__timing__function;

        &:hover {
            transition: background-color $button__transition__time $button__transition__timing__function;
        }
    }

    @include lib-link-as-button();
    font-size: 1.5rem !important; // sass-lint:disable-line no-important
    line-height: 1;
}

@mixin btn-icon(
    $size: default,
    $icon: null,
    $scale: null,
    $position: before,
    $padding-offset: 37px,
    $icon-offset: 15px,
    $top: 9px,
    $load-icon: true
) {
    @include btn($size);
    position: relative;
    font-size: $font-size__base;

    @if $size == large {
        $padding-offset: $padding-offset + 8;
        $top: $top + 4;
        $scale: $scale + 0.25;
    }

    @if $position == after {
        padding-right: $padding-offset;
    } @else {
        padding-left: $padding-offset;
    }

    &:#{$position} {
        position: absolute;
        top: $top;

        @if $position == after {
            right: $icon-offset;
        } @else {
            left: $icon-offset;
        }

        @include icon(
            $icon: $icon,
            $scale: $scale,
            $load-icon: $load-icon
        );
    }
}

@mixin btn-arrowed(
    $size: default,
    $scale: 0.75,
    $top: 11px,
    $load-icon: true
) {
    @include btn-icon(
        $size: $size,
        $icon: arrow-right-white,
        $scale: $scale,
        $position: after,
        $top: $top,
        $load-icon: $load-icon
    );
}

@mixin btn-cart(
    $size: default,
    $scale: 0.75,
    $top: 5px,
    $load-icon: true
) {
    @include btn-icon(
        $size: $size,
        $icon: cart-white,
        $scale: $scale,
        $top: $top,
        $load-icon: $load-icon
    );
}

@mixin btn-link {
    padding: 0;
    background: transparent;
    border: none;
    color: $color-grey3;
    font-weight: normal;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
        background: transparent;
        color: $color-grey3;
        border: none;
    }
}

@mixin btn-secondary {
    background-color: white;
    border-color: $color-black;
    color: $color-black;

    &:hover {
        background-color: darken(white, 5%);
    }
}

@mixin btn-small-white() {
    padding: 5px 10px;
    border: 1px solid $color-grey1;
    background-color: $color-white;
    border-radius: 3px;
    color: $color-grey1;
    font-size: 13px;
    transition: none;

    &:hover {
        background-color: $color-black;
        border-color: $color-black;
        color: $color-white;
    }
}