span.product-label {
    top: $indent__m;
    right: $indent__m;
    background: transparent;
    border: 0;
    max-width: 100px;
    padding: 0;
    border-radius: 50%;
    
    .product-label-attribute--text,
    .product-label-discount {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-warm-red;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        color: white;
        font-weight: $font-weight__semibold;
        line-height: 1;
        text-transform: uppercase;
        text-align: center;
    }

    .product-label-discount {
        font-size: 2.2rem;
    }

    .product-label-attribute--text {
        font-size: 1.6rem;
        word-break: break-word;

        &.product-label--text-short {
            font-size: 2.2rem;
        }
    }
}

.product-item {
    span.product-label {
        max-width: 70px;
        top: $indent__s;
        right: $indent__s;

        .product-label-attribute--text,
        .product-label-discount {
            width: 55px;
            height: 55px;
        }

        .product-label-discount {
            font-size: 1.8rem;
        }

        .product-label-attribute--text {
            font-size: 1.4rem;

            &.product-label--text-short {
                font-size: 1.8rem;
            }
        }
    }
}

@include min-screen($screen__m) {

    span.product-label {
        .product-label-attribute--text,
        .product-label-discount {
            width: 77px;
            height: 77px;
        }
    }

}


@include max-screen($screen__m) {

    .product.media {
        span.product-label {
            top: 50px;
        }
    }

}
