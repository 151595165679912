//
//  Layout
//  _____________________________________________

html {
    height: 100%;

    @include max-screen($screen__m) {
        &.no-scroll,
        &.filter--expanded  {
            width: 100%;
            overflow: hidden;
            position: fixed;
        }

        &.no-scroll,
        .catalog-product-view {
            .message.global.demo {
                display: none;
            }
        }

    }
}

html.ie11,
html.ie11 body,
html.ie10,
html.ie10 body {
    height: auto !important; // sass-lint:disable-line no-important
    min-height: 100%;
}

@include max-screen($screen__xxl) {
    .page-wrapper > .page-bottom {
        max-width: 100vw !important; // sass-lint:disable-line no-important
    }
}

@include min-screen($screen__sm) {
    .page-wrapper > .page-bottom {
        width: 100%;
        margin: 0 auto;
    }
}
