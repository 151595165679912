//
// All
// -------------------

.gallery {
    margin: 0 0 20px;
    overflow: hidden;

    &.gallery--device {
        overflow-y: auto;
    }

    @include min-screen($gallery-breakpoint__desktop) {
        height: auto;
        margin: 0;
    }

    .gallery__image-list {
        @include min-screen($gallery-breakpoint__desktop) {
            display: initial;
        }
    }

    .gallery__image-list-container {
        width: 100vw;
        overflow: hidden;

        @include min-screen($gallery-breakpoint__desktop) {
            width: 100%;
        }
    }

    .gallery__image-list-item {
        width: 100%;
        transition: all 0.2s ease-out;
        box-sizing: border-box;
        line-height: 0;

        .image-list__image {
            width: 100%;

            @include min-screen($gallery-breakpoint__desktop) {
                &:not(.image-list__image--first) {
                    height: $gallery-image-large-height;
                }
            }
        }

        @include min-screen($gallery-breakpoint__desktop) {
            height: auto;
            width: 100%;
            margin-bottom: $indent__s;
            overflow: hidden;

            &:not(.gallery__image-list-item--video) {
                height: $gallery-image-large-height;

                &:nth-child(4n + 2),
                &:nth-child(4n + 3) {
                    height: 0;
                    width: calc(50% - 5px);
                    padding-top: 50%;
                    position: relative;
                    float: left;

                    .image-list__image {
                        height: 100%;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;

                        &.alignment {
                            &--bottom-left,
                            &--bottom-middle,
                            &--bottom-right {
                                top: auto;
                            }
                        }
                    }
                }

                &:nth-child(4n + 2) {
                    margin-right: 5px;
                }

                &:nth-child(4n + 3) {
                    margin-left: 5px;
                }

                &:nth-child(last) {
                    padding-left: initial;
                    margin-bottom: initial;
                }
            }

            &.gallery__image-list-item--first {
                height: auto;
            }
        }
    }
}

@include max-screen($gallery-breakpoint__desktop) {
    .gallery__image-list-container {
        display: flex;
        height: 100%;
        position: inherit;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .gallery__image-list-item {
        &:nth-child(4n) {
            .image-list__image {
                height: auto;
                width: 100%;
            }
        }
    }
}
