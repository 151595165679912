
.pagebuilder-button-primary {
    @include btn();

    &:visited,
    &:active {
        color: white !important; // sass-lint:disable-line no-important
    }
}

.has-arrow:not(.is-large) {
    .pagebuilder-button-primary {
        @include btn-arrowed();


        &.btn-large {
            @include btn-arrowed($size: large);
        }
    }
}

.is-large {
    .pagebuilder-button-primary {
        @include btn($size: large);
    }

    &.has-arrow {
        .pagebuilder-button-primary {
            @include btn-arrowed($size: large);
        }
    }
}
