//
//  Footer Newsletter
//  ---------------------------------------------

.footer-newsletter {
    .action.primary {
        @include btn();
        margin-top: 1px;
    }

    .block.newsletter {
        .field {
            margin-right: 15px;

            .control {
                &:before {
                    display: none;
                }
            }
        }

        input {
            padding-left: 0;
            background: none;
            color: $color-grey3;
            border-color: $color-grey5;
        }
    }
}

.footer-newsletter__text {
    max-width: 260px;
}


//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__m) {

    .footer-newsletter {
        .block.newsletter {
            width: 100%;

            .field {
                margin-right: 15px;
            }

            .action.subscribe {
                border-radius: 0;
            }
        }
    }

}


@include min-screen($screen__l) {

    .footer-newsletter {
        width: 30%;
        flex: 1 1 30%;
    }

}
