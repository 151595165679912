//
//  Cart form
//  _____________________________________________

.cart-form {
    @include min-screen($screen__m) {
        width: 60% !important; // sass-lint:disable-line no-important
    }
}

.cart-form__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @include max-screen($screen__m) {
        .action {
            margin-left: 0 !important; // sass-lint:disable-line no-important
        }
    }

    @include min-screen($screen__m) {
        justify-content: flex-start;
    }

    button:nth-child(2),
    button:nth-child(3) {
        font-family: $font-family__base !important; // sass-lint:disable-line no-important
        font-weight: normal !important; // sass-lint:disable-line no-important
    }
}

.cart-form__button-continue {
    @include max-screen($screen__m) {
        width: 100%;
        margin-bottom: 10px;
    }
}

.product-item__warranty-claim.message-info.info.message {
    padding-left: 10px;
}
