/*  =========================================================================
    Undo popup
    ========================================================================= */

.c-undo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  transition: background $transition-duration ease-in;

  .c-button--primary {
    margin-bottom: 1em;

    &:focus,
    &:hover {
      .c-undo__icon {
        transform: rotate(0deg);
        transition: transform 250ms;
      }
    }
  }
}

.c-undo__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  background: white;
  padding: 1.5em;
  width: 85vw;
  max-width: 30em;
  max-height: 90%;
  overflow: auto;
  border: 1px solid $color-gray-4;
  transition: all $transition-duration ease-in-out;
  opacity: 0;

  > :first-child {
    margin-top: 0 !important;
  }

  > :last-child {
    margin-bottom: 0 !important;
  }
}

.c-undo--fixed .c-undo__content {
  position: fixed;
}

.c-undo__button-container {
  text-align: center;
}

.c-undo__icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  transform: rotate(1turn);
}

.c-undo--visible {
  background: rgba(255, 255, 255, 0.75);

  .c-undo__content {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}
