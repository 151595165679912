//
//  Cart usps    
//  _____________________________________________


.cart-usps {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    width: 100%;
    margin-bottom: $indent__m;
    padding: 15px;
    padding-bottom: 5px;
    border: 1px solid $color-grey7;
    box-sizing: border-box;
    float: right;

    p {
        strong {
            text-transform: uppercase;
        }
    }
    
    ul {
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 15px;
        margin-left: 0;

        li {
            list-style-type: none;
            position: relative;
            margin-left: 20px;
            font-size: 1.6rem;
            

            &:not(:last-child) {
                margin-bottom: $indent__s;
            }

            &::before {
                @include icon($icon: checkmark-small);
                position: absolute;
                left: -20px;
                top: 8px;
            }
        }
    }
}

.account-nav .item.current strong {
    border-color: $color-grey1;
}
