.stories__grid-item {
    float: left;
    width: 50%;
    height: 50vw;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &:hover {
        cursor: pointer;

        .stories__grid-image {
            transform: scale(1.25);
        }
    }

    &.is-dummy {
        .stories__grid-image {
            filter: grayscale(1);
        }

        &:hover {
            cursor: default;

            .stories__grid-image {
                transform: none;
            }
        }
    }
}

.stories__grid {
    overflow: hidden;
}

.hundred-index-index .page-footer {
    z-index: 9;
}


@media screen and (min-width: $screen__xs) and (max-width: $screen__s - 1) {
    .stories__grid-item {
        width: calc(100% / 3);
        height: calc(100vw / 3);
    }

    .stories {
        margin-bottom: calc(-100vw / 3);
    }
}

@media screen and (min-width: $screen__s) and (max-width: $screen__l - 1) {
    .stories__grid-item {
        width: 20%;
        height: 20vw;
    }

    .stories {
        margin-bottom: calc(-100vw / 5);
    }
}

@media screen and (min-width: $screen__l) and (max-width: 1600px - 1) {
    .stories__grid-item {
        width: calc(100% / 7);
        height: calc(100vw / 7);
    }

    .stories {
        margin-bottom: calc(-100vw / 7);
    }
}

@media screen and (min-width: 1600px) {
    .stories__grid-item {
        width: calc(100% / 9);
        height: calc(100vw / 9);
    }

    .stories {
        margin-bottom: calc(-100vw / 9);
    }
}

.stories__grid-image {
    width: 101%;
    height: 101%;
    transition: 0.3s transform;
}

.stories__grid-item .story {
    display: none;
}
