//
//  Cart Free Shipping Bar
//  _____________________________________________


.checkout-cart-index {
    .aw_fslabel_label {
        background: $color-white-smoke;
        margin-bottom: 10px;
        @include text-normal();
        font-size: $h3__font-size;



        .goal {
            @include text-normal();
            font-weight: $font-weight__heavier;
            font-size: $h3__font-size;
        }
    }
}
