.tbw-progress {
    position: fixed;
    top: -40px;
    left: 0;
    right: 0;
    height: 30px;
    background: $color-grey7;
    z-index: 99;
    transition: top 0.5s ease-in;

    &.active {
        top: 0;
    }

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: $primary__color;
        color: $color-white;
        transition: width 0.5s ease-in;
    }
}
