$the-answer-to-life-the-universe-and-everything: 42;

.tbw__steps {
    counter-reset: tbw-step-counter;
    list-style: none;
    padding: 0;
    position: relative;

    &[data-has-animation="true"] {
        .tbw-step {
            animation: fadein 0.5s ease-in;
        }
    }
}

.tbw-step {
    counter-increment: tbw-step-counter;
    position: relative;
    padding-left: $spacer-xl;
    padding-bottom: $spacer-lg;
    padding-top: 0.25em;

    @include min-screen($screen__s) {
        padding-left: $spacer-xxl;
        padding-top: 0.35em;
    }

    &::before {
        content: counter(tbw-step-counter);
        display: block;
        position: absolute;
        text-align: center;
        font-weight: $font-weight__bold;
        color: white;
        background: black;
        font-size: $font-size__l;
        left: 0;
        top: 20px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;

        @include min-screen($screen__s) {
            width: $the-answer-to-life-the-universe-and-everything + px;
            height: $the-answer-to-life-the-universe-and-everything + px;
            line-height: $the-answer-to-life-the-universe-and-everything + px;
        }
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
