//
//  bbd Slider
//  ---------------------------------------------

.bbd-items__toolbar {
    margin-bottom: $indent__m;
}

.bbd-slider {
    position: relative;
    margin: 0 -12.5px;

    &__slide {
        padding: 0 12.5px;
        width: auto !important; // sass-lint:disable-line no-important
        display: block !important; // sass-lint:disable-line no-important
        margin-bottom: 25px;

        .card {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex-grow: 0;

            &__image {
                display: block;
                margin: 0;
            }

            &__text {
                margin-bottom: 20px;
            }
        }

        img {
            @include object-fit(cover);
            height: 100%;
        }
    }

    &__arrows {
        position: absolute;
    }
}

button.bbd-slider__arrow {
    position: relative;
    height: $indent__m;
    width: $indent__m;
    border-radius: $indent__m;
    background: $color-white;
    border: 0;
    padding: 0;

    &:before {
        @include icon($icon: chevron-right-grey);

        position: absolute;
        top: 5px;
        left: 8px;
    }

    &:hover {
        &:before {
            @include icon(
                $icon: chevron-right-black
            );
        }
    }

    &:active {
        box-shadow: none;
    }

    &.bbd-slider__arrow--prev {
        &:before {
            transform: rotate(180deg);
            left: 7px;
            top: 6px;
        }
    }
}

.featured-items__products,
.featured-items__inspiration {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.6s;
    height: 0;
    display: block;
    overflow: hidden;

    &.is--active {
        visibility: visible;
        opacity: 1;
        height: auto;
    }
}

.featured-items__inspiration {
    margin-top: -55px;

    .featured-items__slider {
        margin-top: 55px;
    }

    .bbd-slider__arrows {
        top: -50px;
        right: 5px;

        button.bbd-slider__arrow {
            &:before {
                @include icon($icon: chevron-right-grey);
            }

            &:hover:before {
                @include icon($icon: chevron-right-black);
            }

            &.bbd-slider__arrow--prev {
                &:before {
                    transform: rotate(180deg);
                }
            }
        }

        @include max-screen($screen__xs) {
            display: none;
        }
    }
}

//
//  Mobile
//  ---------------------------------------------

@include max-screen($screen__m) {

    .bbd-slider {
        &__slide {
            margin-bottom: 60px;
        }
    }

}


//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__m) {

    .bbd-slider {
        &__slides {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.225s ease;

            &.slick-initialized {
                visibility: visible;
                opacity: 1;
            }
        }

        &__arrows {
            display: block;
            position: absolute;
            right: 12.5px;
            top: -56px;
        }
    }
}
