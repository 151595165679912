.c-container {
  display: block;
  position: relative;
  padding: 1em;
  flex: 0 0 auto;
  width: 100%;

  @media (min-width: $min-width-lg) {
    width: 30%;
    margin-bottom: 0;
  }
}

.c-container__header {
  position: relative;
  flex: 0 0 auto;
}

.c-container__header--flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.c-options__back {
  margin-top: -1.25em;
}

.c-container--options {
  overflow-x: hidden;

  @media (min-width: $min-width-md) and (max-width: $min-width-lg - 1) {
    width: 50%;
    flex: 0 0 auto;
  }

  @media (min-width: $min-width-lg) {
    display: flex;
    flex-direction: column;
    order: -1;
  }
}

.c-options__header-content {
  &[data-header-for]:not(.c-options__header-content--current) {
    display: none;
  }
}

.c-options__header-content--generic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.c-container__title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.25em;
  line-height: 1;
  margin: 0 0 1.25em;

  @media (min-width: $min-width-md) {
    font-size: 1.5625em;
  }
}

.c-container--preview {
  padding: calc(1em + #{$target-width / 2});

  @media (max-width: $min-width-lg - 1) {
    width: 100%;
  }

  @media (min-width: $min-width-lg) {
    width: 40%;
  }
}

.c-container--summary {
   @media (min-width: $min-width-md) and (max-width: $min-width-lg - 1) {
    width: 50%;
    flex: 0 0 auto;
  }
}
