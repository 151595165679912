//
//  Box to cart
//  ---------------------------------------------

.product-info-main,
.product-options-bottom {
    
    .box-tocart__price {
        display: flex;
        align-items: baseline;
        margin-right: 24px;

        .price-suffix {
            margin-left: $indent__xs;
            font-size: 1.3rem;
        }
    }

    .price-box {
        .price-wrapper {
            .price {
                font-size: 3.2rem;
                font-weight: $font-weight__semibold;
            }
        }

        .old-price {
            .price-wrapper {
                .price {
                    font-size: 2rem;
                }
            }
        }
    }
}
