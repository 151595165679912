/*  =========================================================================
    Translation errors
    fugly by design
    ========================================================================= */

.u-translation-error,
[data-translation-error] {
  &::before {
    display: inline-block !important;
    vertical-align: middle !important;
    margin-right: 1ch !important;
    content: '!' !important;
    background: rgb(175, 0, 0) !important;
    color: white !important;
    font-size: 1em !important;
    font-weight: bold !important;
    width: 1.5em !important;
    height: 1.5em !important;
    line-height: 1.5em !important;
    text-align: center !important;
    border-radius: 50%;
  }
}
