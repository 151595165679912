//
//  Product Detail Page Service & Warranty Tab
//  ---------------------------------------------

.service-warranty-tab {
    &__header {
        margin-bottom: $indent__xl;

        h3 {
            margin-top: 0;
        }
    }

    &__related-products {
       
        .column.main .product-items {
            margin-left: 0;
        }
    
        // Tablet view tab content
        @media only screen and (min-width: $screen__xs) and (max-width: $screen__s)  {
            .products-grid .product-item {
                width: percentage(1/3.1);
            }

            .product-item-info .product-item-details {
                display: block;
                width: percentage(2/3) !important; // sass-lint:disable-line no-important
            }
            
        }

        // Mobile view tab content
        @include max-screen($screen__s) {
            .products-grid .product-item {
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: $indent__s;
                }

                .product-item-photo {
                    width: 100%;
                    max-width: 85px;
                    margin-right: $indent__s;
                }

                .product-item-info .product-item-details {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }
                
                .price-box.price-final_price {
                    align-self: flex-end;
                }
            }

            .product-item-info {
                display: flex;
            }

            .product-item-info .product-item-details {
                width: 50%;
            }
        }

        .product.details.product-item-details {
            width: 75%;
            float: left;
            padding-left: $indent__m;
            box-sizing: border-box;

            .product-item .product.name a,
            .product-item-name,
            .product-item-link {
                font-size: 1.8rem;
            }

            .product-item-link {
                &:after {
                    padding-left: $indent__xs;
                    font-size: 110%;
                    content: '\203A';
                }

                &:hover:after {
                    text-decoration: none;
                }
            }
        }
    }

    .product-item-info {
        width: initial;
    }
}

#servicewarrantytab {

    @include max-screen($screen__s) {
        .product-items {
            display: flex;
            flex-wrap: wrap;
        }

        .product.details.product-item-details {
            padding-left: 0 !important; // sass-lint:disable-line no-important
        }
    }

    .product-image-container {
        width: 100% !important; // sass-lint:disable-line no-important

        .product-image-wrapper {
            padding-bottom: 90px !important; // sass-lint:disable-line no-important
        }
    }

    #servicewarrantytab {
        .product-image-container {
            width: 100% !important; // sass-lint:disable-line no-important

            .product-image-wrapper {
                padding-bottom: 130px !important; // sass-lint:disable-line no-important
            }
        }

        #service-warranty-color-select,
        .item.product.product-item {
            width: 100%;
            padding-left: 0;
            box-sizing: border-box;

            @include min-screen($screen__m) {
                width: 49%;
                padding-right: $indent__xl;

                &:nth-child(2n) {
                    padding-right: 0;
                }
            }

            @include min-screen($screen__l) {
                width: percentage(1/3);

                &:nth-child(2n) {
                    padding-right: $indent__xl;
                }

                &:nth-child(3n) {
                    padding-right: 0;
                }
            }

            @include min-screen($screen__xl) {
                width: 24%;

                &:nth-child(3n) {
                    padding-right: $indent__xl;
                }

                &:nth-child(4n) {
                    padding-right: 0;
                }
            }
        }

    }

    .service-warranty-tab .price-box {
        .price-box {
            float: right;
        }

        .product-item-photo {
            width: 25%;
            float: left;
        }

        .product-image-photo {
            position: initial;
        }

        .product-items {
            margin-left: 0;
            border-bottom: none;
        }

    }
}
