@include min-screen($screen__l) {
    .highlight__card {
        display: block;
        margin-top: 1em;
    }

    .highlight__card p {
        font-size: $font-size__l;
        text-transform: none;
    }

    .highlight__card strong {
        font-size: $h3__font-size-desktop;
    }
}

.navigation .nav-main__list .highlight__card a {
    @include btn();
    @include btn-arrowed();
}

@include min-screen($screen__l) {
    .navigation .nav-main__list .highlight__card a {
        @include btn();
        @include btn-arrowed($load-icon: false);
        padding: 11px 20px !important; // sass-lint:disable-line no-important
        font-weight: 600 !important; // sass-lint:disable-line no-important
        margin-top: 1em;

        &:after {
            opacity: 0!important; // sass-lint:disable-line no-important
            display: none !important; // sass-lint:disable-line no-important
        }
    }
}

