//
//  Pagination
//  ---------------------------------------------

.pages {
    .item {
        margin: 0 0 0 -1px;
    }

    .items {
        display: flex;
        white-space: normal;

        strong.page,
        .action,
        a.page {
            width: 40px;
            height: 40px;
            padding: 0;
            border: 1px solid $border-color__base;
            font-size: 1.6rem;
            line-height: 40px;
            text-align: center;
            box-sizing: border-box;
        }

        strong.page {
            font-weight: $font-weight__semibold;
            color: $color-grey3;
        }

        .action,
        a.page {
            font-weight: $font-weight__semibold;
            color: $text__color;

            &:hover {
                background: $primary__color;
                color: $color-white;
                border-color: $primary__color;
            }
        }
    }

    .action {
        &.next,
        &.previous {
            border: none;

            &:before {
                margin: 14px 0 0;
                @include icon($icon: 'angle-right');
            }

            &:hover {
                &:before {
                    @include icon($icon: 'angle-right-white');
                }
            }
        }

        &.next {
            margin-left: 0;
        }

        &.previous {
            margin-right: 0;

            &:before {
                transform: scaleX(-1);
            }
        }
    }
}
