body.amlocator-index-index {
    overflow-x: initial;
}

.store-locator {

    //
    //  Layout
    //  ---------------------------------------------

    .store-locator__top {
        @include lib-clearfix();
    }

    .store-locator__grid {
        @include min-screen($screen__m) {
            display: grid;
            grid-column-gap: $indent__m;
            grid-template-columns: 30% auto;
        }
    }

    .store-locator__content {
        margin-top: $indent__m;

        @include min-screen($screen__m) {
            display: flex;
        }
    }

    .store-locator__content-main {
        @include min-screen($screen__m) {
            flex: 2;
        }
    }

    .store-locator__content-sidebar {
        @include min-screen($screen__m) {
            flex: 1;
            margin-left: $indent__m;
            padding-left: $indent__m;
            border-left: 1px solid $border-color__base;
        }


        ul {
            padding-left: 0;
            list-style: none;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: $indent__s;

            li {
                border: 1px solid $border-color__base;

                a {
                    display: block;
                    padding: 10px;
                }
            }

            @include min-screen($screen__xs) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @include min-screen($screen__s) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            @include min-screen($screen__m) {
                grid-template-columns: 1fr 1fr;
            }
        }

    }

    //
    //  Search
    //  ---------------------------------------------

    .store-locator__filter {
        display: block !important; // sass-lint:disable-line no-important
        margin-bottom: $indent__m;
    }

    .store-locator__search,
    .store-locator__locate {
        width: 100%;
    }

    .store-locator__locate {
        @include lib-button-as-link();
        margin-left: 0;
        margin-top: $indent__s;

        &:active {
            box-shadow: none;
        }

        svg {
            position: relative;
            top: 4px;
            margin-right: 4px;
        }
    }

    .store-locator__no-location {
        display: block;
        margin-top: $indent__s;
    }

    //
    //  Map
    //  ---------------------------------------------

    .store-locator__map {
        width: 100%;
        float: none;
    }

    .store-locator__map-canvas {
        width: 100%;
        height: auto;
        padding-top: 66.66%;
    }

    //
    //  Marker
    //  ---------------------------------------------

    .store-locator__popup {
        padding: $indent__s;
        max-width: 250px;
        text-align: center;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0;
        }

        a.btn {
            display: block;
            margin-top: $indent__m;
            margin-bottom: $indent__s;
            text-align: center;
        }
    }

    //
    //  Stores
    //  ---------------------------------------------

    .store-locator__results-found {
        span {
            color: $color-warm-red;
        }
    }

    .store-locator__stores {
        width: 100%;
        float: none;
        height: auto;
        overflow: auto;
        margin: $indent__m 0;

        @include max-screen(1300px) {
            height: auto;
        }
    }

    .store-locator__stores-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: $indent__s;

        @include min-screen($screen__xxs) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .store-locator__too-many-results {
        grid-column-start: 1;
        grid-column-end: 4;

        span.store-locator__to-map {
            display: inline;
            border: none;
            padding: inherit;
            font-size: inherit;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .store-locator__store {
        font-size: 90%;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 90%;
            margin-top: 0;
        }

        .view-icon {
            padding: 0;
            border: 0;
            margin-top: 10px;

            &::before {
                content: '';
                display: inline-block;
                width: 18px;
                height: 23px;
                background: url('../images/store-locator/brabantia-pinpoint.png') no-repeat;
                background-size: contain;
                margin-right: 10px;
                position: relative;
                top: 4px;
            }
        }

        &.active,
        &:hover {
            background: $color-grey7;
            border: 1px solid $border-color__base;
            .view-icon {
                &::before {
                    background: url('../images/store-locator/brabantia-pinpoint-active.png') no-repeat;
                    background-size: contain;
                }
            }
        }
    }
}