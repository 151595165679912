.modals-overlay {
    background-color: rgba(0,0,0,0.5);
}

.redirect-popup {
    display: none;
}

.amredirect-modal.modal-popup {
    pointer-events: auto;

    .modal-inner-wrap {
        margin:  0 auto;
        overflow-y: auto;
        width: 100%;
    }

    .modal-header {
        padding-bottom: 0;
        .modal-title {
            border-bottom: 0;
            font-size: 40px;
            padding-bottom: 0
        }
    }

    .modal-content {
        .redirect-popup {
            display: block;
        }
    }

    .redirect-popup {
        overflow-x: hidden;

        .intro-text {
            >div>div {
                padding: 10px 0 !important;
            }
        }

        .switcher {
            float: none;
        }

        ul.dropdown.switcher-dropdown {
            list-style: none;
            padding-left: 10px;
        }

        button {
            min-width: 180px;
        }

        .redirect-buttons {
            margin-bottom: 30px;
            text-align: center;
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }
            button {
                margin-bottom: 10px;
            }
        }

        .switcher.redirect-main-buttons {
            .redirect-buttons {
                margin-bottom: 10px;
            }
            .switcher-dropdown__list {
                border: none;
                padding: 0;
            }
            .options {
                ul.dropdown {
                    border: none;
                    padding: 0 0 0 10px;
                }
            }
            a {
                position: relative;
                svg {
                    border-radius: 50px;
                }
                .pointer {
                    padding-left: 8px;
                    &.icon-angle-down {
                        width: 6px;
                        height: 8px;
                        left: 15px;
                        position: relative;
                    }
                }
            }
        }

        #redirect-popup-switcher {
            display: none;
            &.active {
                display: block;
            }
        }

        .dealer-link {
            margin-bottom: 30px;

            .dealer-comment {
                margin-bottom: 20px;
            }

            a {
                display: block;
                text-align: center;
                width: 100%;
            }
        }

        .continue {
            margin-bottom: 20px;
            .popup-continue {
                text-decoration: none;
            }
        }
    }
}

//
//  Media queries
//  ---------------------------------------------

@include min-screen($screen__xxs) {
    .amredirect-modal.modal-popup {
        .modal-inner-wrap {
            max-width: 450px;
            width: 90%;
        }
    }
}

@include min-screen($screen__xs) {
    .amredirect-modal.modal-popup {
        .modal-inner-wrap {
            margin: 30px auto;
            .redirect-popup {
                .redirect-buttons {
                    text-align: inherit;
                }
                .dealer-link {
                    a {
                        text-align: inherit;
                    }
                }
            }
        }
    }
}

@include min-screen($screen__s) {
    .amredirect-modal.modal-popup {
        .modal-inner-wrap {
            margin: 30px auto;
            max-width: 580px;
        }
    }
}
