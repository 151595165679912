.pagebuilder-full-width {
    float: left;
    width: 100%;
}

.pagebuilder-content-type {
    box-sizing: border-box;
    margin-bottom: 20px;
}

[data-content-type="row"],
[data-role="row"] {
    .widget .block-title > strong {
        @include lib-heading(h2);
        text-transform: uppercase;
    }

    &[data-appearance="full-bleed"] {
        margin-left: calc(50% - 50vw) !important; // sass-lint:disable-line no-important
        margin-right: calc(50% - 50vw) !important; // sass-lint:disable-line no-important

        .pagebuilder-column-group {
            max-width: calc(100% - 30px);
            margin: 0 auto !important; // sass-lint:disable-line no-important
            width: 100%;

            @include min-screen($screen__m) {
                max-width: $layout__max-width - 50px;
            }
            
            @media only screen and (min-width: 700px) and (max-width: $screen__xxl)  {
                padding: 0 20px;
            }
           
        }
    }
}

body:not(.aw_blog-post-view) {
    .column.main {
        [data-content-type="row"],
        [data-role="row"] {
            [data-element="inner"] {
                @include max-screen($screen__m) {
                    margin-bottom: 30px !important; // sass-lint:disable-line no-important
                }
            }
        }
    }   
}

.checkmark-list {
    ul {
        padding: 0;
        list-style: none;

        li {
            padding-bottom: 5px;

            &:before {
                @include icon($icon: checkmark);
                margin-right: 10px;
            }
        }
    }
}
