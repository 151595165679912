$author-image-dimensions: 80px;

.about-the-author {
    h2 {
        margin-bottom: $indent__m !important; // sass-lint:disable-line no-important
    }

    .about-the-author__image {
        min-width: 100px;
    }

    .about-the-author__author {
        padding: $indent__l !important; // sass-lint:disable-line no-important
        margin-bottom: 0 !important; // sass-lint:disable-line no-important


        @include max-screen($screen__s) {
            padding: $indent__base !important; // sass-lint:disable-line no-important
        }

        p {
            margin-bottom: 0;
        }

        img {
            border-radius: 50% !important; // sass-lint:disable-line no-important
            max-height: $author-image-dimensions !important; // sass-lint:disable-line no-important
            max-width: $author-image-dimensions !important; // sass-lint:disable-line no-important
        }
    }

    .about-the-author__name {
        margin: auto 0 !important; // sass-lint:disable-line no-important

        p:nth-child(2) {
            color: $text__color__muted;
        }
    }

    div.about-the-author__about,
    .about-the-author__about {
        padding: 0 $indent__l $indent__xl  $indent__l !important; // sass-lint:disable-line no-important
    }
}