/*  =========================================================================
    Chevron


    for this svg:

      <svg xmlns="http://www.w3.org/2000/svg" width="1600" height="958" viewBox="0 0 1600 958" class="c-chevron">
        <path fill="currentColor" fill-rule="nonzero" d="M792.44 364.67l549.7 549.13c59.04 58.98 154.7 58.92 213.67-.12s58.91-154.72-.12-213.7L899.22 44.2c-58.99-58.93-154.56-58.93-213.55 0L44.32 684.87c-59.04 58.97-59.1 154.64-.13 213.69 58.97 59.04 154.63 59.1 213.67.13l534.58-534.03z"/>
      </svg>

    ========================================================================= */

.c-chevron {
  display: inline-block;
  vertical-align: middle;
  transition: transform 150ms;
  width: 0.75em;
  height: 0.75em * (598 / 1600);
}

.c-chevron--right {
  transform: rotate(90.0001deg);
}

.c-chevron--down {
  transform: rotate(180.0001deg);
}

.c-chevron--left {
  transform: rotate(270deg);
}
