
.page-products {
    .products-grid:not(.bbd-slider) {
        .product-items {
            display: flex;
            flex-wrap: wrap;
            margin-left: -20px;

            .product-item {
                @include lib-layout-column(1, 0, 100%);
                position: relative;
                margin-bottom: $indent__m;
                padding-left: 20px;

                @include min-screen($screen__s) {
                    margin: 0 0 $indent__l;
                }
            }
        }
    }
}

//
//  page-layout-1column
//  _____________________________________________

.page-layout-1column,
.page-layout-2columns-left,
.page-layout-2columns-right,
.page-layout-3columns {
    &.page-products .products-grid:not(.bbd-slider) {
        .product-item {
            width: percentage(1);
            margin-left: 0;
        }
    }
}

//
//  page-layout-1column
//  Media query > 480px
//  _____________________________________________

@include min-screen($screen__xs) {

    .page-layout-1column,
    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        &.page-products .products-grid:not(.bbd-slider) {
            .product-item {
                width: percentage(1/2);
                margin-left: 0;
            }
        }
    }

}

//
//  page-layout-2columns & 3columns
//  Media query > 640px
//  _____________________________________________

@include min-screen($screen__s) {

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        &.page-products .products-grid:not(.bbd-slider) {
            .product-item {
                width: percentage(1/3);
                margin-left: 0;
            }
        }
    }

}

//
//  page-layout-2columns & 3columns
//  Media query > 768px
//  _____________________________________________

@include min-screen($screen__m) {

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        &.page-products .products-grid:not(.bbd-slider) {
            .product-item {
                width: percentage(1/2);
                margin-left: 0;
            }
        }
    }

}

//
//  page-layout-2columns & 3columns
//  Media query > 1024px
//  _____________________________________________

@include min-screen($screen__l) {

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        &.page-products .products-grid:not(.bbd-slider) {
            .product-item,
            .product-item:nth-child(4n + 1) {
                width: percentage(1/3);
                margin-left: 0;
            }
        }
    }

}
