//
//  Cart sidebar container    
//  _____________________________________________

.cart-sidebar-container {
    
    @include max-screen($screen__m) {
        margin-top: 25px;
    }
  
    @include min-screen($screen__m) {
        width: 30%;
        float: right;
    }
}
