.catalog-product-view {

    div[data-bv-show="inline_rating"] {

        #bvseo-aggregateRatingSection {
            display: none !important;  // sass-lint:disable-line no-important
        }
    }

}

@include max-screen($screen__m) {
    .catalog-product-view {

        div[data-bv-show="rating_summary"],
        .box-tocart__qty-stock {
            height: auto;
            max-height: 500px;
            transition: max-height 0.3s;

            .no-scroll & {
                height: 0;
                max-height: 0;
                overflow: hidden;
                margin: 0;
            }
        }

        .color-picker {
            white-space: nowrap;
            overflow: auto;
            margin-bottom: 0;
        }
    }
}

.catalog-product-view {

    .product.media {
        width: 100vw;
        margin: -25px -15px 15px;

        @include min-screen($screen__m) {
            width: 60%;
            margin: 0;
        }
    }

    .box-tocart__qty-stock {
        display: flex;
        align-items: center;
    
        .field.qty {
            display: flex;
        }
    }

    .product.info.description {
        margin-top: 30px;
        p:first-of-type{
            display: none;
        }

        @include min-screen($screen__m) {
            .product.info.sidebar {
                h2 {
                    margin-top: 0;
                }
                width: 33%;
                float: left;
            }
            .product.attribute.description-video {
                width: 63%;
                float: right;
            }
            .product.attribute.description {
                width: 100%;
                clear: both;
            }


        }
    }

    .product-info-main {
        width: 100%;
        position: relative;

        .sub-title {
            margin-bottom: 10px;
        }

        @include max-screen($screen__m) {

            &:before {
                width: 40px;
                height: 2px;
                position: absolute;
                top: -25px;
                left: 50%;
                border-radius: 5px;
                background-color: #c2c2c2;
                content: '';
                opacity: 0;
                transform: translateX(-20px);
                transition: opacity 250ms ease-in-out;

                .no-scroll & {
                    opacity: 1;
                }
            }

            h1 {
                font-size: $h2__font-size;
            }
        }

        @include min-screen($screen__m) {
            width: 37%;
        }
    }
}

.product-info-wrapper {
    position: relative;
}

@include min-screen($screen__l) {
    .product.media,
    .product-info-main {
        display: inline-block;
    }

    .product.media {
        float: none;
    }

    .catalog-product-view .product-info-main {
        float: right;
        position: sticky;
        left: 0;
        top: 30px;
    }
}
