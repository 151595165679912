.hundred-index-index {
    .mfp-container {
        max-width: 90%;
    }

    .mfp-content {
        display: inline-block;
    }
}

.stories__popup {
    position: relative;
    background: white;
    padding: 20px;
    width: auto;
    max-width: 900px;
    margin: 20px auto;

    @include min-screen($screen__l) {
        display: flex;
        flex-direction: row-reverse;
    }
}

.story__wrapper,
.story__media {
    @include min-screen($screen__l) {
        flex: 1;
        width: 50%;
    }
}

.story__media {
    padding-top: 40px;

    @include min-screen($screen__l) {
        margin-left: 20px;
        max-width: 400px;
    }
}

.story__slider {
    ul {
        list-style: none;
        padding: 0;
    }

    img {
        width: 100%;
    }

    iframe {
        width: 100% !important; // sass-lint:disable-line no-important
    }

    .slick-dots {
        text-align: center;

        li {
            display: inline-block;
            margin: 2px;

            button {
                text-indent: -9999em;
                font-size: 0;
                line-height: 0;
                display: block;
                width: 13px;
                height: 13px;
                padding: 5px;
                cursor: pointer;
                color: transparent;
                border: 0;
                outline: none;
                background: transparent;
                position: relative;
                margin: 0 2px;

                &:before {
                    font-size: 6px;
                    line-height: 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 13px;
                    height: 13px;
                    content: "•";
                    text-align: center;
                    -webkit-font-smoothing: antialiased;
                    color: transparent;
                    border-width: 1px;
                    border-style: solid;
                    border-color: $color-black;
                    border-image: initial;
                    background: $color-white;
                    border-radius: 50%;
                    transition: background-color .2s ease-in-out;
                }
            }

            &.slick-active,
            &:hover {
                button {
                    &:before {
                        background: $color-black;
                    }
                }
            }
        }
    }
}

.story__author {
    font-weight: $font-weight__bold;
}
