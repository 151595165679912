//
//  Button variables
//  _____________________________________________

//  Font style
$button__font-family                          : $font-family__headings;
$button__font-weight                          : $font-weight__semibold;
$button__padding                              : 11px 20px;

//  Display settings
$button__border-radius                        : 0;

//  Default                                   = secondary button
$button__color                                : $color-white;
$button__background                           : $primary__color;
$button__border                               : 1px solid $primary__color;

$button__hover__color                         : $color-white;
$button__hover__background                    : $color-grey2;
$button__hover__border                        : 1px solid $color-grey2;

$button__active__color                        : $button__hover__color;
$button__active__background                   : $button__hover__background;
$button__active__border                       : $button__hover__border;

//  Large button
$button__font-size__l                         : 1.8rem;
$button__padding__l                           : 11px 20px;

// Transition settings
$button__transition__time                     : 0.2s;
$button__transition__timing__function         : ease-in-out;
