//
//  RMA Table
//  ---------------------------------------------

.table-rma {
    tbody {

        tr {
            border-bottom: 1px solid $color-grey6;
        }
    }
}
