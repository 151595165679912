.zEWidget-webWidget {
    z-index: 9999999999 !important; // sass-lint:disable-line no-important
}

.zEWidget-launcher {
    @media (max-width: 1024px) {
        bottom: 60px !important; // sass-lint:disable-line no-important
        z-index: 99 !important; // sass-lint:disable-line no-important
    }
}
