/*  =========================================================================
    Check
    ========================================================================= */

$check-size-unit: 4px;

.c-check-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  min-width: $check-size-unit * 4;
  min-height: $check-size-unit * 4;
  border-radius: 50%;
  border: 1px solid $color-gray-1;
  background: rgba(232, 232, 232, 0.75);


  .c-check {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}

.c-check {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: $check-size-unit;
  height: $check-size-unit;
  margin: $check-size-unit;
  color: currentColor;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all $transition-duration;
    content: '';
    background: currentColor;
    width: $check-size-unit;
    height: $check-size-unit;
    border-radius: 50%;
  }

  &.c-check--checked,
  &[checked] {
    &::before,
    &::after {
      height: $check-size-unit / 2;
      border-radius: $check-size-unit / 4;
    }

    &::before {
      width: $check-size-unit * 1.25;
      transform: translateY($check-size-unit / 2) translate(-50%, -50%) rotate(49deg) translateX(-50%) translateX($check-size-unit / 4);
    }

    &::after {
      width: $check-size-unit * 1.8;
      transform: translateY($check-size-unit / 2) translate(-50%, -50%) rotate(-49deg) translateX(50%) translateX(-$check-size-unit / 4);
    }
  }

  &.c-check--large.c-check--checked,
  &.c-check--large[checked] {
    &::before {
      width: $check-size-unit * 2;
      transform: translateY($check-size-unit) translate(-50%, -50%) rotate(49deg) translateX(-50%) translateX($check-size-unit / 4);
    }

    &::after {
      width: $check-size-unit * 3.03;
      transform: translateY($check-size-unit) translate(-50%, -50%) rotate(-49deg) translateX(50%) translateX(-$check-size-unit / 4);
    }
  }
}
