//
//  BBD Inspiration Wizard Widget
//  ---------------------------------------------

.inspirationwizard {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 0;
    padding-bottom: 73.86%;
    position: relative;

    form {
        height: 100%;

        .input {
            padding-top: 55px;

            @include max-screen($screen__xs) {
                padding-top: 20px;
            }

            .row {
                display: flex;

                .column {
                    box-sizing: border-box;
                    width: calc(40% - 40px);
                    float: left;
                    text-align: right;
                    margin-left: 20px;
                    margin-right: 20px;
                    height: 70px;

                    @include max-screen($screen__xs) {
                        width: 30%;
                        height: 60px;
                        margin: 0;

                        &:first-child {
                            padding-right: 0.5em;
                        }

                        &.select {
                            width: 40%;
                        }
                    }

                    span {
                        font-family: $font-family__secondary;
                        font-size: $h2__font-size-desktop;
                        line-height: 70px;

                        @include max-screen($screen__xs) {
                            font-size: $h2__font-size;
                            line-height: 60px;
                        }
                    }
                }

                .after {
                    text-align: left;

                    @include max-screen($screen__xs) {
                        //width: 100%;
                        //text-align: center;
                        margin: 0;
                        padding-left: 0.5em;
                        padding-right: 0;
                    }
                }

                .select {
                    margin: 0;
                    position: relative;

                    @include min-screen($screen__sm) {
                        width: 25%;

                        .select {
                            padding: 0 15px;
                        }
                    }

                    select {
                        @include text-normal();
                        background-position: 90% 16px;
                        background-color: white;
                        border: none;
                        position: absolute;
                        bottom: 7px;
                        left: 0;
                        padding: 0 30px 0 7px;
                    }
                }
            }
        }

        button.btn.btn-arrow.btn-large,
        .btn-arrow.btn-large {
            position: absolute;
            bottom: $spacer-lg;
            left: calc(#{$spacer-lg} - 20px);

            &:after {
                transform: scale(0.75);
                top: 12px;
            }
        }
    }

}
