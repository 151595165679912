.usp-list {
    background: $color-grey7;
    position: relative;
    height: 60px;
    margin-bottom: $indent__s;

    &__inner {
        //max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
        padding-top: 7px;
        visibility: hidden;
        opacity: 0;

        &.slick-initialized {
            visibility: visible;
            opacity: 1;
            transition: 0.2s opacity ease-in-out;
        }

        @include min-screen($screen__s) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            max-width: $layout__max-width;
            padding-left: $layout-indent__width;
            padding-right: $layout-indent__width;
            visibility: visible;
            opacity: 1;
            transition: 0.2s opacity ease-in-out;
        }
    }

    &__arrows {
        position: static;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        padding: 0 $layout__width-xs-indent;
        box-sizing: border-box;
        height: 0;
        width: 100%;

        @include min-screen($screen__s) {
            display: none;
        }

        .bbd-slider__arrow {
            position: absolute;
            top: 17px;
            background: none;

            &--next {
                right: $layout__width-xs-indent;
            }
        }
    }

    &__item {
        box-sizing: border-box;
        padding-left: 50px;
        justify-content: center;

        @include max-screen($screen__s) {
            display: flex !important; // sass-lint:disable-line no-important
        }

        @include min-screen($screen__s) {
            display: flex;
            flex-direction: row;
            padding-left: 10px;

            &:first-child {
                padding-left: 0;
            }

            &:nth-child(4) {
                display: none;
            }
        }

        @include min-screen($screen__xl) {
            &:nth-child(4) {
                display: flex;
            }
        }

        &-content {
            display: inline-block;
            box-sizing: border-box;
            padding-left: 15px;
            width: 90%;
            overflow: hidden;
            float: left;
            margin-top: auto;
            margin-bottom: auto;

            @include min-screen($screen__s) {
                float: initial;
                width: auto;
            }
        }

        &-image {
            margin-top: 8px;
            float: left;

            //@include min-screen($screen__s) {
            //    margin-top: 8px;
            //}
            //
            //@include min-screen($screen__m) {
            //    margin-top: 12px;
            //    float: initial;
            //}

            img {
                margin: 0 auto;
                height: $indent__m;
                width: auto;
            }
        }

        &-title {
            margin: $indent__xs 0 0 0;
        }

        &-subtitle {
            color: $color-grey2;
            margin-top: 3px;
        }

        &-title,
        &-subtitle {
            display: block;
            white-space: nowrap;
            font-size: 1.3rem;
        }
    }
}
