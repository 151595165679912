//
//  Form account
//  ---------------------------------------------
.aw-rma__view form,
.form-account,
.form-edit-account,
.form-newsletter-manage {
    
    .fieldset > .field:not(.choice) > .label {
        padding: 6px 15px 0 0;
        text-align: left;
        width: auto;
        box-sizing: border-box;
        float: inherit;
        font-weight: 600;
    }

    .fieldset > .field:not(.choice) > .control {
        width: auto;
        float: inherit;
    }

    .actions-toolbar {
        margin-left: 0 !important; // sass-lint:disable-line no-important
    }

    .fieldset {
        > .field.choice:before,
        > .field.no-label:before {
            display: none;
        }
    }

    .field-recaptcha,
    .field-recaptcha + .captcha {
        margin-top: 30px;
    }
}

.form-address-edit,
.form-edit-account,
.form-newsletter-manage {
    padding: 30px;
    border: 1px solid $color-grey6;

    .legend span {
        font-size: $h3__font-size !important; // sass-lint:disable-line no-important

        @include min-screen($screen__m) {
            font-size: $h3__font-size-desktop !important; // sass-lint:disable-line no-important
        }
    }
}
