.pm-mask:before,
.pin__title {
    display: none !important; // sass-lint:disable-line no-important
}

.pin__image {
    line-height: 0;
}

.pin__popup {
    box-shadow: none !important; // sass-lint:disable-line no-important
}

.pin__type .pin-label {
    cursor: pointer;
    display: block;
    text-align: center;
    font-size: 28px;
}

.pin__type--area {
    cursor: default !important; // sass-lint:disable-line no-important
    transition: none !important; // sass-lint:disable-line no-important

    &:hover,
    &.pin__opened {
        background: white !important; // sass-lint:disable-line no-important

        .pin-label {
            color: black;
        }
    }
}

.popup__content--product {
    text-align: left !important; // sass-lint:disable-line no-important
}

.pin__popup .popup__content--product h3 {
    margin-bottom: 5px !important; // sass-lint:disable-line no-important
    font-size: $font-size__l !important; // sass-lint:disable-line no-important

    a {
        text-decoration: none !important; // sass-lint:disable-line no-important
    }
}

.popup__actions {
    display: flex;
    flex-direction: row;
    border: none !important; // sass-lint:disable-line no-important
    margin-top: $spacer;
}

.popup__add-to-cart {
    @include btn-icon(
        $size: default,
        $icon: cart-white,
        $scale: 0.75,
        $top: 9px,
        $load-icon: true
    );
}

.popup__content--product > div form {
    width: auto !important; // sass-lint:disable-line no-important
    align-self: flex-end;
    margin-right: 15px;
    padding: 0 !important; // sass-lint:disable-line no-important
}

.pin__popup div.price-box {
    flex-grow: 1;
    align-items: center;
    padding-left: 15px;

    .price {
        color: black !important; //sass-lint:disable-line no-important
    }
}

.lookbook__category-button {
    @include btn-arrowed();
    position: absolute;
    bottom: $spacer-lg;
    left: $spacer-lg;
    color: white !important; //sass-lint:disable-line no-important

    &:visited {
        color: white;
    }
}

.mgs-lookbook-container {
    width: 100%;

    .lookbook-image {
        width: 100%;
    }
}

@include max-screen($screen__m) {
    .pin__type.pin__type--area {
        display: none !important; //sass-lint:disable-line no-important
    }
}
