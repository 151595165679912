%svg-common {
	background: url("svg/sprite.css.svg") no-repeat;
}

.icon-account {
	@extend %svg-common;
	background-position: 93.55932203389831% 52.4390243902439%;
}

.icon-account-dims {
	width: 18px;
	height: 20px;
}

.icon-angle-down {
	@extend %svg-common;
	background-position: 99.83249581239531% 55.04950495049505%;
}

.icon-angle-down-dims {
	width: 11px;
	height: 7px;
}

.icon-angle-right {
	@extend %svg-common;
	background-position: 95.50748752079866% 59.6%;
}

.icon-angle-right-dims {
	width: 7px;
	height: 12px;
}

.icon-angle-right-white {
	@extend %svg-common;
	background-position: 94.34276206322795% 59.6%;
}

.icon-angle-right-white-dims {
	width: 7px;
	height: 12px;
}

.icon-angle-up {
	@extend %svg-common;
	background-position: 100% 21.386138613861387%;
}

.icon-angle-up-dims {
	width: 11px;
	height: 7px;
}

.icon-arrow-left-black {
	@extend %svg-common;
	background-position: 87.07482993197279% 48.79032258064516%;
}

.icon-arrow-left-black-dims {
	width: 20px;
	height: 16px;
}

.icon-arrow-right-black {
	@extend %svg-common;
	background-position: 90.3225806451613% 48.79032258064516%;
}

.icon-arrow-right-black-dims {
	width: 19px;
	height: 16px;
}

.icon-arrow-right-white {
	@extend %svg-common;
	background-position: 93.54838709677419% 48.79032258064516%;
}

.icon-arrow-right-white-dims {
	width: 19px;
	height: 16px;
}

.icon-calendar {
	@extend %svg-common;
	background-position: 91.78082191780823% 29.918032786885245%;
}

.icon-calendar-dims {
	width: 24px;
	height: 24px;
}

.icon-cart-black {
	@extend %svg-common;
	background-position: 96.93877551020408% 52.4390243902439%;
}

.icon-cart-black-dims {
	width: 20px;
	height: 20px;
}

.icon-cart-white {
	@extend %svg-common;
	background-position: 87.07482993197279% 56.50406504065041%;
}

.icon-cart-white-dims {
	width: 20px;
	height: 20px;
}

.icon-checkmark {
	@extend %svg-common;
	background-position: 95.62289562289563% 55.37848605577689%;
}

.icon-checkmark-dims {
	width: 14px;
	height: 10px;
}

.icon-checkmark-small {
	@extend %svg-common;
	background-position: 97.1571906354515% 59.36254980079681%;
}

.icon-checkmark-small-dims {
	width: 10px;
	height: 10px;
}

.icon-checkmark-white {
	@extend %svg-common;
	background-position: 97.97979797979798% 55.37848605577689%;
}

.icon-checkmark-white-dims {
	width: 14px;
	height: 10px;
}

.icon-chevron-right-black {
	@extend %svg-common;
	background-position: 99.33444259567388% 19.2%;
}

.icon-chevron-right-black-dims {
	width: 7px;
	height: 12px;
}

.icon-chevron-right-grey {
	@extend %svg-common;
	background-position: 100% 23.2%;
}

.icon-chevron-right-grey-dims {
	width: 7px;
	height: 12px;
}

.icon-clock {
	@extend %svg-common;
	background-position: 100% 0;
}

.icon-clock-dims {
	width: 96px;
	height: 96px;
}

.icon-close {
	@extend %svg-common;
	background-position: 93.24324324324324% 56.16161616161616%;
}

.icon-close-dims {
	width: 16px;
	height: 17px;
}

.icon-cw-refresh {
	@extend %svg-common;
	background-position: 96.39794168096054% 23.81930184804928%;
}

.icon-cw-refresh-dims {
	width: 25px;
	height: 25px;
}

.icon-download {
	@extend %svg-common;
	background-position: 87.67123287671232% 29.918032786885245%;
}

.icon-download-dims {
	width: 24px;
	height: 24px;
}

.icon-edit {
	@extend %svg-common;
	background-position: 100% 34.83606557377049%;
}

.icon-edit-dims {
	width: 24px;
	height: 24px;
}

.icon-filter {
	@extend %svg-common;
	background-position: 86.77966101694915% 60.56910569105691%;
}

.icon-filter-dims {
	width: 18px;
	height: 20px;
}

.icon-flag-be_fr {
	@extend %svg-common;
	background-position: 87.67123287671232% 39.75409836065574%;
}

.icon-flag-be_fr-dims {
	width: 24px;
	height: 24px;
}

.icon-flag-be_nl {
	@extend %svg-common;
	background-position: 91.78082191780823% 34.83606557377049%;
}

.icon-flag-be_nl-dims {
	width: 24px;
	height: 24px;
}

.icon-flag-de {
	@extend %svg-common;
	background-position: 95.89041095890411% 39.75409836065574%;
}

.icon-flag-de-dims {
	width: 24px;
	height: 24px;
}

.icon-flag-en {
	@extend %svg-common;
	background-position: 100% 39.75409836065574%;
}

.icon-flag-en-dims {
	width: 24px;
	height: 24px;
}

.icon-flag-es {
	@extend %svg-common;
	background-position: 87.67123287671232% 44.67213114754098%;
}

.icon-flag-es-dims {
	width: 24px;
	height: 24px;
}

.icon-flag-fr {
	@extend %svg-common;
	background-position: 91.78082191780823% 44.67213114754098%;
}

.icon-flag-fr-dims {
	width: 24px;
	height: 24px;
}

.icon-flag-int {
	@extend %svg-common;
	background-position: 95.89041095890411% 34.83606557377049%;
}

.icon-flag-int-dims {
	width: 24px;
	height: 24px;
}

.icon-flag-int_en {
	@extend %svg-common;
	background-position: 100% 29.918032786885245%;
}

.icon-flag-int_en-dims {
	width: 24px;
	height: 24px;
}

.icon-flag-it {
	@extend %svg-common;
	background-position: 95.89041095890411% 44.67213114754098%;
}

.icon-flag-it-dims {
	width: 24px;
	height: 24px;
}

.icon-flag-nl {
	@extend %svg-common;
	background-position: 91.78082191780823% 39.75409836065574%;
}

.icon-flag-nl-dims {
	width: 24px;
	height: 24px;
}

.icon-flag-os {
	@extend %svg-common;
	background-position: 87.67123287671232% 34.83606557377049%;
}

.icon-flag-os-dims {
	width: 24px;
	height: 24px;
}

.icon-flag-uk {
	@extend %svg-common;
	background-position: 95.89041095890411% 29.918032786885245%;
}

.icon-flag-uk-dims {
	width: 24px;
	height: 24px;
}

.icon-flag-us {
	@extend %svg-common;
	background-position: 0 0;
}

.icon-flag-us-dims {
	width: 512px;
	height: 512px;
}

.icon-location-arrow {
	@extend %svg-common;
	background-position: 91.75627240143369% 20.77922077922078%;
}

.icon-location-arrow-dims {
	width: 50px;
	height: 50px;
}

.icon-menu {
	@extend %svg-common;
	background-position: 90.47619047619048% 56.16161616161616%;
}

.icon-menu-dims {
	width: 20px;
	height: 17px;
}

.icon-mobile {
	@extend %svg-common;
	background-position: 98.82154882154882% 23.625254582484725%;
}

.icon-mobile-dims {
	width: 14px;
	height: 21px;
}

.icon-search {
	@extend %svg-common;
	background-position: 93.0390492359932% 60.446247464503045%;
}

.icon-search-dims {
	width: 19px;
	height: 19px;
}

.icon-slider-handle-left {
	@extend %svg-common;
	background-position: 96.28378378378379% 48.59437751004016%;
}

.icon-slider-handle-left-dims {
	width: 16px;
	height: 14px;
}

.icon-slider-handle-right {
	@extend %svg-common;
	background-position: 98.98648648648648% 48.59437751004016%;
}

.icon-slider-handle-right-dims {
	width: 16px;
	height: 14px;
}

.icon-sort-asc {
	@extend %svg-common;
	background-position: 90.47619047619048% 52.4390243902439%;
}

.icon-sort-asc-dims {
	width: 20px;
	height: 20px;
}

.icon-sort-desc {
	@extend %svg-common;
	background-position: 87.07482993197279% 52.4390243902439%;
}

.icon-sort-desc-dims {
	width: 20px;
	height: 20px;
}

.icon-toggle {
	@extend %svg-common;
	background-position: 98.08027923211169% 19.51219512195122%;
}

.icon-toggle-dims {
	width: 35px;
	height: 20px;
}

.icon-trash {
	@extend %svg-common;
	background-position: 99.82905982905983% 44.58077709611452%;
}

.icon-trash-dims {
	width: 23px;
	height: 23px;
}

.icon-upload {
	@extend %svg-common;
	background-position: 100% 52.4390243902439%;
}

.icon-upload-dims {
	width: 18px;
	height: 20px;
}

.icon-upload-white {
	@extend %svg-common;
	background-position: 89.83050847457628% 60.56910569105691%;
}

.icon-upload-white-dims {
	width: 18px;
	height: 20px;
}

