.tbw-layout-start__button-wrapper {
    margin-top: $indent__s;

    @include max-screen($screen__xs) {
        text-align: center;
    }
}

.tbw-layout-start__button {
    @include max-screen($screen__xs) {
        width: 100%;
        margin: $indent__s 0;
    }
}

.tbw-layout-start__no-code {
    display: block;
    margin-top: 10px;
    cursor: pointer;
}

.tbw-layout-start__form {
    margin-top: 30px;

    .tbw-form__field.success {
        &:after {
            display: none;
        }
    }
}

.tbw-layout-start__label {
    font-size: 2.2rem;
    font-weight: bold;
}

.tbw-layout-start__options {
    .tbw-layout-grid__option {
        @include min-screen($screen__xxs) {
            flex: 0 0 calc(100% - #{$indent__s * 2});
        }

        @include min-screen($screen__xs) {
            flex: 0 0 calc(#{percentage(1/3)} - #{$indent__s * 2});
        }

        @include min-screen($screen__s) {
            flex: 0 0 calc(#{percentage(1/3)} - #{$indent__s * 2} );
        }
    }
}