.block-search {
    margin-left: 25px;

    input {
        position: static;
        left: inherit;
        margin: 0;
        padding-right: 35px;
        border-bottom-color: $color-black;
    }

    .control {
        border-top: none;
    }

    .label {
        @include lib-visually-hidden();
    }

    .action.search {
        display: inline-block;
        margin: 0;
        padding: 5px 0;
        position: absolute;
        right: 10px;
        top: 0;
        z-index: 1;
        line-height: inherit;
        border: 0;
        box-shadow: none;
        background: none;
        text-decoration: none;
        text-shadow: none;
        font-weight: 400;

        & > span {
            @include lib-visually-hidden();
        }

        &:before {
            @include icon($icon: 'search');
        }

        &.disabled,
        &[disabled] {
            opacity: 1;
        }
    }

}

//
//  Mobile Only
//  ---------------------------------------------

@include max-screen($screen__l) {
    .block-search {
        top: 0;
        margin-top: 6px;
        vertical-align: top;

        .control {
            padding: 0;
        }

        .nested {
            display: none;
        }

        input {
            height: $form-element-input__height - 10px;
            margin-bottom: 10px;
            padding: 0 10px;
        }
    }
}

//
//  Tablet
//  ---------------------------------------------

@include min-screen($screen__m) {
    .block-search {
        width: 20%;
        margin-left: auto;
        margin-right: 40px;
    }
}

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__l) {
    .block-search {
        width: 100%;
        max-width: 216px;
        margin-left: auto;
        margin-right: 20px;

        .nested {
            display: none;
        }

        .action.search {
            margin-top: 5px;
        }
    }
}

@include min-screen($screen__xl) {
    .block-search {
        max-width: 276px;
    }
}
