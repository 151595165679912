.c-bin-config,
.c-bin-config__option,
.c-bin-config__bins {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  pointer-events: none;
}

.c-bin-config {
  left: 4%;
  right: 4.5%;
  top: 7%;
  bottom: 44%;
  width: auto;
  height: auto;
  color: white;
  transform: rotateX(-1deg);
}

.c-preview--size-medium.c-preview--legs-no.c-preview--operation-pedal .c-bin-config {
  top: 11%;
  bottom: 20%;
  transform: rotateX(-7deg);
}

.c-preview--size-large.c-preview--legs-yes .c-bin-config {
  top: 7%;
  bottom: 25%;
  transform: rotateX(-3deg);
}

.c-preview--size-large.c-preview--legs-no .c-bin-config {
  top: 8%;
  bottom: 9%;
  left: 5%;
  right: 6%;
  transform: rotateX(-2deg);
}

.c-bin-config__bins {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.c-bin-config__bin {
  position: relative;
  border: 0.125em dotted currentColor;
  border-radius: 0.25em;
  flex: 0 0 auto;
  height: 100%;


  // show volume
  &::after {
    content: attr(data-size) ' L';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  // border-radius fancyness for first and last child
  &:first-child {
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
  }

  &:last-child {
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  // sizing
  &[data-size="11"] {
    width: calc(33.333% - 0.25em);
  }

  &[data-size="23"] {
    width: calc(66.666% - 0.25em);
  }

  &[data-size="30"] {
    width: calc(50% - 0.25em);
  }

  &[data-size="36"],
  &[data-size="60"], {
    width: 100%;
  }
}

.c-preview--size-medium .c-bin-config__bin[data-size="60"],
.c-preview--size-medium .c-bin-config__bin[data-size="30"],
.c-preview--size-large .c-bin-config__bin[data-size="11"],
.c-preview--size-large .c-bin-config__bin[data-size="23"],
.c-preview--size-large .c-bin-config__bin[data-size="36"] {
  display: none !important;
}

.c-preview--color-light .c-bin-config {
  color: $color-gray-1;
}
