//
//  Common
//  ---------------------------------------------

.page-header, .redirect-popup {

    .switcher {
        float: right;
        margin-top: 3px;
        margin-right: 0;

        .options {

            .action.toggle {
                line-height: 0;
                display: flex;
                align-items: center;

                &:after {
                    @include icon($icon: angle-down);
                    display: none;
                }

                > strong {
                    line-height: 0;
                    display: inline-block;
                }

                &.active {
                    display: flex;

                    &:after {
                        display: none;
                        //@include icon($icon: angle-up);
                        //display: none;
                    }
                }
            }

            .action.toggle,
            ul.dropdown a {
                svg {
                    border-radius: 50%;
                }
            }

            ul.dropdown {
                min-width: 280px;
                max-width: 510px;
                width: 80vw;
                right: 0;
                top: calc(100% + 10px);
                border-color: $border-color__base;
                border-top: 1px solid $color-grey6;

                &.is--active {
                    display: block;
                }

                &:before {
                    right: 26px;
                    left: auto;
                }

                &:after {
                    right: 25px;
                    left: auto;
                    border-color: transparent transparent $border-color__base transparent;
                }

                li {
                    &:hover {
                        background: none;
                    }
                }

                a {
                    display: flex;
                    align-items: center;
                    padding: 8px 0;

                    svg {
                        margin-right: 15px;
                        flex: 0 0 24px;
                        align-self: baseline;
                    }
                }
            }
        }

        .switcher-dropdown__default-stores {
            .switcher-dropdown__list {
                border-bottom: 1px solid $border-color__base;
            }
        }

        .switcher-dropdown__list {
            list-style: none;
            margin: 0 30px;
            padding: 30px 0;
            column-count: 2;
            column-gap: 20px;

            .switcher-option {
                break-inside: avoid;

                &.active {
                    a {
                        color: $text__color;
                    }
                }

                a {
                    text-decoration: none;
                    font-size: 1.3rem;
                    color: $color-grey3;
                    &:hover {
                        color: $text__color;
                    }
                }
            }
        }
    }
}

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__l) {

    .page-header, .redirect-popup {
        .switcher {
            margin-top: 8px;

            a {
                color: $primary__color;
            }

            .options {
                ul.dropdown {
                    top: calc(100% + 29px);
                }
            }
        }
    }

}


//
//  Mobile
//  ---------------------------------------------

@include screen($screen__m, $screen__l) {

    .page-header, .redirect-popup {
        .switcher {
            margin-top: 7px;
        }
    }

}

@include max-screen($screen__s) {

    .page-header, .redirect-popup {
        .switcher {
            .switcher-dropdown__list {
                margin: 0 15px;
                padding: 15px 0;
                columns: 1;
            }

        }
    }

}
