//
//  Orders history, items
//  ---------------------------------------------

.orders-history,
.orders-recent,
.order-items {
    tbody {

        tr {
            border-bottom: 1px solid $color-grey6;
        }
    }
}
