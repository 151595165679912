@mixin pb--responsive() {
    margin-right: -10px;
    margin-left: -10px;
    display: flex;
    flex-direction: column;

    > .pagebuilder-column {
        width: 100% !important; // sass-lint:disable-line no-important
    }
}

@mixin pb--unset-responsive() {
    margin-right: -20px;
    margin-left: -20px;
    flex-direction: row;
}

@mixin pb--no-margin() {
    .pagebuilder-column-group,
    .pagebuilder-column {
        @include no-margin(true);

        @include min-screen($screen__m) {
            @include no-margin(true);
        }

        @include max-screen($screen__m) {
            @include no-margin(true);
        }

        @include screen($screen__s, $screen__m) {
            @include no-margin(true);
        }
    }
}

@mixin pb--no-padding() {
    .pagebuilder-column-group,
    .pagebuilder-column {
        @include no-padding(true);

        @include min-screen($screen__m) {
            @include no-padding(true);
        }

        @include max-screen($screen__m) {
            @include no-padding(true);
        }

        @include screen($screen__s, $screen__m) {
            @include no-padding(true);
        }
    }
}