$spacing-unit: 1.5625em;

$spacer-xxs:    $spacing-unit * 0.25;
$spacer-xs:     $spacing-unit * 0.5;
$spacer-sm:     $spacing-unit * 0.75;
$spacer-md:     $spacing-unit;
$spacer:        $spacing-unit;
$spacer-lg:     $spacing-unit * 1.25;
$spacer-xl:     $spacing-unit * 2;
$spacer-xxl:    $spacing-unit * 3.25;
$spacer-xxxl:   $spacing-unit * 5.25;
