//
//  Product Detail Page Filter options
//  ---------------------------------------------

.filter-options {
    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 10px 0;

        &:after {
            @include icon($icon: angle-down);
            opacity: 0.6;
            transform: rotate(0deg);
            transition: transform 250ms ease-in-out;
        }
    }

    .filter-options__item.active {
        .filter-options-title:after {
            transform: rotate(180deg);
            transition: transform 250ms ease-in-out;
        }
    }

    .item.show-more {
        @include btn-small-white();
        display: inline-block;
        margin-top: 10px;
        cursor: pointer;
    }
}

.filter-options__item {
    border-top: 1px solid $color-grey6;
}

.filter-options .filter-options-content {
    overflow: hidden;
    clear: both;
}
