//
//  Switch
//  ---------------------------------------------

.switch {
    > button:not(:last-child) {
        margin-right: 15px;
    }
}

button.switch__btn,
button.switch__toggle {
    @include lib-button-as-link();
    font-size: $h3__font-size-desktop !important; // sass-lint:disable-line no-important
    font-weight: $font-weight__bold;
    text-transform: uppercase;
    text-decoration: none;
    color: $color-grey5;

    &:active {
        box-shadow: none;
        text-decoration: none;
    }
}

button.switch__btn--active {
    color: $color-black;
}

button.switch__toggle {
    margin-top: 5px;

    &:after {
        transform: rotate(180deg);
        @include icon($icon: toggle);
    }
}

.switch__btn--active + button.switch__toggle {
    &:after {
        transform: rotate(0deg);
    }
}

//
//  Mobile
//  ---------------------------------------------

@include max-screen($screen__m) {

    button.switch__btn,
    button.switch__toggle {
        font-size: $h3__font-size;
    }

}
