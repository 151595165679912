.gallery {
    @include max-screen($gallery-breakpoint__desktop) {
        height: 75vw;

        .gallery-slick-list {
            .slick-slide,
            .gallery__image-list-item {
                overflow: hidden;
                height: 85vw;

                &:not(.gallery__image-list-item--video) {
                    display: flex !important;
                }
            }

            .slick-slide {
                > div {
                    width: 100%;
                }
            }

            &.slick-vertical .slick-slide {
                border: none;
            }

            .slick-dots {
                position: absolute;
                top: 50%;
                margin: 0;
                padding-left: 15px;
                transform: translateY(-50%);

                li {
                    width: 8px;
                    height: 8px;
                    list-style: none;

                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    &:after {
                        width: 8px;
                        height: 8px;
                        position: absolute;
                        background-color: #c2c2c2;
                        border-radius: 50%;
                        content: '';
                        transition: transform 250ms ease-in-out, background-color 250ms ease-in-out;
                    }

                    &.slick-active:after {
                        background-color: $color-black;
                        transform: scale(1.2);
                    }
                }

                button {
                    display: none;
                }
            }
        }
    }

    @include max-screen($screen__s) {
        height: 80vw;

        .gallery-slick-list {
            .slick-slide,
            .gallery__image-list-item {
                height: 80vw;
            }
        }
    }

    @include max-screen($screen__xs) {
        height: 51vh;

        .gallery-slick-list {
            .slick-slide,
            .gallery__image-list-item {
                height: 51vh;
            }
        }
    }
}
