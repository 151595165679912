/*  =========================================================================
    AR model
    ========================================================================= */

.c-ar {
  display: block;

  &[href="#"] {
    pointer-events: none;
  }
}
