//
//  Cards
//  _____________________________________________

.card {
    width: 100%;

    &__image {
        line-height: 0;
        margin: 0 0 10px;

        img {
            width: 100%;
        }
    }

    a.card__title,
    &__title {
        display: block;
        margin: 10px 0 10px;
        font-size: 1.8rem;
        font-weight: $font-weight__bold;
    }

    a.card__title {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    &__bottom {
        margin-top: 25px;
    }

    a.card__btn,
    &__btn {
        @include btn-arrowed();
    }
}
