@keyframes slide-up {
    from {
        margin-bottom: -100px;
    }

    to {
        margin-bottom: 0;
    }
}

.tbw-to-start {
    position: fixed;
    bottom: 129px;
    right: 20px;
    z-index: 999;
    transition: all 0.5s;
    animation: 0.5s slide-up;

    @include min-screen($screen__m) {
        bottom: 70px;
    }

    &__button {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        position: relative;
        padding: 0;

        &:after {
            content: '';
            @include icon(
                $icon: arrow-right-white
            );
            transform: rotate(-90deg) scale(0.75);
            left: 10px;
            top: 10px;
            position: absolute;
        }
    }
}