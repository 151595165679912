$input-checkbox-ratio: 15px;

.onestepcheckout-index-index .page-main .checkout-container .field,
.onestepcheckout-index-index .page-main .checkout-container .control {
    display: flex;

    @include max-screen($screen__s) {
        display: block;

        .label {
            width: 100%;
        }
    }
}

.onestepcheckout-index-index .page-main .checkout-container .aw-onestep-main fieldset.field.street .control {
    flex-wrap: nowrap !important; // sass-lint:disable-line no-important
}

.onestepcheckout-index-index {

    .page-title {
        margin-bottom: $indent__l !important; // sass-lint:disable-line no-important
    }

    .page-main {

        .aw-onestep-sidebar {
            .totals-tax {
                //font-style: italic;
                opacity: 0.5;
            }
        }

        .aw-onestep-groups {
            .aw-onestep-groups_item:first-of-type {
                //.group-content {
                //    .field[name*="country_id"],
                //    .field[name*="lastname"] {
                //        width: calc(50% - 20px);
                //        float: right;
                //    }
                //
                //    .field[name*="postcode"],
                //    .field[name*="firstname"] {
                //        width: calc(50% - 20px);
                //        float: left;
                //    }
                //}
            }
        }

        .checkout-container .aw-onestep-main .email {
            max-width: 100% !important; // sass-lint:disable-line no-important
            margin: 40px 0 0 !important; // sass-lint:disable-line no-important
            flex-direction: column;

            .field {
                flex-direction: row !important; // sass-lint:disable-line no-important
                padding-bottom: 2em;

                .note {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }

                .control {
                    flex-direction: column;
                }

            }
        }

        // spacing
        .aw-onestep-main .aw-onestep-groups .aw-onestep-groups_item:not(:first-of-type) {
            margin-top: $indent__base !important; // sass-lint:disable-line no-important

            @include min-screen($screen__l) {
                margin-top: $indent__xl !important; // sass-lint:disable-line no-important
            }
        }

        .fieldset > .field .note,
        .fieldset > .fields > .field .note {
            font-size: 14px;
        }

        .checkout-container {
            margin-top: $indent__l !important; // sass-lint:disable-line no-important
            color: $color-grey1 !important; // sass-lint:disable-line no-important
            font-family: $font-family__base !important; // sass-lint:disable-line no-important

            .control {
                display: flex;
                width: 100%;
            }

            .field.choice {
                font-size: 1.6rem;
            }

            .grand.totals {
                font-family: $font-family__headings !important; // sass-lint:disable-line no-important
                font-weight: $font-weight__bold !important; // sass-lint:disable-line no-important
                font-size: 2rem !important; // sass-lint:disable-line no-important
            }

            .totals.sub,
            .totals.shipping,
            .totals-tax {
                font-size: 1.6rem !important; // sass-lint:disable-line no-important
            }

            .new-shipping-address-form .title {
                @include btn-arrowed();
                display: inline-block;
                height: auto !important; // sass-lint:disable-line no-important
                padding: 11px $indent__base;
                line-height: 2.2rem;
                font-size: 1.8rem;
                text-transform: inherit !important; // sass-lint:disable-line no-important

                &:before {
                    display: none !important; // sass-lint:disable-line no-important
                }

                &:after {
                    top: 13px;
                }

                strong {
                    padding-right: $indent__base;
                    color: white !important; // sass-lint:disable-line no-important
                    text-decoration: none !important; // sass-lint:disable-line no-important
                }
            }
        }

        .aw-onestep-description,
        .checkout-container .onestepcheckout-index-index .aw-onestep-description {
            color: $color-grey1 !important; // sass-lint:disable-line no-important
        }

        .checkout-container .input-text {
            cursor: inherit !important; // sass-lint:disable-line no-important
        }

        // hide numbers
        .checkout-container .aw-onestep-sidebar-header {
            margin-bottom: $indent__base;
            padding-left: 0 !important; // sass-lint:disable-line no-important
            font-family: $font-family__headings !important; // sass-lint:disable-line no-important
            font-weight: $font-weight__bold !important; // sass-lint:disable-line no-important
        }

        .checkout-container .aw-onestep-sidebar-content .aw-onestep-sidebar-block.aw-sidebar-product-list .aw-onestep-sidebar-block_title {
            padding-left: 0 !important; // sass-lint:disable-line no-important
            text-transform: uppercase;
            font-family: $font-family__headings !important; // sass-lint:disable-line no-important
            font-weight: $font-weight__bold !important; // sass-lint:disable-line no-important
        }

        .checkout-container .aw-onestep-sidebar-content .aw-onestep-sidebar-block.payment-option .payment-option-title .action-toggle {
            font-size: 2rem !important; // sass-lint:disable-line no-important
        }

        .checkout-container .aw-onestep-sidebar-content {

            .actions-toolbar {
                margin-top: $indent__base !important; // sass-lint:disable-line no-important

                .action.checkout {
                    background-color: #00b248;
                    border-color: #00b248;
                }

                .action.checkout:hover,
                .action.checkout:focus {
                    background-color: lighten(#00b248, 5%);
                    border-color: lighten(#00b248, 5%);
                }
            }

            .aw-onestep-sidebar-block.aw-sidebar-before-place-order {

                .action {
                    color: $color-black !important; // sass-lint:disable-line no-important
                    width: 100%;
                    font-size: 14px !important; // sass-lint:disable-line no-important
                }


                [data-role="newsletter-subscriber"],
                .checkout-agreement {
                    label::after {
                        top: 5px;
                    }
                }


                [data-role="newsletter-subscriber"] {

                    label {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        justify-content: flex-start;
                    }
                }

                .checkout-agreement {

                    .action {
                        color: $color-black !important; // sass-lint:disable-line no-important
                        width: 100%;

                        &:hover {
                            text-decoration: none !important; // sass-lint:disable-line no-important
                        }
                    }
                }

            }

        }

        // hide numbers
        .checkout-container .aw-onestep-sidebar-header:before,
        .checkout-container .aw-mobile .aw-sidebar-product-list .aw-onestep-sidebar-block_title:before,
        .aw-onestep-main .aw-onestep-groups .aw-onestep-groups_item .group-title .mark,
        .aw-onestep-main .aw-onestep-groups .aw-onestep-groups_item  .search-shipping-address {
            display: none !important; // sass-lint:disable-line no-important
        }

        .aw-sidebar-product_remove {
            display: none !important; // sass-lint:disable-line no-important
        }

        .checkout-container {

            .field-row {
                display: block !important; // sass-lint:disable-line no-important
            }

            .group-title {
                margin-bottom: $indent__l;
                font-family: $font-family__headings !important; // sass-lint:disable-line no-important
                font-weight: $font-weight__bold !important; // sass-lint:disable-line no-important
            }

            .action-toggle,
            .toggle {
                color: $color-grey1 !important; // sass-lint:disable-line no-important
            }

            .aw-onestep-main {

                @include min-screen($screen__m) {
                    .email {
                        width: 100%;
                    }
                }

                .field--valid:not([aria-invalid="true"]),
                .input-text[aria-invalid="false"]:not([value=""]),
                .input-text.valid {
                    border-bottom: 1px solid $color-success-green;
                }

                // input
                .input-text {
                    background-color: transparent;
                    border-top: none;
                    border-right: none;
                    border-left: none;
                    border-bottom: $form-element-input__border;
                    transition: $form-element-input__transition;

                    &:focus {
                        border-top: none;
                        border-right: none;
                        border-left: none;
                        box-shadow: none;
                        border-bottom: $form-element-input__border--focus;
                        transition: $form-element-input__transition;
                    }
                }

                // input type checkbox
                input[type="checkbox"] {
                    position: absolute;
                    opacity: 0;
                }

                input[type="checkbox"] + label {
                    position: relative;
                    cursor: pointer;
                    padding: 0;
                }

                input[type="checkbox"] + label:before {
                    display: inline-block;
                    width: $input-checkbox-ratio;
                    height: $input-checkbox-ratio;
                    position: relative;
                    top: 3px;
                    margin-right: $indent__s;
                    background: white;
                    border: 1px solid $color-grey3;
                    border-radius: 2px;
                    content: '';

                    @include min-screen($screen__xxl) {
                        top: 2px;
                    }
                }

                input[type="checkbox"] + label:after {
                    width: $input-checkbox-ratio;
                    height: $input-checkbox-ratio;
                    position: absolute;
                    top: 5px;
                    left: 2px;
                    transform: scale(0.8);

                    @include min-screen($screen__xxl) {
                        top: 4px;
                    }
                }

                input[type="checkbox"]:checked + label:before {
                    border-color: $color-matt-black;
                }

                input[type="checkbox"]:checked + label:after {
                    @include icon($icon: 'checkmark');
                }

                input[type="checkbox"]:disabled + label {
                    cursor: auto;
                    opacity: 0.7;
                }

                input[type="checkbox"]:disabled + label:before {
                    opacity: 0.7;
                }

                input[type='checkbox'] + label:before,
                input[type='radio'] + label:before {
                    margin-left: 0 !important; // sass-lint:disable-line no-important
                }

                input[type='checkbox']:checked + label:before {
                    transition: none !important; // sass-lint:disable-line no-important
                    color: transparent !important; // sass-lint:disable-line no-important
                }

                input[type='radio'] + label:before,
                input[type='radio']:checked + label:before {
                    top: 5px;
                    color: $color-black !important; // sass-lint:disable-line no-important
                    width: 15px;
                    height: 15px;
                    content: '';
                }

                input[type='radio']:checked + label:after {
                    top: 6px;

                    @include min-screen($screen__xxl) {
                        top: 6px;
                    }
                }

                .payment-method .payment-method-title.field label.label:before {
                    top: 3px;
                    margin-left: 0 !important; // sass-lint:disable-line no-important
                }

                .payment-method .payment-method-title .label > span {
                    font-family: $font-family__base;
                    font-size: 14px;
                }

                // select
                select {
                    appearance: none;
                    outline: none;
                    box-shadow: none;
                    border-color: transparent;
                    border-top: none;
                    border-right: none;
                    border-left: none;
                    border-bottom: $form-element-input__border;
                    border-color: $color-grey4;
                    border-radius: 0;
                    color: $color-grey3;
                    transition: $form-element-input__transition;
                    padding: 0 10px;
                    font-family: $font-family__base;

                    &:focus {
                        border-bottom: $form-element-input__border--focus;
                        transition: $form-element-input__transition;
                    }
                }

                .email {
                    margin: $indent__xl 0;
                }

                .field {
                    margin: $indent__base 0;

                    &.street {
                        .field {
                            margin: 0;
                        }
                    }
                }

                //.control {
                //    display: block !important; // sass-lint:disable-line no-important
                //}

                .field.fl-label-state,
                .field.fl-label {
                    .label {
                        position: relative !important; // sass-lint:disable-line no-important
                        font-size: inherit !important; // sass-lint:disable-line no-important
                        transform: translateY(0) !important; // sass-lint:disable-line no-important
                        white-space: inherit !important; // sass-lint:disable-line no-important
                        overflow: inherit !important; // sass-lint:disable-line no-important
                        padding: 0;
                        font-weight: $font-weight__heavier;
                        color: $color-grey1;
                        width: 40% !important; // sass-lint:disable-line no-important
                        padding-right: 10px !important; // sass-lint:disable-line no-important

                        @include min-screen($screen__xxl) {
                            font-size: 1.6rem !important; // sass-lint:disable-line no-important
                        }

                        @include max-screen($screen__xs) {
                            width: 100% !important; // sass-lint:disable-line no-important
                        }
                    }

                    .input-text,
                    .select,
                    textarea {
                        margin: 0 !important; // sass-lint:disable-line no-important
                        padding-top: 0 !important; // sass-lint:disable-line no-important
                        padding-bottom: 0 !important; // sass-lint:disable-line no-important
                        outline: none;

                        @include min-screen($screen__xxl) {
                            font-size: 1.6rem;
                        }
                    }
                }

                .field .control._with-tooltip input {
                    margin-right: 0;
                    width: 100% !important; //sass-lint:disable-line no-important
                }

                fieldset .field .label {
                    text-align: left !important; //sass-lint:disable-line no-important
                    padding-right: 10px !important; //sass-lint:disable-line no-important
                }
            }

            input[type="checkbox"] + label:before {
                content: '' !important;
            }
        }

        // errors
        .checkout-container .field._error .input-text,
        .checkout-container .input-text.mage-error,
        .checkout-container .field._error .select {
            border: 0 !important; // sass-lint:disable-line no-important
            border-bottom: 1px solid $color-warm-red !important; // sass-lint:disable-line no-important
        }

        .checkout-container .aw-onestep-sidebar-block .actions-toolbar {
            display: flex;
            width: 100% !important; // sass-lint:disable-line no-important
            max-width: 100% !important; // sass-lint:disable-line no-important
        }

        .checkout-container .aw-onestep-sidebar-block .aw-sidebar-product-card {
            @include max-screen($screen__m) {
                margin-bottom: 15px !important; // sass-lint:disable-line no-important
            }
        }

        .checkout-container .aw-onestep-sidebar-block.aw-sidebar-product-list .aw-sidebar-product-description {
            @include max-screen($screen__m) {
                flex-direction: column !important; // sass-lint:disable-line no-important
            }
        }

        .checkout-container .aw-onestep-sidebar-block.aw-sidebar-product-list .aw-sidebar-product-subtotals {
            @include max-screen($screen__m) {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-top: $indent__s;
                text-align: left !important; // sass-lint:disable-line no-important
            }
        }

        .checkout-container div.mage-error {
            color: $color-warm-red !important; // sass-lint:disable-line no-important
            margin-top: $indent__s;
            text-align: right;
        }

        .checkout-container .field.addresses {
            width: 100% !important; // sass-lint:disable-line no-important
        }

        // sidebar
        .checkout-container .aw-onestep-sidebar {
            background-color: $color-grey7 !important; // sass-lint:disable-line no-important
            border: 0 !important; // sass-lint:disable-line no-important
            border-radius: 0 !important; // sass-lint:disable-line no-important
        }

        .checkout-container .aw-onestep-sidebar-content .aw-onestep-sidebar-block.aw-sidebar-before-place-order .action {
            color: $color-black;

            &:hover {
                color: $color-black;
            }
        }

        .checkout-container input[type='checkbox'] + label:before,
        .onestepcheckout-index-index .page-main .checkout-container input[type='radio'] + label:before {
            color: #1f1f1f !important; // sass-lint:disable-line no-important
            min-width: 16px;
            width: 16px;
            height: 16px;
            content: '' !important; // sass-lint:disable-line no-important
        }

        .checkout-container button.checkout {
            @include btn-arrowed();
            display: block;
            height: auto !important; // sass-lint:disable-line no-important
            padding: 11px $indent__base;
            line-height: 2.2rem;
            font-size: 1.8rem;
            text-transform: inherit !important; // sass-lint:disable-line no-important

            &:after {
                position: relative;
                margin-left: $indent__s;
                top: 3px;
                left: 0;
            }
        }

        .checkout-container .select {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='7' version='1'%3E%3Cpath d='M5 4l4-4a1 1 0 0 1 1 2L6 6H5L0 2a1 1 0 1 1 2-2l3 4z' fill='%231F1F1F' fill-rule='evenodd'/%3E%3C/svg%3E") !important; // sass-lint:disable-line no-important
            background-size: 11px !important; // sass-lint:disable-line no-important
            background-position: 98% 18px !important; // sass-lint:disable-line no-important
        }

        // Shipping address
        .aw-onestep-main .new-shipping-address-form {
            margin-bottom: $indent__base !important; // sass-lint:disable-line no-important
        }

        .aw-onestep-main .email .form,
        .aw-onestep-main .payment-method .form,
        .aw-onestep-main .authentication {
            margin: 0 !important; // sass-lint:disable-line no-important
        }

        .aw-onestep-main .onestep-shipping-address .new-shipping-address-form strong {
            color: $color-white !important; // sass-lint:disable-line no-important
        }

        .aw-onestep-main .shipping-address-items ol .shipping-address-item {
            border-radius: 0 !important; // sass-lint:disable-line no-important

            @include max-screen($screen__m) {
                width: 100% !important; // sass-lint:disable-line no-important
            }
        }

        .aw-onestep-main .shipping-address-items ol .shipping-address-item.selected-item {
            border-color: $color-black !important; // sass-lint:disable-line no-important
            box-shadow: inset 0 0 0 1px $color-black !important; // sass-lint:disable-line no-important
        }

        .aw-onestep-main .shipping-address-items ol .shipping-address-item.selected-item:after {
            color: $color-black !important; // sass-lint:disable-line no-important
        }

        .aw-onestep-main .shipping-address-items ol .shipping-address-item .action-select-shipping-item span {
            color: $color-black !important; // sass-lint:disable-line no-important
            font-weight: $font-weight__bold;
        }

        .aw-onestep-main .onestep-billing-address .choice {
            margin-left: 0 !important; // sass-lint:disable-line no-important
        }

        .aw-onestep-main .billing-address-details {
            padding: $indent__base !important; // sass-lint:disable-line no-important
            border: 1px solid $color-grey6;

            .action-edit-address {
                color: $color-black !important; // sass-lint:disable-line no-important
            }
        }

        .aw-onestep-main .payment-method._active .payment-method-content {
            padding-bottom: 0 !important; // sass-lint:disable-line no-important
        }

        .aw-onestep-main .adyen-checkout__field {
            margin-bottom: 15px !important; // sass-lint:disable-line no-important

            &:not(:last-child) {
                margin-right: 15px !important; // sass-lint:disable-line no-important
            }
        }

        .aw-onestep-main .payment-method-content .payment-method-note {
            margin-bottom: 15px !important; // sass-lint:disable-line no-important
            font-size: 12px;
        }

        .aw-onestep-main .adyen-checkout__label__text {
            border-color: $color-black;
            text-transform: uppercase;
        }

        .aw-onestep-main .adyen-checkout__input {
            border-radius: 0;
        }

        .aw-onestep-main .adyen-checkout__dropdown__list {
            z-index: 10;
        }

        .aw-onestep-main .email {
            max-width: inherit;
        }

        .aw-onestep-main select {
            @include max-screen($screen__s) {
                padding-right: $indent__xl !important; // sass-lint:disable-line no-important
            }
        }

        .aw-onestep-main .payment-method:first-of-type {
            border-radius: 0 !important; // sass-lint:disable-line no-important
        }

        .aw-onestep-main .payment-method._active:before {
            background-color: $color-black !important; // sass-lint:disable-line no-important
        }

        .aw-onestep-main .payment-method-title .label a {
            z-index: 0 !important; // sass-lint:disable-line no-important
        }

        .aw-onestep-main .field.field-select-billing {
            position: relative;

            &:after {
                @include icon($icon: angle-down);
                position: absolute;
                top: 19px;
                right: 9px;
                content: '';
                pointer-events: none;

                @include min-screen($screen__s) {
                    top: 22px;
                }
            }
        }

        .payment-method {
            font-family: $font-family__base !important; // sass-lint:disable-line no-important

            select {
                background-color: transparent;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='7' version='1'%3E%3Cpath d='M5 4l4-4a1 1 0 0 1 1 2L6 6H5L0 2a1 1 0 1 1 2-2l3 4z' fill='%231F1F1F' fill-rule='evenodd'/%3E%3C/svg%3E") !important; // sass-lint:disable-line no-important
                background-size: 11px; // sass-lint:disable-line no-important
                background-position: 98% 18px; // sass-lint:disable-line no-important
                background-repeat: no-repeat;
            }

            .adyen-checkout__input:active,
            .adyen-checkout__input--active,
            .adyen-checkout__input:focus {
                border: 1px solid #000000;
                box-shadow: none;
            }

            .payment-method-content .legend span {
                font-size: 2rem !important; // sass-lint:disable-line no-important
            }

            .adyen-checkout__label__text {
                @include text-small;
                font-size: 14px;
                text-transform: inherit;
            }

            .adyen-checkout__card-input {

                .adyen-checkout__input {
                    img {
                        margin-right: $indent__s !important; // sass-lint:disable-line no-important
                    }
                }
            }

            #payment_form_adyen_hpp_ideal {
                margin-bottom: $indent__base !important; // sass-lint:disable-line no-important
            }

            #payment_form_adyen_cc { // sass-lint:disable-line no-id

                legend,
                legend + br {
                    display: none;
                }

                .field {
                    margin-top: 0;
                }

                span.adyen-checkout__label__text {
                    @include text-small;
                }

                .field.choice {
                    display: none;
                }

                @include max-screen($screen__xs) {
                    .adyen-checkout__input.adyen-checkout__input--large {
                        width: 100%;
                    }
                }

            }

            .payment-method-title.field {
                padding: $indent__base;

                label.label {
                    &:before {
                        margin-right: $indent__base;
                    }
                }
            }

            .payment-method-title {
                .payment-icon {
                    display: none;
                }

            }
        }

        .checkout-container .field.choice {
            font-size: 2rem;
        }

    }
}

.onestepcheckout-index-index .page-main .checkout-container .aw-onestep-main {
    input[type="text"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="datetime"],
    input[type="email"] {
        background: $color-grey6;
    }
}

.onestepcheckout-index-index .page-main .checkout-container .field.choice {
    font-size: 1.4rem !important; // sass-lint:disable-line no-important
}

.onestepcheckout-index-index .page-main .checkout-container .form-discount .control {
    flex-direction: column;
}

.payment-methods {
    .field-phone {
        .field-tooltip {
            display: none;
        }
    }
}
