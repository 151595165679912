//
//  Layout
//  ---------------------------------------------

@mixin layout-inner() {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: $layout__max-width;
    padding-left: $layout-indent__width;
    padding-right: $layout-indent__width;
    width: auto;
}

@mixin reset-inner() {
    margin-left: auto;
    margin-right: auto;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
}

//
//  Padding / Margin
//  ---------------------------------------------

@mixin no-padding($important: false) {
    @if $important == true {
        padding-right: 0 !important; // sass-lint:disable-line no-important
        padding-left: 0 !important; // sass-lint:disable-line no-important
    }
    @else {
        padding-right: 0;
        padding-left: 0;
    }
}

@mixin no-margin($important: false) {
    @if $important == true {
        margin-right: 0 !important; // sass-lint:disable-line no-important
        margin-left: 0 !important; // sass-lint:disable-line no-important
    }
    @else {
        margin-right: 0;
        margin-left: 0;
    }
}