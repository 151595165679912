//
//  Box
//  ---------------------------------------------

@mixin boxContent(){
    .box,
    .addresses li {
        padding: 30px;
        box-sizing: border-box;
        border: 1px solid $color-grey6;
    }

    .box-title {
        text-transform: uppercase;
    }

    .box-actions {
        margin-top: 20px;
    }

    .block-title strong {
        font-size: $h3__font-size !important; // sass-lint:disable-line no-important

        @include min-screen($screen__m) {
            font-size: $h3__font-size-desktop !important; // sass-lint:disable-line no-important
        }
    }
}
