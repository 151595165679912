#dpd_parcelshop_container #map_canvas {
    @include max-screen(1100px) {
        height: auto !important; // sass-lint:disable-line no-important
        width: 100%;
    }

    @include max-screen($screen__xs) {
        .content {
            tr {
                display: none;

                &:last-child {
                    display: block;

                    td:first-child {
                        display: none;
                    }
                }
            }
        }
    }
}

.dpd-shipping-information {
    @include min-screen($screen__xs) {
        display: none !important; // sass-lint:disable-line no-important
    }
}
