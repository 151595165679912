//
//  Variables
//  _____________________________________________
$aw-acp-mfp__background-color: #0b0b0b;
$aw-acp-mfp-container__background-color: $color-white;
$aw-acp-mfp-preloader__color: $color-gray80;
$aw-acp-price__color: #009900;
$aw-acp-mobile-close__color: $color-blue1;
$aw-acp-message-god__color: #27ae60;
$aw-acp-message-bad__color: #e74c3c;
$aw-acp-message-info__color: #2c3e50;
$aw-acp-message-god__background-color: #e8fff2;
$aw-acp-message-bad__background-color: #ffedeb;
$aw-acp-message-info__background-color: #fffae6;
$aw-acp-bar-gradient-top__color: #54f054;
$aw-acp-bar-gradient-bottom__color: #2bc253;
$aw-acp-mobile-header__border-color: $color-black;

$color-grey6: #e8e8e8;
$color-grey7: #f7f7f7;

//
//  Common
//  _____________________________________________


.aw-acp-popup-container {
    &.mfp-bg {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 160;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background: $aw-acp-mfp__background-color;
        opacity: 0.8;
        transition: all 0.3s ease-out;
    }

    &.mfp-wrap {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 161;
        width: 100%;
        height: 100%;
        outline: none !important; // sass-lint:disable-line no-important
    }

    .mfp-container {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 0 8px;
        width: 100%;
        height: 100%;
        text-align: center;

        &::before {
            display: inline-block;
            height: 100%;
            content: "";
            vertical-align: middle;
        }
    }

    &.mfp-align-top {
        .mfp-container {
            &::before {
                display: none;
            }
        }
    }

    .mfp-content {
        position: relative;
        z-index: 106;
        display: inline-block;
        margin: 0 auto;
        vertical-align: middle;
        text-align: left;
    }

    .mfp-ajax-holder,
    .mfp-inline-holder {
        .mfp-content {
            width: 100%;
            cursor: auto;
        }
    }

    &.mfp-ajax-cur {
        cursor: progress;
    }

    &.mfp-auto-cursor {
        .mfp-content {
            cursor: auto;
        }
    }

    .mfp-preloader {
        position: absolute;
        top: 50%;
        right: 8px;
        left: 8px;
        z-index: 105;
        margin-top: -0.8em;
        width: auto;
        color: $aw-acp-mfp-preloader__color;
        text-align: center;

        a {
            color: $aw-acp-mfp-preloader__color;

            &:hover {
                color: $aw-acp-mfp-container__background-color;
            }
        }
    }

    .mfp-s-ready {
        .mfp-preloader {
            display: none;
        }
    }

    .mfp-s-error {
        .mfp-content {
            display: none;
        }
    }

    .product-item {
        .action {
            span {
                display: none;
            }
        }
    }

    .aw-acp-popup-with-anim {
        opacity: 0;
        transition: all 0.2s ease-in-out;
    }

    &--zoom-in {
        &.mfp-ready {
            .aw-acp-popup-with-anim {
                opacity: 1;
            }
        }

        &.mfp-removing {
            .aw-acp-popup-with-anim {
                opacity: 0;
            }

            &.mfp-bg {
                opacity: 0;
            }
        }
    }
}

.aw-acp-popup {
    position: relative;
    margin: 20px auto;
    padding: 20px;
    max-width: 800px;
    background: $aw-acp-mfp-container__background-color;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    .swatch-option {
        box-sizing: content-box;
    }

    .products {
        margin: 30px -5px;
    }

    .product-item {
        padding: 0 5px;

        .action.tocart {
            margin: 0 10px;
        }

        .product-item-subtitle {
            display: block;
        }
    }

    &__title,
    &__description,
    &__options-wrapper {
        margin: 0 0 20px;
    }

    &__result-title {
        text-transform: uppercase;
        font-family: $font-family__headings;
        font-weight: $font-weight__bold;
    }

    &__result-price {
        font-size: 1.8rem;
    }

    &__result-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        border-bottom: 1px solid $color-grey6;
    }

    &__mobile-title {
        overflow: hidden;
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }

    &__form {
        .fieldset {
            > .field {
                &:not(.choice) {
                    > .label,
                    > .control {
                        width: auto;
                        float: none;
                    }

                    > .label {
                        text-align: left;
                        box-sizing: content-box;
                        padding: 0;
                        margin: 0 0 5px;
                    }
                }
            }
        }
    }

    &__options-wrapper {
        .fieldset {
            margin-left: 2px;

            .legend {
                margin: 0 0 20px;
            }
        }
    }

    &__box-tocart-wrapper {
        display: flex;
        align-content: flex-end;
    }

    &__box-qty {
        flex: 0 0 auto;
        width: auto;
        margin: 0;
    }

    &__box-price {
        flex: 1 1 auto;
        text-align: center;

        .price-container {
            .price {
                font-size: 24px;
                color: $aw-acp-price__color;
            }
        }
    }

    &__mobile-close-wrapper {
        flex: 0 0 auto;
        margin-left: 15px;
        line-height: 10px;

        &.headless {
            margin-left: 0;
            width: 100%;
        }

        .aw-acp-popup__mobile-close {
            &.action.primary {
                border: none;
                background: none;
                color: white;
                width: 100%;
            }
        }
    }

    &__message,
    .aw_fslabel_label {
        margin: -20px -20px 20px;
        padding: 13px 20px;
        border-radius: 0;
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        &--good {
            background: $color-grey6;
            color: $color-black;
        }

        &--bad {
            background: $aw-acp-message-bad__background-color;
            color: $aw-acp-message-bad__color;
        }

        &--info {
            background: $aw-acp-message-info__background-color;
            color: $aw-acp-message-info__color;
        }
    }

    &__close-wrapper {
        .aw-acp-popup__close {
            display: block;
            width: 100%;
            height: 50px;
            font-size: 18px !important; // sass-lint:disable-line no-important
            line-height: 1.5;
        }
    }

    .aw_fslabel_label {
        background: $aw-acp-message-god__background-color;
        color: $aw-acp-message-god__color;
        font-family: inherit;
    }

    &__main {
        margin-bottom: 20px;
    }

    &__loader {
        display: flex;
        align-content: center;
        padding: 0 40px;
        height: 300px;

        &-bar {
            position: relative;
            display: block;
            overflow: hidden;
            margin: auto;
            max-width: 300px;
            width: 100%;
            height: 20px;
            border-radius: 10px;
            background: $aw-acp-bar-gradient-bottom__color linear-gradient(180deg, $aw-acp-bar-gradient-top__color, $aw-acp-bar-gradient-bottom__color);
            box-shadow: inset 0 2px 9px hsla(0, 0%, 100%, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);

            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 101;
                overflow: hidden;
                border-radius: 10px;
                background-image: linear-gradient(-45deg, hsla(0, 0%, 100%, 0.2) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, 0.2) 0, hsla(0, 0%, 100%, 0.2) 75%, transparent 0, transparent);
                background-size: 50px 50px;
                content: "";
                animation: a 2s linear infinite;
            }
        }
    }

    &__column--media {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    &__reviews-wrapper,
    &__image-wrapper {
        margin: 0;
        text-align: left;
    }

    &__reviews-wrapper {
        height: 20px;
        flex: 1 1 auto;

        .product-reviews-summary {
            display: block;

            .rating-summary,
            .reviews-actions {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    &__image-wrapper {
        flex: 0 0 auto;
    }

    &__image {
        max-width: 100%;
        max-height: 300px;
        height: auto;
    }

    &__actions {
        align-content: center;
    }

    &__close-wrapper {
        display: flex !important; // sass-lint:disable-line no-important
        align-items: center;
        justify-content: center;
        height: 50px;
        text-align: center;
    }

    &__columns {
        width: auto !important; // sass-lint:disable-line no-important

        .price-box {
            margin: 0;
        }
    }

    button.aw-acp-popup__submit {
        font-size: 18px !important; // sass-lint:disable-line no-important
    }

    &__submit,
    &__close {
        font-size: 18px !important; // sass-lint:disable-line no-important
        text-decoration: none;
    }

    &__submit {
        width: 100%;
        height: 50px;
    }

    &__result {
        padding: 20px;
        background-color: $color-grey7;

        &-title,
        &-price,
        &-items {
            margin: 0 0 10px;
            font-size: 18px;
        }

        &-view-cart {
            margin: 0;

            .btn-cart {
                font-size: $font-size__l !important; // sass-lint:disable-line no-important
            }
        }
    }

    &__related {
        margin-top: 30px;

        .product-items {
            .product-item-details {
                padding-top: 0;
                position: static;
            }

            .product-item {
                .tocart {
                    bottom: 0;
                }
            }
        }
    }


}


@keyframes a {
    0% {
        background-position: 0 0;
    }

    to {
        background-position: 50px 50px;
    }
}

.mfp-hide {
    display: none !important; // sass-lint:disable-line no-important
}

// Controls
.input-text.qty {
    width: 54px;
    text-align: center;
}

.field.qty .label {
    font-weight: 600;
    margin: 8px 0;
    display: block;
}

@media(min-width: 580px) {
    .aw-acp-popup-container {
        .aw-acp-popup-with-anim {
            transform: scale(0.8), 1;
        }

        &--zoom-in {
            &.mfp-ready {
                .aw-acp-popup-with-anim {
                    transform: scale(1);
                }
            }

            &.mfp-removing {
                .aw-acp-popup-with-anim {
                    transform: scale(0.8);
                }
            }
        }
    }

    .aw-acp-popup {
        &__mobile-header {
            display: none;
        }

        &__options-wrapper {
            max-height: 700px;
            overflow-y: auto;
            padding: 0 5px 0 0;
        }

        &__columns {
            display: flex;
        }

        &__column {
            &:first-child {
                flex: 0 0 auto;
                width: 50%;
            }

            &:nth-child(2) {
                flex: 1 1 auto;
                margin-left: 20px;
            }
        }
    }

}

.products-grid {
    &.products-acp-related {
        .product-item {
            width: 100%;
            margin-bottom: 30px;

            &:nth-child(n+4) {
                display: none;
            }

            @include min-screen($screen__xs) {
                width: 50%;
            }

            @include min-screen($screen__s) {
                width: percentage(1/3);
                margin-bottom: 0;
            }
        }
    }
}

@media(max-width: 579px) {
    .aw-acp-popup-container {
        .mfp-container {
            position: static;
            padding: 0;
            background: $aw-acp-mfp-container__background-color;

            &::before {
                display: none;
            }
        }

        .mfp-content {
            position: static;
        }
    }

    .aw-acp-popup {
        position: static;
        margin: 20px 0 0 0;

        &__mobile-close-wrapper {
            width: 100%;
        }

        &__mobile-close {
            margin: 0;
            width: 100%;
        }

        &__result-view-cart .btn {
            display: flex !important; // sass-lint:disable-line no-important
            justify-content: center;
            width: 100%;
        }

        &__mobile-header {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 102;
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            padding: 0;
            width: 100%;
            height: 40px;
            border-bottom: 1px solid $aw-acp-mobile-header__border-color;
            background: $aw-acp-mfp-container__background-color;
        }

        &__product-info {
            display: none;
        }

        &--result {
            .aw-acp-popup__message {
                flex: 0 0 auto;
                margin-right: 0;
                margin-left: 0;

                &--info {
                    display: none;
                }
            }

            .aw-acp-popup__columns {
                flex: 0 0 auto;
                margin: 0;
                padding-bottom: 20px;
            }

            .aw-acp-popup__column {
                &--media {
                    display: none;
                }
            }

        }

        &__main {
            left: 0;
            overflow-y: auto;
            margin: 0;
            padding: 20px 0;
            width: 100%;

            &.aw-acp-popup--result {
                display: flex;
                flex-direction: column;
            }
        }

        &__loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &__column {
            &:first-child {
                margin: 0 20px 20px 20px;
                font-size: 1.5rem !important; // sass-lint:disable-line no-important
            }

            &--media {
                flex-direction: column-reverse;

                &.small {
                    .aw-acp-popup__image-wrapper {
                        width: 50%;
                        margin: auto;
                    }
                }
            }
        }

        &__reviews-wrapper {
            text-align: left;
            margin-bottom: 10px;
        }

        &__close-wrapper {
            display: none;
        }

        &__submit-wrapper {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 2;
        }

    }

}

// Update loader
.aw-acp-popup__loader-bar {
    background: url('../images/loader/huisje-o_animated.svg') center center no-repeat;
    background-size: contain;
    height: 100px;
    box-shadow: none;

    &:after {
        display: none;
    }
}


//
//  Mobile
//  _____________________________________________


//
//  Desktop
//  _____________________________________________

