//
//  Mobile Only
//  ---------------------------------------------

@include max-screen($screen__m) {

    .navigation .nav-main__list .nav-main__sub {
        z-index: -1;
    }

    .block-mobile {
        display: flex;
        border-top: 1px solid $color-grey6;
        background-color: $color-white;
        position: fixed;
        margin-bottom: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        padding: 3px 0;

        .nav-toggle {
            margin-top: 10px;
            display: inline-block;
            position: relative;
            top: inherit;
            left: inherit;
            margin-left: 14px;
        }

        .block-search {
            display: inline-block;
            position: relative;
            width: 100%;
            margin-left: 0;
            margin-right: 30px;
            padding-left: 10px;
            margin-top: 0;

            input {
                height: 32px;
                margin-top: 5px;
                padding-right: 0;
            }

            .action.search {
                right: 3px;
                top: 5px;
                transform: scale(0.8);
            }

            .control {
                margin: 0;
                padding: 0 0 25px;
            }
        }

        .minicart-wrapper {
            margin-left: auto;
            top: 13px;
            z-index: 1;

            .block-minicart {
                top: inherit;
                bottom: 100%;

                &:before,
                &:after {
                    display: none;
                }
            }

        }

    }
}
