.page-products .columns {
    display: flex;

    @include min-screen($screen__l) {
        display: block;
    }
}

.page-layout-2columns-left .sidebar-main {
    width: 100%;
    order: 1;
    flex-basis: 100%;
    flex-grow: 1;
    float: none;

    @include min-screen($screen__l) {
        width: 25%;
        float: left;
    }
}

.page-layout-2columns-left .column.main {
    width: 100%;
    order: 2;
    flex-basis: 100%;
    flex-grow: 1;
    float: none;

    @include min-screen($screen__l) {
        width: 73%;
        float: right;
    }
}

.page-layout-2columns-left.catalogsearch-result-index .column.main {
    @include min-screen($screen__l) {
        width: 100%;
    }
}
