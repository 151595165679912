.tbw-layout-buttons__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &.is-selected {
        .tbw-layout-buttons__button:not(.active){
            opacity: 0.5;
            transition: opacity 0.2s ease-in-out;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.tbw-layout-buttons__button {
    input {
        visibility: hidden;
        position: absolute;
    }

    label {
        @include btn();
        margin: 0 $spacer-xxs;

        &:before,
        &:after {
            display: none !important; // sass-lint:disable-line no-important
        }
    }


}
