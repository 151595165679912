//
//  Box to cart
//  ---------------------------------------------

.product-info-main,
.product-options-bottom {
    .box-tocart {
        product info description
        &__qty-stock {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .input-text.qty {
            height: $form-element-input__height;
            width: 63px;
            text-align: left;
            color: $color-grey3;
        }

        .actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding-top: $indent__m;

            .no-scroll & {
                padding-top: 0;
            }

            .action.tocart {
                @include btn-cart($size: large);
            }
        }

        &__additional {
            margin-top: $indent__base;
        }
    }

    .fieldset > .field,
    .fieldset > .fields > .field {
        > .label {
            font-weight: $font-weight__semibold;
        }
    }
}

//
//  Mobile
//  ---------------------------------------------
@include max-screen($screen__xs) {

 .product.info.description {
     .no-scroll & {
         opacity: 0;
     }
 }
}
//
//  Desktop
//  ---------------------------------------------
@include min-screen($screen__m) {

    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            display: block;

            .actions {
                display: flex;
                justify-content: space-between;
            }
        }
    }

}


//
//  Mobile
//  ---------------------------------------------

@include max-screen($screen__m) {

    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            .actions {
                justify-content: space-between;

                .action.tocart {
                    width: auto;
                }
            }
        }
    }

    .product-info-main .box-tocart .field.qty {
        flex-grow: 2;
        display: flex;
        margin-bottom: $spacer-xs;
    }

}


//@include max-screen($screen__xs) {
//
//    .product-info-main,
//    .product-options-bottom {
//        .box-tocart {
//            .actions {
//                .action.tocart {
//                    width: 100%;
//                }
//            }
//        }
//    }
//
//}
