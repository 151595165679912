.filter-active {
    overflow-y: hidden;
}

.filter.block {
    margin-bottom: 0;

    .filter--expanded & {
        position: relative;
        visibility: visible;
        z-index: 90;
    }
}

.is--sticky {
    position: sticky;
    left: 0;
    top: 0;
}

.filter__wrapper {
    display: none;
    margin: 0;

    .filter--expanded & {
        background: #fff;
        bottom: 57px;
        display: block;
        left: 0;
        overflow-y: auto;

        .filter-options {
            padding-bottom: 80px;
        }


        @include max-screen($screen__l) {
            position: fixed;
            right: 0;
            top: 0;
            z-index: 160;
            padding: 20px 15px;
            height: 100%;
        }

        @include max-screen($screen__m) {
            z-index: 1000; // overwrite z-index search box
        }

        @include min-screen($screen__m) {
            top: 60px;
            padding: 20px 40px;
            height: calc(100% - 60px);
        }

        @include min-screen($screen__l) {
            height: auto;
        }
    }
}

@include min-screen($screen__l) {
    .filter__wrapper {
        display: block;
        position: relative;
    }
}
