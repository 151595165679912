.block-search {
    .amsearch-form-container {
        .input-text {
            &:focus {
                border: 0;
                border-bottom: 1px solid $color-black;
                box-shadow: none;
            }
        }

        &.-opened {
            .input-text {
                padding-left: 10px;

                &::-moz-placeholder {
                    font-style: normal !important; // sass-lint:disable-line no-important
                    @include lib-css(color, $input-text-placeholder__color);
                }

                &::-webkit-input-placeholder {
                    font-style: normal !important; // sass-lint:disable-line no-important
                    @include lib-css(color, $input-text-placeholder__color);
                }

                &:-ms-input-placeholder {
                    font-style: normal !important; // sass-lint:disable-line no-important
                    @include lib-css(color, $input-text-placeholder__color);
                }
            }

            .amsearch-wrapper-input {
                position: static;
                right: auto;
                width: auto !important; // sass-lint:disable-line no-important

                &:first-child {
                    display: block;
                }
            }

            .action.search,
            .action.search.amasty-xsearch-hide {
                display: block;
            }
        }
    }

    .amsearch-loupe {
        display: none !important; // sass-lint:disable-line no-important
    }

    .amsearch-close {
        &:before {
            @include icon($icon: 'close');
        }
    }

    .amsearch-wrapper-input ~ .amsearch-wrapper-input {
        display: none;
    }

}

@include min-screen($screen__m) {

    .block-search {
        .amsearch-form-container {
            &.-opened {
                .action.search {
                    display: block;
                }
            }

            .input-text {
                padding-left: 10px;
            }
        }

        .amsearch-close {
            display: none !important; // sass-lint:disable-line no-important
        }
    }
}

@include max-screen($screen__m) {

    .block-search {
        .amsearch-close {
            height: 35px;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 999;
            //pointer-events: none;
            background: white;

            &:before {
                display: block;
                position: fixed;
                top: 10px;
                right: 10px;
                margin: 0 auto;
                pointer-events: none;
                cursor: pointer;
            }
        }
    }
}
