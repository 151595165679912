/** Product items component **/

// Container product items list
.product-items {

    .product-item-details {
        display: block;
        position: relative;
        padding-top: $indent__m;
    }

    .product-item-subtitle {
        display: block;
        margin-bottom: 0;
    }

    // Todo after write plugin / module add class to div and rename
    [data-bv-show="inline_rating"] {
        width: 100%;
        position: absolute;
        top: 5px;
    }
}

.ie11,
.ie10 {
    .product-items [data-bv-show="inline_rating"] > a {
        display: inline-block !important; // sass-lint:disable-line no-important

    }
}

@include min-screen($screen__s) {

    // hide stock on
    .catalog-category-view {
        .product-item-actions .actions-primary {
            .stock {
                padding: 17px;
                bottom: -15px;
                &:after {
                    top: 9px;
                    right: 8px;
                }
                span {
                    display: none;
                }
            }
        }
    }
}
