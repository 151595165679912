//
//  Login container
//  ---------------------------------------------

.aw-rma-new-guest, 
.login-container {

    .block {

        .block-title {
            text-transform: uppercase;
        }
        
        &.block-new-customer,
        &.block-guest-order {

            @include min-screen($screen__s) {
                padding: 20px 0;
                box-sizing: border-box;     
            }
        }

        &.block-customer-login {
            padding: 20px;
            background-color: $color-grey7;
            box-sizing: border-box;     

            @include max-screen($screen__s) {
                margin-right: -15px;
                margin-left: -15px;
                margin-bottom: 15px;

                .block-title {
                    display: none;
                }
            }

            .form-login {
                .field.note { 
                    display: none;
                }
            }
        }
    }

    .fieldset:after {
        margin-top: 15px;
    }

    .fieldset > .field:not(.choice) > .label {
        padding: 6px 15px 0 0;
        text-align: left;
        width: auto;
        box-sizing: border-box;
        float: inherit;
        font-weight: 600;
    }

    .fieldset > .field:not(.choice) > .control {
        width: auto;
        float: inherit;
    }
}

.form-login {
 
    .actions-toolbar {
        margin-left: 0 !important; // sass-lint:disable-line no-important
    }
}
