.customer-menu {
    @include lib-dropdown();
    display: none;

    .customer-menu__user-loggedin {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 1px;
        right: 5px;
        background-color: $color-grey2;
        border: 2px solid white;
        border-radius: 50%;
        z-index: 10;
        pointer-events: none;

        &:after {
            @include icon($icon: 'checkmark-white');
            position: absolute;
            top: 4px;
            left: 1px;
            transform: scale(0.6);
        }
        
    }

    .action.toggle {
        min-width: 25px;
        vertical-align: middle;
        line-height: 0;
        display: flex;
        align-items: center;
        padding-top: 10px;

        &:before {
            @include icon($icon: 'account'
                );
            line-height: 0;
            right: 10px;
            position: relative;
        }

        &:after {
            //@include icon($icon: angle-down);
            display: none;
        }

        &>span {
            @include lib-visually-hidden();
        }

        &.active {
            &:after {
                display: none;
            }
        }
    }

    .fieldset>.field {
        margin: 0 0 20px;

        &:not(.choice)>.control {
            width: 100%;
        }
    }

    ul.dropdown {
        max-width: 330px;
        width: 80vw;
        right: 0;
        top: calc(100% - 4px);
        padding: 30px;
        border-top: 1px solid $color-grey6;

        &.is--active {
            display: block;
        }

        &::before,
        &::after {
            right: 10px;
            left: auto;
        }

        &::after {
            right: 9px;
            left: auto;
        }

        &>li:hover {
            background: inherit;
            cursor: default;
        }

        .action.primary {
            @include btn-arrowed( //$size: 'large'
            );

        }

        .action.remind {
            font-size: 1.25rem;
        }

        .block {
            margin-bottom: 20px;

            &.block-customer-login {
                padding-bottom: 20px;
                border-bottom: 1px solid $color-grey6;
            }

            &:last-child {
                margin-bottom: 0;
            }

            #block-new-customer-heading {
                display: none;
            }

        }

    }
}

//
//  Tablet
//  ---------------------------------------------

@include min-screen($screen__m) {

    .customer-menu {
        display: inline-block;
    }

}

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__l) {
    .customer-menu {

        .action.toggle {
            margin-left: 25px;
        }
    }
}
