//
//  Category Pagination
//  ---------------------------------------------

.products.wrapper ~ .toolbar {
    .pages {
        margin: 0 auto;
    }
}

@include min-screen($screen__m) {

    .products.wrapper ~ .toolbar {
        .limiter {
            display: none;
        }

        .pages {
            float: none;
        }
    }

}
