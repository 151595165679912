
.custom-form {
    margin-bottom: 20px;
    padding: 40px !important; // sass-lint:disable-line no-important
    background: $color-grey7;

    @include max-screen($screen__m) {
        padding: 20px;
    }

    h2 {
        margin-top: 0;
    }

    .formbuilder-form {
        margin-top: 50px;

        .form-list {
            .field {
                padding-left: 2px;
            }
        }

        .formbuilder.form {
            margin-top: 50px;
        }

        .formbuilder .required em {
            display: none;
        }
        .formbuilder .subtemplate-wrapper > label {
            margin-bottom: 0;
            padding-bottom: 0;
            font-weight: $font-weight__heavier;
        }

        .formbuilder .subtemplate-wrapper {
            margin-bottom: 40px;
        }

        .formbuilder .list-options li {
            display: flex;
            align-items: center;
            width: 100%;

            .mage-error {
                margin-top: 4px;
                margin-left: 1em;
            }
        }

        .formbuilder li {
            float: left;
            margin-right: 50px;
        }

        .formbuilder .formbuilder-button {
            border-radius: 0;

            &:after {
                @include icon($icon: arrow-right-white);
                margin-left: 5px;
                vertical-align: middle;
            }
        }

        .formbuilder .input-box {
            input[type="file"] {
                display: none;
            }

            label.file-input {
                @include btn();
                display: inline-flex;
                align-items: center;
                margin-top: 10px;
                background: $color-white;
                border-color: $color-white;
                color: $color-matt-black;

                &:hover {
                    background: darken($color-white, 1%);
                    border-color: darken($color-white, 1%);
                    color: $color-matt-black;
                }

                &:after {
                    @include icon($icon: upload);
                    margin-left: 10px;
                    vertical-align: middle;
                    transform: scale(0.8);
                }
            }

            select {
                background-color: transparent;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='7' version='1'%3E%3Cpath d='M5 4l4-4a1 1 0 0 1 1 2L6 6H5L0 2a1 1 0 1 1 2-2l3 4z' fill='%231F1F1F' fill-rule='evenodd'/%3E%3C/svg%3E") !important; // sass-lint:disable-line no-important
                background-size: 11px; // sass-lint:disable-line no-important
                background-position: 98% 18px; // sass-lint:disable-line no-important
                background-repeat: no-repeat;
            }
        }
    }

    .buttons-set {
        padding-left: 0 !important; // sass-lint:disable-line no-important
        padding-right: 0;
    }
}
