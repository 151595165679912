//
//  Create account
//  ---------------------------------------------

.form-create-account {

    .fieldset > .field:not(.choice) > .label {
        padding: 6px 15px 0 0;
        text-align: left;
        width: auto;
        box-sizing: border-box;
        float: inherit;
        font-weight: 600;
    }

    .fieldset > .field:not(.choice) > .control {
        width: auto;
        float: inherit;
    }

    .actions-toolbar {
        margin-left: 0 !important; // sass-lint:disable-line no-important
    }

    .fieldset {
        > .field.choice:before,
        > .field.no-label:before {
            display: none;
        }

        > .field.newsletter {
            margin-bottom: 0;
        }
    }

    .field-recaptcha {
        margin-top: 30px;
    }

    .field[class*="agreement-"] input[type="checkbox"] + label:before {
        margin-right: 6px;
    }
}
