.breadcrumbs {
    max-width: $layout__max-width;
    margin: 15px auto;

    .items {
        font-size: $font-size__s;
    }

    .item {
        color: #656565;
    }

    @include max-screen($screen__s) {
        .catalog-product-view & {
            display: none;
        }
    }
}
