#customer-email-fieldset {
    .field.field-email-confirmation {
        margin-top: $indent__s;

        @include min-screen($screen__m) {
            margin-top: $indent__l;
        }
    }
}

.onestepcheckout-index-index {
    .page-main {
        .checkout-container {
            .aw-onestep-main {
                .email {
                    max-width: unset;

                    @include min-screen($screen__m) {
                        max-width: 50%;
                    }
                }
            }
        }
    }
}
