//
//  Form variables
//  _____________________________________________

$form-element-input__border						: 1px solid $color-grey5;
$form-element-input__border--focus              : 1px solid $color-matt-black;
$form-element-input__border-radius				: 0;
$form-element-input__height                     : 40px;
$form-element-input__padding                    : 0 10px;
$form-element-input__font-size                  : $font-size__base;
$form-element-input__font-family                : $font-family__base;
$form-element-input__transition                 : border-bottom-color 0.2s ease-in-out;

//
//  Input-text
//  ---------------------------------------------

$input-text__border								: $form-element-input__border;
$input-text__border-radius						: $form-element-input__border-radius;
$input-text__height								: $form-element-input__height;
$input-text__padding							: $form-element-input__padding;
$input-text__font-size                          : $form-element-input__font-size;

//
//  Select
//  ---------------------------------------------

$select__border                                 : 1px solid $color-grey6;
$select__border-radius                          : $form-element-input__border-radius;
$select__height                                 : $form-element-input__height;
$select__padding                                : $form-element-input__padding;
$select__font-size                          	: $form-element-input__font-size;

//
//  Textarea
//  ---------------------------------------------

$textarea__border                               : $form-element-input__border;
$textarea__border-radius                        : $form-element-input__border-radius;
$textarea__padding                              : 18px;
$textarea__font-size                            : $form-element-input__font-size;
