.gallery-previous-button {
    @include icon($icon: arrow-right-black);
    position: relative;
    top: 45px;
    left: 15px;
    transform: rotate(180deg);
    z-index: 2;
    cursor: pointer;

    @include min-screen($gallery-breakpoint__desktop) {
        display: none;
    }
}
