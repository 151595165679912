//
//  Product Slider
//  ---------------------------------------------

.column.main .block.upsell,
.column.main .block.crosssell,
.block.widget.block-products-list {
    position: relative;

    .title {
        margin-bottom: 30px;

        strong {
            font-size: $h2__font-size;
        }
    }

    .product-items {
        margin: 30px 0;
        margin-left: 0;
    }

    .product-item {
        padding-left: 0;
        position: relative;

        .action.unavailable {
            @include btn-arrowed();
            display: none;
            position: absolute;
            bottom: 0;
            right: 12.5px;
            padding: 10px 40px 10px 16px;

            &:after {
                top: 11px;
                right: 14px;
            }
        }

        .action.tocart {
            @include btn-cart();
            display: none;
            position: absolute;
            bottom: 0;
            right: 12.5px;
            width: 35px;
            height: 35px;
            padding: 0;
            margin-bottom: -2em;

            span {
                display: none;
            }

            &::before {
                top: 6px;
                left: 6px;
            }
        }

        &:hover {
            .action.tocart {
                display: block;
            }

            .action.unavailable {
                display: flex;
                align-items: center;
            }
        }

        .product.name {
            a {
                font-size: 1.8rem;
                font-weight: $font-weight__semibold;
            }
        }

        img.product-image-photo {
            height: auto;
        }
    }

    .product-item-subtitle {
        width: 100%;
        height: 2em;
    }

    .product-item-info {
        width: 100%;
        padding-bottom: $indent__xs;
    }

    .product-image-container {
        width: 100% !important; // sass-lint:disable-line no-important
    }

    .bbd-slider {
        margin: 0;

        &__inner {
            margin: 0 -#{$layout__width-xs-indent};
            padding: 0 ($layout__width-xs-indent - $indent__xs);
            overflow: hidden;
        }

        &__slide {
            padding: 0 $indent__xs;
        }

        &__arrows {
            right: 0;
            top: -40px;
        }
    }

    .slick-list {
        overflow: visible;
    }
}

//
//  Mobile
//  ---------------------------------------------

@include max-screen($screen__s) {
    .pb--full-width {
        .product-item-info {
            margin-left: 0% !important; // sass-lint:disable-line no-important
        }
    }
}

//
//  Tablet
//  ---------------------------------------------

@include max-screen($screen__m) {
    .pb--full-width {
        .block.widget.block-products-list {
            .bbd-slider__inner {
                margin: 0 -15px;
                padding: 0 17vw;
            }

            .product-item-info {
                margin-left: -50%;
            }
        }
    }
}

@include min-screen($screen__m) {
    .pb--full-width {
        .block.widget.block-products-list {
            .product-item-info {
                //margin-left: -50%;
                position: relative;
            }
        }
    }
}

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__m) {

    .column.main .block.upsell,
    .column.main .block.crosssell,
    .block.widget.block-products-list {

        .title {
            strong {
                font-size: $h2__font-size-desktop;
            }
        }

        .bbd-slider {
            &__inner {
                margin: 0 -#{$layout-indent__width};
                padding: 0 ($layout-indent__width - 12.5px);
            }

            &__slide {
                padding: 0 12.5px;
            }
        }
    }
}

//
//  Desktop XXL
//  ---------------------------------------------

@include min-screen($screen__xxl) {
    .pb--full-width {
        .block.widget.block-products-list {
            .bbd-slider__arrows {
                max-width: 1340px;
                left: 50%;
                margin-left: -670px;
                text-align: right;
            }

            .bbd-slider {
                margin-left: calc(50% - 50vw);
                margin-right: calc(50% - 50vw);
            }

            .bbd-slider__inner {
                margin: 0;
                padding: 0 17vw;
            }
        }
    }
}
