.lazyload {
    //opacity: 1;
    //-webkit-transition: opacity 0.8s;
    //-moz-transition: opacity 0.8s;
    //-ms-transition: opacity 0.8s;
    //-o-transition: opacity 0.8s;
    //transition: opacity 0.8s;
    visibility: visible;
}

img.lazyload[src*="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII="] {
    opacity: 1;
    //visibility: hidden;
    //height: 0;
}
