//
//  Footer Payment Methods
//  ---------------------------------------------

.footer-payment-methods {
    margin-top: 50px;
}

@include max-screen($screen__l) {
    .footer-payment-methods {

        p {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: baseline;
        }

        img {
            max-width: 350px;
            margin-right: 10px;
        }
    }
}

@include max-screen($screen__xxs) {
    .footer-payment-methods {

        img {
            max-width: 300px;
            margin-right: 0;
        }
    }
}