table {
    font-size: $font-size__base;

    thead {
        tr {
            font-weight: $font-weight__heavier;
        }
    }

    tbody {
        tr {
            font-weight: normal;
        }
    }


    // Overwrite Magento vendor styling
    .product-item-name {
        font-size: $font-size__base;
        font-weight: normal;
    }

    .product-item {
        .product.name {
            a {
                font-size: $font-size__base;
                font-weight: normal;
            }
        }
    }
    
    .price-excluding-tax,
    .price-including-tax {
        .price {
            font-size: $font-size__base;
            font-weight: normal;
        } 
    }
}

