.c-targets {
    position: static;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    list-style: none;
    counter-reset: targetNumber;
}

.c-targets__item {
    position: absolute;
    transform: translate(-50%, -50%);
}

.c-target__button {
    cursor: pointer;
    position: relative;
    width: $target-width;
    height: $target-width;
    border-radius: 50%;
    border: 2px solid $color-gray-3;
    background: rgba($color-gray-4, 0.5625);
    color: $color-gray-3;
    transition: all $transition-duration;
    overflow: visible; // because IE ¯\_(ツ)_/¯
    font-size: 1em;
    padding: 0;

    &::after {
        $size: 1.5em;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-75%, -50%);
        background: $color-gray-1;
        color: white;
        counter-increment: targetNumber;
        content: counter(targetNumber);
        width: $size;
        height: $size;
        line-height: 1.7;
        text-align: center;
        border-radius: 50%;
        font-weight: 600;
        font-size: 1em;
    }
}

.c-target__button.active,
.c-target__button:hover,
.c-target__button:focus,
.c-target__button.checked {
    color: $color-gray-1;
    border-color: rgba($color-gray-1, 0.75);
}


/*  =========================================================================
    Placement
    use top and left positions only
    ========================================================================= */


// 1) legs
.c-targets__item--legs {
    top: 85%;
    left: 2.5%;
}

.c-preview--legs-yes .c-targets__item--legs {
    top: 88.15%;
    left: 11.4%;
}

.c-preview--size-large.c-preview--legs-no .c-targets__item--legs {
    top: 90%;
    left: 3.5%;
}

.c-preview--size-large.c-preview--legs-yes .c-targets__item--legs {
    top: 88.9%;
    left: 11%;
}

// 2) operation
.c-targets__item--operation {
    left: 50%;
}

.c-preview--operation-touch .c-targets__item--operation {
    top: 1%;
}

.c-preview--operation-pedal .c-targets__item--operation {
    top: 81%;
}

// 3) color
.c-targets__item--color {
    left: 1%;
    top: 43%;
}

.c-preview--legs-no .c-targets__item--color {
    left: 1.5%;
    top: 48%;
}

.c-preview--legs-yes.c-preview--size-large .c-targets__item--color {
    left: 0.5%;
    top: 40%;
}


// 4) size
.c-targets__item--size {
    left: 2.5%;
    top: 2%;
}

.c-preview--size-medium.c-preview--legs-no .c-targets__item--size {
    top: 2.75%;
}

.c-preview--size-large.c-preview--legs-no .c-targets__item--size {
    top: 2%;
    left: 4%;
}

.c-preview--size-large.c-preview--legs-yes .c-targets__item--size {
    top: 2%;
    left: 1.75%;
}

// 5) compartments
.c-targets__item--compartments {
    left: 50%;
    top: 43%;
}

.c-preview--legs-no .c-targets__item--compartments {
    top: 25%;
}

.c-preview--legs-yes.c-preview--size-large .c-targets__item--compartments {
    top: 30%;
}
