.tbw-layout-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 -#{$indent__s};

    &.is-selected {
        .tbw-layout-grid__option:not(.active) {
            opacity: 0.5;
            transition: opacity 0.2s ease-in-out;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.tbw-layout-grid__option {
    flex: 0 0 calc(100% - #{$indent__s * 2});
    margin: $indent__s;
    cursor: pointer;
    overflow: hidden;

    &:hover {
        .tbw-layout-grid__label {
            background: $color-grey2;
            border: 1px solid $color-grey2;
            color: $color-white;
        }
    }

    @include min-screen($screen__xxs) {
        flex: 0 0 calc(50% - #{$indent__s * 2});
    }

    @include min-screen($screen__s) {
        flex: 0 0 calc(#{percentage(1/4)} - #{$indent__s * 2} );
    }
}

.tbw-layout-grid__image {
    line-height: 0;
    border: 1px solid #000;
    text-align: center;

    img {
        box-sizing: border-box;
    }
}

.tbw-layout-grid__label {
    @include btn-arrowed();
    width: 100%;
    height: 100%;
    transition: background-color 0.2s ease-in-out;
}
