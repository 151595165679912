$mobile-header-height: 50px;

.block-mobile {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    height: $mobile-header-height;
    border-top: 1px solid $color-grey6;
    padding: 0;
    z-index: 110;
    margin: 0 0;
    background: white;
    
    @include max-screen($screen__m) {
        transition: transform 150ms ease-in-out;
        .no-scroll & {
            transform: translateY(100%);
        }
    }
}

.nav-sections {
    display: inline-block;
    width: 50px;
    height: 50px;
    position: relative;
}

.navigation {
    height: calc(100vh - 50px);
    position: fixed;
    bottom: $mobile-header-height;
    left: 0;
    top: 100%;
    width: 100%;
    background: white;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.2s ease-in-out;

    &.is--active {
        top: 0;
        transition: 0.2s ease-in-out;
    }

    ul.nav-main__extra {
        padding: 0;
    }
}

@include max-screen($screen__m) {
     .highlight__card {
         display: none;
     }
 }

.block-nav {
    display: inline-block;
    margin-left: 1.5em;
}

.nav-main__extra ul {
    margin: 0;
    padding: 0;
}

.navigation .nav-main__list {
    list-style: none;
    margin: 0;
    padding: 3em 1.2em;
    text-transform: uppercase;
    white-space: nowrap;

    a {
        animation: animateout 0.3s;
        font-size: $font-size__l;
        font-weight: bold;
        text-decoration: none;
        margin-bottom: 1.3em;
        display: block;

        &:hover {
            text-decoration: underline;
        }
    }
}

.submenu__holder {
    margin-bottom: 2.5em;
}

.navigation .nav-main__sub {
    display: block;
    padding: 0;
    margin: 0;
    white-space: normal;

    a {
        font-size: $font-size__l;
        font-weight: normal;
        text-transform: none;

        &:after {
            @include icon($icon: 'angle-right');
            float: right;
            opacity: 0.3;
            position: relative;
            bottom: -5px;
        }
    }

    .has-active > a {
        @include max-screen($screen__l) {
            color: $color-grey4;
        }
    }

    .submenu {
        position: fixed;
        display: block;
        width: 100%;
        right: -100%;
        top: 0;
        transition: right 0.3s;
        background: white;
        padding: 3em 1.2em;
        z-index: 50;
        box-sizing: border-box;
        height: calc(100vh - 50px);

        &.is--active {
            right: 0;
        }

        a {
            text-transform: uppercase;
            font-weight: bold;
        }

        .active a {
            color: $color-grey4;
        }
    }
}

.submenu__backbutton {
    @include lib-button-as-link();
    font-weight: normal;
    font-size: $font-size__s;
    text-decoration: none;
    margin-bottom: 2.2em;

    &:before {
        @include icon($icon: 'arrow-left-black');
        position: relative;
        bottom: -3px;
        margin-right: 8px;
    }

    &:hover {
        background: transparent;
        color: $color-matt-black;
    }
}

.nav-sections-item-title {
    position: relative;

    @include max-screen($screen__m) {
        &::before {
            width: 100%;
            height: 100%;
            position: fixed;
            background: white;
            content: '';
            z-index: 1;
        }
    }
}

.nav-sections-item-switch {
    font-size: 10px;
    text-align: center;
    text-decoration: none;
    width: 50px;
    height: 40px;
    position: relative;
    display: block;
    padding-top: 10px;
    z-index: 1;

    &:before {
        @include icon($icon: 'menu');
        margin: 0 auto;
        display: block;
    }

    &.is--active:before {
        @include icon($icon: 'close');
        margin: 0 auto;
        display: block;
    }
}

.nav-main__my-account--logout a {
    font-size: $font-size__l;
    font-weight: normal !important; // sass-lint:disable-line no-important
    text-transform: none;

    &:after {
        @include icon($icon: 'angle-right');
        float: right;
        opacity: 0.3;
        position: relative;
        bottom: -5px;
    }
}

@keyframes animatein {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
