$input-checkbox-ratio: 15px;

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: $form-element-input__border;
    transition: $form-element-input__transition;

    &:focus {
        border-top: none;
        border-right: none;
        border-left: none;
        box-shadow: none;
        border-bottom: $form-element-input__border--focus;
        transition: $form-element-input__transition;
    }
}

select {
    appearance: none;
    outline: none;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='7' version='1'%3E%3Cpath d='M5 4l4-4a1 1 0 0 1 1 2L6 6H5L0 2a1 1 0 1 1 2-2l3 4z' fill='%231F1F1F' fill-rule='evenodd'/%3E%3C/svg%3E") !important; // sass-lint:disable-line no-important
    background-size: 11px; // sass-lint:disable-line no-important
    background-position: 98% 18px; // sass-lint:disable-line no-important
    background-repeat: no-repeat;
    box-shadow: none;
    border-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: $form-element-input__border;
    border-color: $color-grey4;
    border-radius: 0;
    color: $color-grey3;
    transition: $form-element-input__transition;
    padding: 0 10px;
    font-family: $font-family__base;

    &:focus {
        border-bottom: $form-element-input__border--focus;
        transition: $form-element-input__transition;
    }
}

select::-ms-expand {
    display: none;
}

input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

input[type="checkbox"] + label {
    text-decoration: none !important;  // sass-lint:disable-line no-important
    position: relative;
    cursor: pointer;
    padding: 0;
}

input[type="checkbox"] + label:before {
    display: inline-block;
    width: $input-checkbox-ratio;
    height: $input-checkbox-ratio;
    position: relative;
    top: 3px;
    margin-right: 10px;
    background: white;
    border: 1px solid $color-grey3;
    border-radius: 2px;
    content: '';

    @include min-screen($screen__xxl) {
        top: 2px;
    }
}

input[type="checkbox"] + label:after {
    width: $input-checkbox-ratio;
    height: $input-checkbox-ratio;
    position: absolute;
    top: 2px;
    left: 2px;
    transform: scale(0.8);
}

// fix for required (error) on customer/account/create/
input[type="checkbox"] + label.label:after {
    content: '' !important;   // sass-lint:disable-line no-important
    margin: 0  !important;   // sass-lint:disable-line no-important
}

input[type="checkbox"]:checked + label:before {
    border-color: $color-matt-black;
}


input[type="checkbox"]:checked + label:after {
    @include icon($icon: 'checkmark');
}


input[type="checkbox"]:disabled + label {
    cursor: auto;
    opacity: 0.7;
}

input[type="checkbox"]:disabled + label:before {
    opacity: 0.7;
}

input[type="checkbox"]:focus + label {
    text-decoration: underline;
}

input[type="radio"] {
    position: absolute;
    opacity: 0;

    + label {
        position: relative;
    }

    + label:before {
        display: inline-block;
        width: $input-checkbox-ratio;
        height: $input-checkbox-ratio;
        position: relative;
        top: 3px;
        margin-right: 10px;
        background: white;
        border: 1px solid $color-grey3;
        border-radius: 50%;
        content: '';

        @include min-screen($screen__xxl) {
            top: 2px;
        }
    }
}

input[type="radio"]:checked + label:before {
    border-color: $color-matt-black;
}

input[type="radio"]:checked + label:after {
    display: inline-block;
    width: 7px;
    height: 7px;
    top: 2px;
    left: 4px;
    margin-right: 10px;
    background: $color-matt-black;
    border: 1px solid $color-matt-black;
    border-radius: 50%;
    content: '';
    position: absolute;

    @include min-screen($screen__xxl) {
        top: 1px;
    }
}

input[type="radio"]:focus + label {
    text-decoration: underline;
}

input[type="radio"]:disabled + label {
    opacity: 0.7;
}


.formbuilder {

    .form-fieldtype-price .form-currency {
        left: 8px;
        top: 5px;
    }

    input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        position: relative;
        background-color: white;
        color: $color-grey3;
        height: 17px;
        width: 17px;
        border: $color-grey3 solid 1px;
        border-radius: 50px;
        cursor: pointer;
        margin-right: 7px;
        outline: none;
    }

    input[type="radio"]:checked {
        background: white;
    }

    input[type="radio"]:checked::after {
        width: 11px;
        height: 11px;
        position: absolute;
        top: 2px;
        left: 2px;
        background-color: $color-black;
        border-radius: 50%;
        color: $color-grey1;
        text-align: center;
        content: '';
    }
}
