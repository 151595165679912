.catalog-product-view {
    .am-attachments-tab {
        &:before {
            @include icon(
                $icon: 'download'
            );
            float: left;
            margin-right: 10px;
        }

        .am-filelink {
            float: left;
            white-space: nowrap;
        }

        .am-fileicon {
            display: none;
        }

        .am-filesize {
            display: none;
        }
    }
}
