@include min-screen($screen__m) {

    .block-mobile {
        display: inline-flex;
        position: static;
        border: none;
        height: auto;
        margin: 0;
        padding-left: 4px;
        background: transparent;
    }

    .nav-sections {
        width: auto;
        height: auto;
        display: block;
        float: none;
        position: static;
    }

    .block-search {
        width: 30%;
        margin-left: 0;
        padding-left: 0;
        float: none;
    }

    .navigation {
        .has--sub .nav-main__sub,
        .block-cms-link {
            a {
                &:hover {
                    transition: 0.2s ease-in-out;
                    text-decoration: none;
                    color: $color-grey3;
                }
            }
        }
    }

    .navigation {
        display: block;
        height: auto;
        position: static;
        overflow: visible;

        .widget.block {
            margin: 0;
        }

        .nav-main__list {
            padding: 0;

            li:not(.submenu__presentation) {
                display: inline-block;
                position: static;
                vertical-align: top;
            }

            a {
                padding: 0.5em 0.7em;
                display: inline-block;
                font-weight: normal;
                margin: 0;
                transition: padding 0.2s;
            }

            .nav-main__sub {
                position: absolute;
                top: -150vh;
                left: 0;
                background: white;
                width: 100%;
                z-index: -100;
                padding: 0;
                border-top: 1px solid $color-grey6;
                transition: top 0.2s ease-in-out;

                &:after {
                    content: '';
                    display: block;
                    background: white;
                    width: 100vw;
                    position: absolute;
                    top: 90px;
                    left: 0;
                }

                li:not(.submenu__presentation) {
                    padding: 0 20px;
                }

                li.level-top {
                    display: inline-block;
                    float: left;
                    width: percentage(1/3);
                    box-sizing: border-box;
                    margin-bottom: 2em;
                }

                li a {
                    text-transform: none;
                    font-weight: bold;
                    font-size: $font-size__l;
                    padding-left: 0;
                    padding-bottom: 0;

                    &:after {
                        content: '\203A';
                        display: inline-block;
                        background: none;
                        width: auto;
                        height: auto;
                        opacity: 1;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        margin-left: 0.5em;
                    }
                }

                &.is--active {
                    top: 89px;
                    transition: top 0.2s ease-in-out;
                }

                .submenu {
                    display: block;
                    position: static;
                    height: auto;
                    padding: 0;

                    li {
                        display: block;
                        padding: 0;
                    }

                    a {
                        font-size: $font-size__base;
                        font-weight: normal;
                    }
                }
            }
        }
    }

    .nav-sections-item-switch,
    .navigation .nav-main__list .nav-main__my-account {
        display: none !important; // sass-lint:disable-line no-important
    }

    // Submenu full width background
    .submenu__presentation,
    li.submenu__presentation {
        display: block;
        max-width: $layout__max-width - 40px;
        margin: 0 auto;
        padding: 30px 40px 80px;
        overflow: hidden;
    }


    // Submenu layout
    .submenu__holder,
    .submenu__highlight {
        display: inline-block;
        float: left;
        box-sizing: border-box;
    }

    .submenu__highlight {
        padding-left: 20px;
        text-transform: initial;
    }

    // 'pointer' underneath active menu
    .has--sub.is--active a {
        position: relative;
    }


    .has--sub > a:before {
        content: '';
        width: 0;
        height: 0;
        transform: rotate(45deg);
        background: transparent;
        border: 1px solid transparent;
        border-left-color: transparent;
        border-top-color: transparent;
        margin: 0;
        line-height: 10px;
        position: absolute;
        left: 50%;
        bottom: -32px;
        margin-left: -5px;
        z-index: 5;
        transition: 0.1s ease-in-out;
    }

    .has--sub.is--active > a:before {
        width: 10px;
        height: 10px;
        background: white;
        border: 1px solid transparent;
        border-left-color: $color-grey6;
        border-top-color: $color-grey6;
        transition: 0.1s ease-in-out;
        transition-delay: 0.1s;
    }
}

.nav-main__list .has--sub > a:after {
    @include icon($icon: 'angle-down');
    content: '';
    display: inline-block;
    margin: 0 0 0 0.4em;
    position: relative;
    bottom: 2px;
}

@include max-screen($screen__m) {
    .nav-main__list .has--sub > a:after {
        display: none;
    }
}

@include screen($screen__m, $screen__l) {
    .navigation {
        .nav-main__list {
            .nav-main__sub {
                li.level-top {
                    width: 50%;
                }
            }
        }
    }

    // Submenu layout
    .submenu__holder {
        width: 80%;
    }

    .submenu__highlight {
        width: 20%;
    }
}

.nav-main__list .has--sub.is--active > a {

    &:after {
        @include icon($icon: 'angle-up');
    }
}

@include screen($screen__m, $screen__l) {
    .block-mobile {
        width: 80%;
    }
}

@include min-screen($screen__l) {
    .navigation .nav-main__list a {
        font-size: $font-size__base;
        line-height: 2.5rem;
    }

    .block-mobile {
        padding-left: 1rem;
        width: calc(100% - 215px);
    }

    // Submenu layout
    .submenu__holder {
        width: 70%;
        margin: 0 20px 0 -20px;
    }

    .submenu__highlight {
        width: 30%;
    }
}

@include max-screen($screen__l) {
    .navigation {
        .nav-main__list {
            .nav-main__sub {
                z-index: -10;

                &.is--active {
                    top: 60px;
                }
            }

            a {
                //font-size: $font-size__s;

                &:before {
                    bottom: -17px;
                }
            }
        }
    }
}

@include min-screen($screen__xl) {
    .nav-main__list a {
        padding: 0.5em 1.1em;
    }

}

@include min-screen($screen__xxl) {
    .navigation {
        .nav-main__list a {
            font-size: 1.8rem;
        }
    }
}

@include min-screen($screen__xl) {
    .has--sub > a:before {
        bottom: -29px;
    }
}
