.category-description {
    [data-element="main"] {
        >h2 {
            margin-bottom: 5px;
            font-size: $h2__font-size-desktop;
        }

        p:nth-child(2) {
            >em {
                @include text-secondary($_color: $heading__color__base);
                font-size: $h1__font-size;
                line-height: 1rem;
                margin: 1.1rem 0 2rem;
            }
        }

        // Pagebuilder class to create a 2 column block
        .two-columns-text {
            p:last-child {
                column-count: 2;

                .category-short-description & {
                    column-count: 1;
                }
            }
        }

    }

    [data-element="inner"] {
        padding: 0 !important; // sass-lint:disable-line no-important
    }
}

@include max-screen($screen__m) {
    .category-description {
        [data-element="main"] {

            // Pagebuilder class to create a 2 column block
            .two-columns-text {
                p:last-child {
                    column-count: 1;
                }
            }
        }
    }
}
