//
//  Account
//  ---------------------------------------------

.account {
    @include max-screen($screen__m) {
        .page-main {
            padding-top: 0;
        }

        .page.messages {
            margin-bottom: 0;
        }
    }

    .column.main {
        .toolbar {
            .limiter-options {
                padding-right: $indent__m
            }
        }
    }

    .block-dashboard-info,
    .block-dashboard-addresses,
    .block-dashboard-orders {
        .block-title {
            display: flex;
            align-items: center;

            h3 {
                margin: 0 !important; // sass-lint:disable-line no-important
            }
        }
    }

    .items.order-links {
        padding-bottom: $indent__s;
    }


    .fieldset {
        > .field.choice {
            margin-bottom: 0;
        }
    }
}
