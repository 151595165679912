//
//  Featured Slider
//  ---------------------------------------------

.featured-items {
    margin: 50px 0;

    &__toolbar {
        margin-bottom: $indent__m;
    }

    &__inspiration {
        display: none;

        .bbd-slider {
            .card {
                .card__text + .card__bottom {
                    margin-top: auto
                }

                .card__image {
                    position: relative;
                    height: 0;
                    padding-top: percentage(320/436);
                    overflow: hidden;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: auto;
                    }
                }
            }
        }
    }

    &__products {

        .bbd-slider {

            &__slides {
                visibility: visible;
                opacity: 1;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                flex: 0 1 auto;
                margin-right: -1 * $indent__m / 2;
                margin-left: -1 * $indent__m / 2;
            }

            &__slide {
                box-sizing: border-box;
                flex: 1 0 auto;
                max-width: 100%;
                margin-bottom: $indent__m;
            }
        }

        .card a.card__title,
        .card__title,
        .card .card__text {
            display: none;
        }

        .card__bottom {
            margin-top: $indent__m;
        }

    }

}

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__xs) {

    .featured-items {

        &__products {

            .bbd-slider {

                &__slide {
                    flex: 1 0 percentage(1/3);
                    max-width: percentage(1/3);
                }
            }
        }
    }
}

