//
//  RMA container
//  ---------------------------------------------

// .aw-rma {}

.aw-rma__title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $indent__l;

    @include max-screen($screen__s) {
        .aw-rma__actions {
            button:not(:last-child) {
                margin-bottom: $indent__s;
            }
        }   
    }
}

.aw-rma__view {
    .block-title {
        display: flex;
        align-items: center;

        h3 {
            margin: 0 !important; // sass-lint:disable-line no-important
        }
    }

    .box {
        padding: $indent__l;
        border: 1px solid $color-grey6;
        box-sizing: border-box;
    }

    .aw-rma__box-contact-info {
        .box-title {
            font-size: $font-size__base;
            font-weight: $font-weight__heavier;
        }
    }
}

.aw-rma-new__form,
.aw-rma__block-general,
.aw-rma__view-items {
    // Default fieldset and label styling
    .fieldset>.field:not(.choice)>.label {
        padding: 6px 15px 0 0;
        text-align: left;
        width: auto;
        box-sizing: border-box;
        float: inherit;
        font-weight: 600;
    }

    .fieldset>.field:not(.choice)>.control {
        width: auto;
        float: inherit;
    }

    .actions-toolbar {
        margin-left: 0 !important; // sass-lint:disable-line no-important
    }

    .fieldset {

        >.field.choice:before,
        >.field.no-label:before {
            display: none;
        }
    }

    .field-recaptcha,
    .field-recaptcha+.captcha {
        margin-top: 30px;
    }
}

// Return item container
.aw-rma__return-item-container {
    display: flex;
    flex-direction: column;
    padding: $indent__l;
    border: 1px solid $color-grey6;
    box-sizing: border-box;

    &:not(:last-child) {
        margin-bottom: $indent__m;
    }

    .item-details,
    .item-details .item-select {
        margin-bottom: $indent__s;
    }

    .item-return-details .fieldset {
        display: flex;
        flex-direction: column;

        .aw-rma__field:not(:last-child) {
            margin: 0 0 $indent__m 0 !important; // sass-lint:disable-line no-important
        }

        .qty-total {
            margin-top: $indent__s;
        }
    }

    .product-item-details a {
        display: block;
        margin-block-end: $indent__s;
        font-weight: $font-weight__heavier;
        font-size: $font-size__base;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .price-including-tax .price,
    .price-excluding-tax .price {
        font-size: $font-size__base;
        font-weight: normal;
    }

    .product-image-wrapper {
        padding-bottom: 135px !important;
    }
}

// Block tread container
.aw-rma__block-thread {
    .aw-rma__update_request,
    .aw-rma-view__thread-message:not(:last-child) {
        margin-bottom: $indent__m;
    }

    .messages {
        padding-left: $indent__l / 2;
    }

    // Fix default alignment for correct spacing with title
    .label.message {
        padding: 0 !important; // sass-lint:disable-line no-important
    }
}

// Block return massaction
.aw-rma__return-massaction {
    .action:not(:last-child) {
        margin: 0 $indent__m 0 0;
    }
}

// Table
.aw-rma__select-order-table {
    tbody {
        tr {
            border-bottom: 1px solid $color-grey6;
        }
    }
}

// Return items
.aw-rma__return-items {
    .product-item-image {
        display: none;
    }

    @include min-screen($screen__m) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .aw-rma__return-item-container {
            width: 48.8%;
        }

        .product-item-image {
            display: block;
        }
    }
}
