/*  =========================================================================
    Option navigation
    ========================================================================= */
.c-option-nav {
  display: block;
  margin: 0;
  transform: none !important;

  &:not(.c-option--current) {
    display: none;
  }

  @media (min-width: $min-width-lg) {
    position: absolute;
    top: 0;
    left: 0;
  }
}


.c-option-nav__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  counter-reset: step-count;
}

.c-option-nav__button {
  counter-increment: step-count;
  font-weight: 600;
  font-size: 1.125em;
  text-align: left;

  .c-button__label {
    &::before {
      content: counter(step-count) '. ';
    }
  }

  .c-chevron {
    width: 1ex;
    height: 1ex * (958 / 1600);
    margin-top: -1px;
  }

  &:hover,
  &:focus {
    outline: none;

    .c-button__label {
      text-decoration: underline;
    }
  }
}
