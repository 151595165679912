.service-parts {
    table {
        td:first-child {
            width: 90px;
        }

        > tbody {
            > tr {
                > td {
                    vertical-align: middle;

                    a {
                        position: relative;
                        padding-right: 22px;

                        &:after {
                            @include icon(
                                $icon: arrow-right-black,
                                $scale: 0.6
                            );
                            position: absolute;
                            right: 0;
                            top: 4px;
                        }
                    }
                }
            }
        }
    }

    @include max-screen($screen__s) {
        .pagebuilder-column-group > .pagebuilder-column {
            width: 100% !important; // sass-lint:disable-line no-important
        }
    }

    @include screen($screen__s, $screen__xl) {
        .pagebuilder-column-group {
            flex-direction: row;
            flex-wrap: wrap;

            > .pagebuilder-column {
                width: 50% !important; // sass-lint:disable-line no-important
            }
        }
    }

    @include min-screen($screen__m) {
        .pagebuilder-column {
            padding-left: 0 !important; // sass-lint:disable-line no-important
            padding-right: 0 !important; // sass-lint:disable-line no-important
        }
    }

    @include min-screen($screen__xl) {
        .pagebuilder-column-group {
            flex-direction: row;
            flex-wrap: wrap;

            > .pagebuilder-column {
                width: percentage(1/3) !important; // sass-lint:disable-line no-important
            }
        }
    }

    @include min-screen($screen__xxl) {
        font-size: 1.8rem;
    }

}
