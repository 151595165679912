.aw-acp-popup__related,
.page-products {

    .product-item-info,
    .product-image-container,
    .product-item-photo {
        width: 100% !important; // sass-lint:disable-line no-important
    }

    .products-grid:not(.bbd-slider) {
        .product-item-info {
            @include max-screen($screen__s) {
                display: flex;

                .product-item-photo {
                    margin-right: 20px;
                }

                .product-item-details {
                    width: 100%;
                }

                .price-box {
                    float: none;
                }

                .product-image-wrapper {
                    padding-bottom: 100% !important; // sass-lint:disable-line no-important
                }

                .product-item-name {
                    margin-top: 0;
                }
            }

            .product-image-wrapper {
                @include min-screen($screen__s) {
                    padding-bottom: 100% !important; // sass-lint:disable-line no-important

                    .product-image-photo {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.product-image-wrapper {
    @include min-screen($screen__s) {
        width: 100% !important; // sass-lint:disable-line no-important
        padding-bottom: 240px !important; // sass-lint:disable-line no-important
    }
}

.product-item {
    position: relative;

    .product-item-photo {
        display: block;
    }

    .product-item-details {
        .stock {
            display: none;
        }
    }

    .price-box.price-final_price {
        @include screen($screen__s, $screen__m) {
           display: flex;
           flex-direction: column;

           .special-price {
               order: 2;
           }
        }
    }

    .product-item__availability-label {
        color: $color-grey3;
        font-size: 1.3rem;

        @include min-screen($screen__s) {
            position: relative;
            top: 12px;
            margin-top: 17px;
        }
    }

    .price-box + .product-item__availability-label {
        @include min-screen($screen__s) {
            margin-left: 10px;
        }
    }
}

.product-item-name,
.product-item .product.name a {
    font-size: 1.8rem;
    font-weight: $font-weight__heavier;
}

.product-item-subtitle {
    display: inline-block;
    margin-bottom: 1.5em;
    color: $color-gray19;
    
    @include max-screen($screen__s) {
        margin-bottom: 0.5em;
    }
}

.product-item .price-box {
    margin-bottom: 0;
    float: left;
}

.product-item .price-box .price-wrapper .price {
    font-weight: $font-weight__heavier;
}

.products-grid .product-item-actions {
    margin: 0;
}

.product-item .action.unavailable {
    @include btn-arrowed();
    position: absolute;
    right: 12.5px;
    bottom: 0;
    padding: 10px 40px 10px 16px;
    transition: 0.2s opacity;
  
    @include min-screen($screen__l + 1) {
        opacity: 0;
    }

    &:after {
        top: 11px;
        right: 14px;
    }
}

.product-item .tocart {
    @include btn;
    @include btn-icon(default, 'cart-white');
    width: 35px;
    height: 35px;
    position: absolute;
    right: 0;
    bottom: -15px;
    padding: 7px;
    transition: 0.2s opacity;

    @include min-screen($screen__s) {
        opacity: 0;
    }

    &:before {
        top: 7px;
        left: 6px;
    }
}

@include min-screen($screen__s) {
    .product-item:hover {
        .tocart,
        .action.unavailable {
            opacity: 1;
        }
    }
}
