//
//  Promo Banner
//  ---------------------------------------------

.banner {
    margin-bottom: $indent__m;

    &__image {
        line-height: normal;
    }
}

.banner__btn,
a.banner__btn {
    @include btn-arrowed();
    font-size: 1.4rem;
}

//
//  Mobile
//  ---------------------------------------------

@include min-screen($screen__m) {
    .banner {
        position: relative;

        &__image {
            width: 100%;
        }

        &__overlay {
            position: absolute;
            left: $indent__m;
            bottom: $indent__m;
            background: $color-white;
            padding: $indent__m;
            box-sizing: border-box;
            width: 60%;
            max-width: 480px;

            p {
                @include text-large();
            }
        }
    }
}

//
//  Desktop
//  ---------------------------------------------

@include max-screen($screen__xl) {

    .banner {
        &__image {
            height: auto;
            line-height: 0;
            width: 100vw;
            @include object-fit($fit: cover);

            img {
                max-width: 100%;
                position: static;
                height: auto;
                bottom: auto;
                left: auto;
                right: auto;
            }
        }


    }

}


//
//  Desktop Large
//  ---------------------------------------------

@include min-screen($screen__xxl) {

    .banner {
        position: relative;

        &__image {
            position: relative;
            height: 580px;
            overflow: hidden;
            width: 100%;
            @include object-fit($fit: contain);

            img {
                max-width: 100%;
                height: auto;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
            }
        }
    }

    .banner__btn,
    a.banner__btn {
        @include btn-arrowed($size: large, $load-icon: false);
    }

}

@include max-screen($screen__m) {

    .banner__overlay {
        margin: $indent__m 0;
    }

    .banner__image {
        width: 100vw;
        margin-left: -#{$layout__width-xs-indent};
    }
}

@include min-screen($screen__m) {
    .banner__image {
        width: 100%;
    }
}
