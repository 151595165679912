//
//  Cart summary    
//  _____________________________________________

.cart-summary {
    width: 100%;
    background-color: $color-grey7;
    margin: 0;
    padding: 25px;

    > .title {
        margin-top: 0;
        text-transform: uppercase;
    }

    button.checkout {
        @include btn-arrowed();
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
            position: initial; // fallback ie
            position: unset;
            margin-left: 10px;
        }
    }

    .totals-tax {
        font-style: italic;
        opacity: .5;
    }

    fieldset.coupon label span {
        display: none;
    }
}
