//
//  Welcome
//  ---------------------------------------------

.page-layout-welcome {
    background: url("../images/welcome.jpg") no-repeat center;
    background-size: cover;
    height: 100%;

    .page-wrapper {
        height: 100%;
    }

    .page-main {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .welcome-container {
        background: $color-white;
        padding: 20px;
        min-width: 280px;
        max-width: 430px;
        width: 50vw;

        @include max-screen(479px) {
            width: 100%;
            max-height: 80vh;
            overflow-y: auto;
        }

        table {
            margin: 0 -10px 40px;

            td {
                width: 50%;

                @include max-screen(479px) {
                    display: block;
                    width: 100%;
                }
            }

            a {
                display: flex;
                position: relative;
                padding-left: 32px;

                img {
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin-right: 10px;
                    border-radius: 22px;
                }
            }
        }

        .logo {
            display: flex;
            width: 100%;
            max-width: none;
            margin: 0 auto;
            justify-content: center;
        }
    }
}

//
//  Mobile
//  ---------------------------------------------

@include max-screen($screen__m) {

    .page-layout-welcome {
        .welcome-container {
            padding: 15px;
            min-width: 270px;

            h1,
            h2,
            h3 {
                small {
                    display: block;
                    margin-left: 0;
                }
            }

            table {
                margin-bottom: 20px;

                > thead,
                > tbody,
                > tfoot {
                    > tr {
                        > th,
                        > td {
                            padding: 6px 10px;
                        }
                    }
                }
            }
        }
    }

}

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__m) {

    .page-layout-welcome {
        .welcome-container {
            padding: 60px;
        }
    }

}
