//
//  Typography
//  _____________________________________________

//
//  Headings
//  _____________________________________________

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    margin-bottom: $indent__s;
    font-family: $font-family__headings;

    small,
    .small,
    em {
        @include text-secondary(
            $_color: $heading__color__base
        );
        font-size: inherit;
        font-style: normal;
        margin-left: 10px;
    }
}

@include min-screen($screen__m) {
    h2 {
        @include lib-css(font-size, $h2__font-size-desktop);
    }

    h3 {
        @include lib-css(font-size, $h3__font-size-desktop);
    }

    h4 {
        @include lib-css(font-size, $h4__font-size-desktop);
    }

    h5 {
        @include lib-css(font-size, $h5__font-size-desktop);
    }

    h6 {
        @include lib-css(font-size, $h6__font-size-desktop);
    }
}

//
//  Text
//  _____________________________________________

.text-large {
    @include text-large();
}

.text-normal {
    @include text-normal();
}

.text-small {
    @include text-small();
}

//
//  Lists
//  ---------------------------------------------

.list-unstyled {
    @include list-unstyled();
}

.list-inline {
    @include list-inline();
}

