.accessories__tab .block.crosssell,
.accessories__tab h3 {
    margin-top: 0;
}

.page-layout-1column .accessories__tab .products-grid .product-item {
    // display: block;
    width: 100%;

    @include min-screen($screen__m) {
        display: inline-block;
        width: 48%;
    }

    @include min-screen($screen__l) {
        width: 33%;
    }

    @include min-screen($screen__xl) {
        width: 24%;
    }

    .product-image-photo {
        bottom: auto;
    }
}

.accessories__tab {
    .block.crosssell .product-items,
    .products {
        margin-bottom: 0 !important; // sass-lint:disable-line no-important

        @include max-screen($screen__s) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .product-item-info {
        width: 100%;
    }

    .product-items {
        .product-item-subtitle {
            display: inline-block;
        } 

        .product-item-details {
            padding-top: 0;
        }
    }

    @include max-screen($screen__s) {
        .product-image-wrapper {
            padding-bottom: 180px !important; // sass-lint:disable-line no-important
        }
    }
    
    .product-item {
        position: relative;

        .product-item-details,
        .product-item-photo {
            display: inline-block;
            float: left;
            width: 50%;
        }

        .product-item-photo {
            width: calc(50% - 15px);
            padding-right: 15px;
        }

        .product-item-name .product-item-link {
            font-size: 1.8rem;

            &:after {
                content: '\203A';
                font-size: 110%;
                padding-left: $indent__xs;
            }
        }

        .tocart span {
            @include lib-visually-hidden();
        }
    }
}
