
//
//  Block addresses
//  ---------------------------------------------

.block-addresses-default,
.block-addresses-list {
    @include boxContent;
}

.block-addresses-list {

    .actions {
        display: flex;
        justify-content: space-between;
    }
}

// remove address link
.mp-gdpr-delete {
    margin-left: 10px;
}
