.stories__100yearslogo {
    z-index: 2;
    position: absolute;
    left: 10%;
    display: inline-block;
    width: 200px;
    top: -2px;
    max-width: 40%;
    pointer-events: none;

    @media screen and (min-width: $screen__m) {
        max-width: 20%;
        left: 20%;
    }
}
.stories-logo__image {
    max-width: 100%;
    width: auto;
    height: auto;
}
