.tbw-layout-add-to-cart__product {
    max-width: 60%;
}

.tbw-layout-add-to-cart,
.tbw-layout-add-to-cart__to-cart-box {
    display: block;

    @include min-screen($screen__s) {
        display: flex;
        flex-direction: row;
    }
}

@include min-screen($screen__s) {
    .tbw-layout-add-to-cart__product {
        flex-grow: 1;
        margin-left: $spacer;
    }
}

.tbw-layout-add-to-cart__price {
    flex-grow: 1;
}

.tbw-layout-add-to-cart__image-thumbs img {
    width: 80px;
    height: 80px;
    cursor: pointer;
    margin-bottom: $spacer-xxs;
    margin-right: $spacer-xxs;
}

.tbw-layout-add-to-cart__price {
    font-weight: $font-weight__bold;
    font-size: $font-size__xl;
}

.tbw-layout-add-to-cart__add {
    @include btn-cart();
}
