[data-content-type="text"] {
    &.two-columns-text {
        p {
            column-count: 1;

            @include min-screen($screen__m) {
                column-count: 2;
            }
        }
    }
}
