//
//  Tags
//  _____________________________________________

@mixin tag(
    $background-color: $color-white,
    $text-color: $text__color,
    $border-color: $color-grey4
) {
    display: inline-block;
    margin: 0 6px 10px 0;
    padding: 3px 8px;
    cursor: pointer;
    background-color: $background-color;
    color: $text-color;
    border: 1px solid $border-color;
    border-radius: 4px;
    text-decoration: none;
}

@mixin tag-active(
    $background-color: $color-black,
    $text-color: $color-white,
    $border-color: $color-black
) {
    @include tag(
        $background-color: $background-color,
        $text-color: $text-color,
        $border-color: $border-color
    )
}
