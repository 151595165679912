$product-groups-block-transition-time: 250ms;
$product-groups-block-transition-ease: ease-in-out;

.product-groups-block {
    padding: 0 20px;

    @include min-screen($screen__m) {
        display: flex;
        flex-wrap: wrap;
        margin-right: -10px;
        margin-left: -10px;
        padding: 0;
    }
}

.product-groups-block__item { 
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    .card__image {
        width: 100%;
        height: 0;
        padding-top: 75%; // 4:3 Aspect Ratio
        position: relative;

        img {
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
        }
    }

    .card__bottom {
        margin-top: 20px;
    }

    img {
        @include object-fit(cover);
        height: 100%;
    }
    
    @include max-screen($screen__s) {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    @include max-screen($screen__s) {
        &:not(:last-child) {
            margin-bottom: 50px;
        }
    }

    @include min-screen($screen__m) {
        width: 50%;
        padding: 10px;
        box-sizing: border-box;
    }
    
    @include min-screen($screen__xl) {
        width: 33%;
        width: calc(100% / 3);
    }
}
