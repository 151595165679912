//
//  Calibri
//  _____________________________________________

/* latin */
@font-face {
	font-family: 'Calibri';
	font-style: normal;
	font-weight: 400;
    font-display: swap;
	src: local('Calibri'), url(https://fonts.gstatic.com/l/font?kit=J7afnpV-BGlaFfdAhLEY67FIEjg&skey=a1029226f80653a8&v=v10) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
	font-family: 'Calibri';
	font-style: normal;
	font-weight: 700;
    font-display: swap;
	src: local('Calibri Bold'), local('Calibri-Bold'), url(https://fonts.gstatic.com/l/font?kit=J7aanpV-BGlaFfdAjAo9_pxqHxIZrCE&skey=cd2dd6afe6bf0eb2&v=v10) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

//
//  Frutiger
//  _____________________________________________

@font-face {
	font-family: 'Frutiger';
	src: url('../fonts/Frutiger-Black-Cn.eot');
	src: url('../fonts/Frutiger-Black-Cn.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/Frutiger-Black-Cn.woff') format('woff'),
			 url('../fonts/Frutiger-Black-Cn.ttf') format('truetype'),
			 url('../fonts/Frutiger-Black-Cn.svg#Frutiger-Black-Cn') format('svg');
	font-weight: 700;
    font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Frutiger';
	src: url('../fonts/Frutiger-Bold-Cn.eot');
	src: url('../fonts/Frutiger-Bold-Cn.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Frutiger-Bold-Cn.woff') format('woff'),
	url('../fonts/Frutiger-Bold-Cn.ttf') format('truetype'),
	url('../fonts/Frutiger-Bold-Cn.svg#Frutiger-Black-Cn') format('svg');
	font-weight: 600;
    font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Frutiger';
	src: url('../fonts/Frutiger-Light.eot');
	src: url('../fonts/Frutiger-Light.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/Frutiger-Light.woff') format('woff'),
			 url('../fonts/Frutiger-Light.ttf') format('truetype'),
			 url('../fonts/Frutiger-Light.svg#Frutiger-Light') format('svg');
	font-weight: normal;
    font-display: swap;
	font-style: normal;
}

//
//  Carlito
//  _____________________________________________

@font-face {
	font-family: 'Carlito';
	src: url('../fonts/carlito-regular-webfont.woff2') format('woff2'),
			 url('../fonts/carlito-regular-webfont.woff') format('woff'),
			 url('../fonts/carlito-regular-webfont.ttf') format('truetype');
	font-weight: normal;
    font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Carlito';
	src: url('../fonts/carlito-bold-webfont.woff2') format('woff2'),
			 url('../fonts/carlito-bold-webfont.woff') format('woff'),
			 url('../fonts/carlito-bold-webfont.ttf') format('truetype');
	font-weight: 700;
    font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Carlito';
	src: url('../fonts/carlito-bolditalic-webfont.woff2') format('woff2'),
			 url('../fonts/carlito-bolditalic-webfont.woff') format('woff'),
			 url('../fonts/carlito-bolditalic-webfont.ttf') format('truetype');
	font-weight: 700;
    font-display: swap;
	font-style: italic;
}

@font-face {
	font-family: 'Carlito';
	src: url('../fonts/carlito-italic-webfont.woff2') format('woff2'),
			 url('../fonts/carlito-italic-webfont.woff') format('woff'),
			 url('../fonts/carlito-italic-webfont.ttf') format('truetype');
	font-weight: normal;
    font-display: swap;
	font-style: italic;
}

//
//  VinceHand
//  _____________________________________________

@font-face {
	font-family: 'VinceHand';
	src: url('../fonts/vinhnd2c-webfont.eot');
	src: url('../fonts/vinhnd2c-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/vinhnd2c-webfont.woff') format('woff'),
	url('../fonts/vinhnd2c-webfont.ttf') format('truetype'),
	url('../fonts/vinhnd2c-webfont.svg#vincehand_ii_cregular') format('svg');
	font-weight: normal;
    font-display: swap;
	font-style: normal;
}
