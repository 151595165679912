/*  =========================================================================
    Options
    ========================================================================= */

.c-options {
  display: block;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  flex: 1 0 auto;
}

.c-option {
  $legend-font-size: 1.125em;

  // transition: transform 250ms;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 1em + $legend-font-size;
  background: white;
  border: none;

  &:not(.c-option--current) {
    transform: translateX(100%);
  }

  &.c-option--prime-transition { // thanks, Safari!
    transition: transform 250ms;
  }

  legend {
    position: absolute;
    top: 1em;
    left: 0;
    display: block;
    font-weight: 600;
    font-size: $legend-font-size;
    line-height: 1;
  }
}

.c-option__choices {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.c-option__choice {
  margin-top: 1em;
}

.c-option__label {
  cursor: pointer;
  color: $color-gray-2;

  input {
    @include sr-only;
    &:checked ~ .c-option__fake-input,
    &:checked ~ .c-option__label-inner {
      color: $color-gray-1;
    }

    &:checked ~ .c-option__fake-input {
      box-shadow: inset 0 0 0 0.25em white;
    }

    &:disabled ~ .c-option__fake-input,
    &:disabled ~ .c-option__label-inner {
      cursor: not-allowed;
      color: $color-gray-3 !important;
    }

    &[hidden] ~ .c-option__fake-input,
    &[hidden] ~ .c-option__label-inner {
      display: none;
    }
  }

  &:hover,
  &:focus {
    color: $color-gray-1;
  }
}

.c-option__fake-input {
  color: inherit;
  display: inline-block;
  vertical-align: middle;
  background: currentColor;
  border: 1px solid currentColor;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  box-shadow: inset 0 0 0 0.5em white;
  transition: all $transition-duration;

  input:checked ~ & {
    color: $color-gray-1;
  }
}

.c-option__label-inner {
  position: relative;
  display: inline-block;
  padding-left: 0.6em;
  margin-left: -0.1em;
}

// color swatches for color options
[name="color"] ~ .c-option__label-inner::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 1.75em;
  height: 1.75em;
  margin-right: 0.5em;
  border-radius: 50%;
  background: $color-gray-4 no-repeat center;
  background-size: contain;
}
