
//
//  Icons
//  ---------------------------------------------

@mixin icon(
    $icon,
    $scale: null,
    $display: inline-block,
    $load-icon: true
) {
    @if $load-icon == true {
        @extend .icon-#{$icon} !optional;
        @extend .icon-#{$icon}-dims !optional;
    }

    content: '';
    display: $display;

    @if $scale != null {
        transform: scale($scale);
    }
}

@mixin select-dropdown-icon($direction: up) {
    @if $direction == up {
        &:after {
            @include icon($icon: angle-up);
            position: absolute;
            top: 40%;
            right: 15px;
        }
    }

    @if $direction == down {
        &:after {
            @include icon($icon: angle-down);
            position: absolute;
            top: 40%;
            right: 15px;
        }
    }
}
