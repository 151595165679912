/*  =========================================================================
    Bo Configurator
    one sass-file to rule them
    ========================================================================= */

// throwaway
//main#bo-wrapper {
//  display: block;
//  margin: 0 auto;
//  max-width: 1030px;
//  padding: 0 1em;
//  font-family: 'Source Sans Pro', sans-serif;
//}

#bo-configurator {
  display: block;
  position: relative;
  font-size: 1em;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  $transition-duration: 100ms;
  $target-width: 48px;
  $min-width-md: 640px;
  $min-width-lg: 960px;

  @import 'settings/colors';

  @import 'utilities/sr-only';
  @import 'utilities/translation-error';

  @import 'components/chevron';
  @import 'components/check';
  @import 'components/button';
  @import 'components/options';
  @import 'components/container';
  @import 'components/targets';
  @import 'components/option-nav';
  @import 'components/summary';
  @import 'components/incompatibilities';
  @import 'components/bin-config';
  @import 'components/undo';
  @import 'components/ar';
  @import 'components/preview';
  @import 'components/loading';
  @import 'components/price';

  color: $color-gray-1;
  line-height: 1.25;

  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin: 0 -1em;

  @media (min-width: $min-width-lg) {
    flex-wrap: nowrap;
  }
}
