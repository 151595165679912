//
//  Product Detail Page Selected items
//  ---------------------------------------------

.selected-items {
    @include list-unstyled;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
}

.selected-items__item {
    margin: 0 10px 10px 0;
    padding: 2px 6px;
    border: 1px solid $color-grey5;
    border-radius: 2px;
    cursor: pointer;
}

.selected-items__close-button {
    @extend .icon-close-dims;
    @extend .icon-close;
    padding: 0;
    border: 0;
    transform: scale(0.65);

    &:hover,
    &:focus,
    &:active {
        @extend .icon-close-dims;
        @extend .icon-close;
        border: 0;
    }
}
