.title-subtitle-text {
    .title-subtitle-text__title-container {
        margin-bottom: 2rem;

        &--inline {
            display: inline-flex;
        }
    }

    &__title {
        font-size: 2.8rem;

        .title-subtitle-text__title-container & {
            margin-bottom: 0;
        }
    }

    &__subtitle {
        @include text-secondary(
            $_color: $heading__color__base
        );
        font-size: 2.8rem;
        margin: 1.6rem 0 2rem 1.5rem;

        .title-subtitle-text__title-container & {
            margin-left: 0;
        }
    }

    &__text {
        @include min-screen($screen__m) {
            column-count: 2;
            column-width: auto;
        }
    }
}

@include max-screen($screen__s) {
    .title-subtitle-text__title-container {
        margin-bottom: 2rem;

        &--inline {
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
        }
    }

    .title-subtitle-text__title {
        margin-bottom: 0;
    }

    .title-subtitle-text__subtitle {
        margin: 0;
    }
}
