.mfp-fade-zoom.mfp-ready.mfp-bg {
    opacity: 0.5 !important; // sass-lint:disable-line no-important
}

.mfp-container {
    left: initial !important; // sass-lint:disable-line no-important
    margin: 0 auto;
    max-width: 500px;
    position: relative !important; // sass-lint:disable-line no-important
    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
    }
}

// exclude add-to-cart pop-up
.aw-acp-popup-container .mfp-container {
    max-width: 100%;
}

.mfp-content {
    text-align: left !important; // sass-lint:disable-line no-important
}



button.mfp-close {
    &:before {
        @include icon($icon: close);
    }
    color: transparent !important; // sass-lint:disable-line no-important
    opacity: 1 !important; // sass-lint:disable-line no-important
    margin: 20px 18px 0 0;
}
