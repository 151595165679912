body:after {
    content: '';
    height: 100%;
    background: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: background 0.1s ease-in-out;
    z-index: -9999;
    opacity: 0;
}

.store-switcher-is--active:after,
.customer-menu-is--active:after,
.minicart-is--active:after {
    content: '';
    height: 100%;
    background: rgba(0, 0, 0, 0.54);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    transition: background 0.1s ease-in-out;
    opacity: 1;
}

.menu-is--active:after {
    content: '';
    height: 100%;
    background: rgba(0, 0, 0, 0.54);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    transition: background 0.2s ease-in-out;
    transition-delay: 0.2s;
    opacity: 1;
}
