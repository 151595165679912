//
//  Social Media
//  _____________________________________________

.social-media {
    &__list {
        @include list-inline(0 10px);
    }

    &__title {
        display: none;
    }

    &__link {
        color: $color-grey3;
        font-size: 2.2rem;

        &:hover {
            color: $color-black;
        }
    }
}
