//
//  Promo Banner
//  ---------------------------------------------

.promo-banner {
    margin-bottom: 50px;

    &__image {
        line-height: normal;
    }

    &__overlay-container {
        width: 100%;
        max-width: $screen__xxl;
        height: 100%;
        position: relative;
        margin: 0 auto;
    }
}

.promo-banner__btn,
a.promo-banner__btn {
    @include btn-arrowed();
    margin-top: 0.7rem;
}

//
//  Mobile
//  ---------------------------------------------
@include max-screen($screen__s) {
    .promo-banner {
        &__image {
            display: flex;
            position: relative;
            height: 0;
            padding-top: 75%;

            img {
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                bottom: 0;
            }
        }
    }
}

@include max-screen($screen__m) {

    .promo-banner {
        &__image {
            line-height: 0;
            margin: 0 -#{$layout__width-xs-indent};
        }

        &__overlay {
            margin: $indent__m 0;
        }
    }

}

//
//  Desktop
//  ---------------------------------------------

@include min-screen($screen__m) {

    .cms-index-index {

        .promo-banner {

            &__overlay {
                max-width: 478px;
            }
        }
    }

    .promo-banner {
        position: relative;
        width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;

        &__overlay {
            position: absolute;
            left: 40px;
            bottom: 50px;
            background: $color-white;
            padding: $indent__m;
            box-sizing: border-box;
            width: calc(80% - #{$indent__m + $indent__m});
        }

        &__image {
            position: relative;
            height: 45vw;
            max-height: 617px;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
            }
        }


    }

    .promo-banner__btn,
    a.promo-banner__btn {
        @include btn-arrowed($size: medium, $load-icon: false);
    }

}

@include min-screen($screen__l) {
    .promo-banner {
        &__overlay {
            width: calc(60% - #{$indent__m + $indent__m});
        }
    }
}
