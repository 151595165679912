//
//  Header General
//  ---------------------------------------------

.page-header {
    margin-bottom: 0;
    z-index: 150;
    background-color: white;

    @include max-screen($screen__m) {
        .catalog-product-view & {
            margin-top: -52px;
        }
    }
}

//
//  Media queries
//  ---------------------------------------------

@include min-screen($screen__m) {
    .page-header {
        position: relative;
    }

    .header.content {
        position: static;
        background-color: white;
        height: 60px;
        padding: $indent__s $indent__xl 0;
    }
}

@include min-screen($screen__l) {
    .header.content {
        padding-top: 24px;
        height: auto;
        padding-left: 40px;
        padding-right: 40px;
    }
}
