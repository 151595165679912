.ldo-product-table {
    padding-bottom: 0 !important; // sass-lint:disable-line no-important
    min-height: auto !important; // sass-lint:disable-line no-important
}

.ldo__table {
    width: 100%;

    td {
        background: white;
        text-align: center;
        line-height: 1;
        padding: 0 !important; // sass-lint:disable-line no-important
        height: 40px;

    @include max-screen($screen__s) {
            width: 10%;
        }
    }

    tr:nth-child(odd) {
        .ldo__table-header--left:after {
            border-color: #f4f4f4;
            border-left-color: #709c85;
        }
        td {
            background: #f4f4f4;
        }
    }

    th {
        text-align: center;
        padding: 0;
    }

}

.ldo__table-header--green {
    background: #41ad4a;
    color: white;
    text-transform: uppercase;
    font-family: $font-family__base;
    font-weight: $font-weight__bold;
    font-size: 16px;

    th {
        text-align: center;
        padding: 12px 10px;
        white-space: nowrap;

        a {
            color: #fff;
            &:hover,
            &:focus {
                color: #fff;
            }
        }

        @media (max-width: 767px) {
            height: 160px;
        }

        @media(max-width: 767px) {
            text-align: right;
            position: relative;

            div {
                display: inline-block;
                transform: rotate(90deg);
                width: 30px;
                position: absolute;
                left: calc(50% - 14px);
                top: 15px;
            }

            span {
                display: inline-block;
                width: 150px;
                font-size: 14px;
                text-align: right;
                color: white;
            }
        }
    }
}

.ldo__table-header--left {
    background: #709c85;
    color: white;
    font-family: $font-family__base;
    font-weight: $font-weight__bold;
    font-size: 14px;
    text-align: left !important; // sass-lint:disable-line no-important
    position: relative;

    .store-uk &,
    .store-de &,
    .store-fr &,
    .store-be_fr &,
    .store-es &,
    .store-it & {
        font-size: 13px;
    }

    .store-it &,
    .store-es & {
        font-size: 12px;
    }

    .store-fr &,
    .store-be_fr & {
        line-height: 1;
    }

    @include min-screen($screen__s) {
        height: 40px;
        width: 200px;

        .store-de &,
        .store-be_fr &,
        .store-fr &,
        .store-it &,
        .store-es & {
            width: 230px;
        }
    }

    @include max-screen($screen__sm) {
        font-size: 11px;
    }

    span {
        display: block;
        padding: 9px 12px;
        line-height: 1;
        box-sizing: border-box;

        @media(min-width: 768px) {
            border-bottom: 1px solid #8cb29f;
            width: calc(100% - 20px);
            height: 40px;
        }
    }

    &--noborder span {
        border: none;
    }

    @include min-screen($screen__s) {
        &:after {
            display: block;
            content: '';
            border: 20px solid white;
            border-left-color: #709c85;
            width: 0;
            height: 0;
            position: absolute;
            right: -20px;
            top: 0;
        }
    }
}

.ldo__table-cell-check {
    text-transform: uppercase;
    font-family: $font-family__base;
    font-weight: $font-weight__bold;
    color: black;
    font-size: 1em;
    display: block;
    padding: 10px 0;
}

.ldo-hidden-xs {
    @include max-screen($screen__s) {
        display: none;
    }
}

.ldo-visible-xs {
    @include min-screen($screen__s) {
        display: none;
    }
}

.ldo__table tfoot tr td {
    background: white !important; // sass-lint:disable-line no-important
    padding: 10px 5px;
    font-size: 12px;

    a{
        padding: 14px 0;
        display: block;
    }
}

