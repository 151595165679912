.c-preview {
  position: relative;
  width: auto;
  max-width: 375px;
  margin: 0 auto;
  padding: 0;
  flex: 0 0 auto;
  perspective: 1000px;

  .c-preview-container__image {
    width: 100%;
    height: auto;
  }
}

.c-preview--loading {
  * {
    transition: opacity 250ms;
    transition-delay: 250ms;
    opacity: 0;
  }

  &::after {
    content: '';
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    animation: loading-spinner 333ms linear infinite;
    animation-delay: 400ms;
  }
}
