$category-block-transition-time: 250ms;
$category-block-transition-ease: ease-in-out;

.category-block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 20px;
}

.category-block__item { 
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;

    a {
        width: 100%;
    }

    .card__image {
        @include max-screen(479px) {
            margin: 0 -15px;
        }
    }

    .card__bottom {
        margin-top: 20px;
    }

    img {
        @include object-fit(cover);
        object-position: center;
        height: 100%;
    }

    @include min-screen(480px) {
        width: 50%;
    }

    @include min-screen($screen__m) {
        width: 25%;
    }

    @include max-screen(480px) {
        .card__btn {
            width: 100%;
        }
    }
}
