//
//  Footer Columns
//  ---------------------------------------------

.footer-column {
    ul {
        @include list-unstyled();
    }

    li {
        &:after {
            content: '›';
            padding-left: 5px;
        }
    }

    a {
        @include unstyled-link();
        display: inline-block;
        padding: 2px 0;
    }
}

strong.footer-column__title {
    display: block;
    margin-bottom: 8px;
    font-weight: $font-weight__semibold;
    font-size: 1.8rem;

    &:after {
        @include icon($icon: angle-down);
        transition: 0.2s;
        float: right;
        position: relative;
        top: 11px;
        opacity: 0.5;
    }
}

.footer-column {
    [aria-expanded="true"] {
        strong.footer-column__title {
            &:after {
                transform: rotate(180deg);
            }
        }
    }
}



//
//  Mobile
//  ---------------------------------------------

@include max-screen($screen__m) {

    strong.footer-column__title {
        cursor: pointer;
    }

}



//
//  Desktop / Tablet
//  ---------------------------------------------

@include min-screen($screen__m) {

    .footer-columns {
        display: flex;
        flex-wrap: wrap;
    }

    .footer-column {
        flex: 1;

        //&:not(.footer-column--has-newsletter) {
        //    padding: 0 10px;
        //}
    }

    .footer-column__content {
        display: block !important; // sass-lint:disable-line no-important
    }

    strong.footer-column__title {
        &:after {
            display: none;
        }
    }

}



//
//  Large desktop
//  ---------------------------------------------

@include min-screen($screen__l) {

    .footer-columns {
        width: 70%;
        flex: 1 1 70%;
    }
}
