/* One page checkout */

.checkout-onepage-success {

    @include min-screen($screen__sm) {
        .order-information-container,
        .success-page-steps-container {
            width: 50%;
            margin-top: $indent__l;
            box-sizing: border-box;
        }

        .order-information-container {
            float: left;
            padding-right: $indent__base;
        }
    
        .success-page-steps-container {
            float: right;
            padding-left: $indent__base;
        }
    
        .success-page-newsletter-container {
            clear: both;
        }
    }

    @include min-screen($screen__m) {
        .print {
            margin-top: 0;
        }
    }

    @include max-screen($screen__xs) {
        .success-page-newsletter-container {
            max-width: 85vw;
            margin: 0 auto;
        }
    }
    

    @include max-screen($screen__sm) {
        .action.primary {
            width: 100%;
        }
    }

    @include screen($screen__s, $screen__m) {
        .order-information-container {
            margin-bottom: $indent__base;
        }
    }

    .success-page-steps-container {
        figure {
            img {
                position: relative;
                top: -8px;
                max-width: 20px !important; // sass-lint:disable-line no-important
                width: 20px !important; // sass-lint:disable-line no-important

                @include max-screen($screen__sm) {
                    max-width: 40px !important; // sass-lint:disable-line no-important
                    width: 40px !important; // sass-lint:disable-line no-important
                }
            }
        }
    }
}

.checkout-success {
    h2 {
        margin-top: 0;
    }
}

.checkout-succes__steps {
    margin-top: $indent__base;
    
    @include min-screen($screen__s) {
        margin-top: $indent__xl;
    }

    @include min-screen($screen__m) {
        margin-top: 0;
    }
}

