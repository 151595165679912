.bloglist {
    &__meta {
        display: flex;
        flex-direction: column;

        @include min-screen($screen__l) {
            flex-direction: row;
        }
    }

    &__description {
        flex: 6;
        margin-bottom: 20px;
    }

    &__tags {
        flex: 5;
        text-align: right;

        @include max-screen($screen__xs) {
            text-align: left;
        }
    }

    &__posts-wrapper {
        position: relative;
    }

    &__posts {
        margin: $indent__base 0 0;

        .ie11 & {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content:space-between;
        }

        @supports (display: grid) {
            display: grid;
            grid-template-columns: auto;
            grid-gap: 20px;

            @include min-screen($screen__xxs) {
                grid-template-columns: 1fr 1fr;
            }

            @include min-screen($screen__l) {
                -ms-grid-columns: 1fr 1fr 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }
    }

    &__post {
        .ie11 &,
        .ie10 & {
            width: calc(25% - 10px);
            margin-bottom: $indent__base;
            box-sizing: border-box;
        }
    }

    &__post-image-wrapper {
        display: block;
        position: relative;
        width: 100%;
        padding-top: 75%; // 4:3 Aspect Ratio
        overflow: hidden;
    }

    &__post-image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }

    &__post-meta {
        @include lib-clearfix();
        font-size: 80%;

        @include max-screen($screen__xs) {
            display: none;
        }
    }

    &__post-meta-item {
        float: left;
        color: $color-grey2;

        &:not(:last-child):after {
            content: '•';
            margin: 0 10px;
        }
    }

    &__post-title {
        font-weight: $font-weight__semibold;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__pagination {
        margin: 20px 0;

        .pages {
            display: flex;
            justify-content: center;
        }

        a.page,
        a.action {
            cursor: pointer;
        }
    }

    &__loader {
        @include lib-loader();
        display: none;
        position: absolute;
    }
}
