/*  =========================================================================
    Loading screen
    ========================================================================= */

.c-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  transition: all 1s;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    border: 0.5em solid currentColor;
    border-right-color: transparent;
    animation: loading-spinner 500ms linear infinite;
  }

  &:not(.c-loading--visible) {
    pointer-events: none;
    opacity: 0;

    &::after,
    .c-loading__message {
      display: none;
    }
  }
}

.c-loading--error {
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background: transparent;
    box-shadow: -2em 0 currentColor, 2em 0 currentColor;
    transform: translate(-50%, -50%) translateY(-4em);
  }

  &::after {
    animation: none;
    border-bottom-color: transparent;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes loading-spinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
    opacity: 1;
  }

  to {
    transform: translate(-50%, -50%) rotate(0.99999turn);
    opacity: 1;
  }
}


.c-loading__message {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(4em);
  transition: none !important;
}

.c-loading__message--error {
  display: none;
}

.c-loading--error {
  .c-loading__message {
    display: none;
  }

  .c-loading__message--error {
    display: block
  }
}

:lang(nl) .c-loading__message:not([lang="nl"]),
:lang(en) .c-loading__message:not([lang="en"]),
:lang(fr) .c-loading__message:not([lang="fr"]),
:lang(de) .c-loading__message:not([lang="de"]) {
  display: none !important;
}
