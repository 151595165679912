//
//  Block order details view
//  ---------------------------------------------


.block-order-details-view {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include boxContent;
}
