//
//  Color variables
//  _____________________________________________

// Colors
$color-warm-red: #f0493e;
$color-matt-black: #1f1f1f;
$color-success-green: #00cc52;

$color-grey1: #343434;
$color-grey2: #4e4e4e;
$color-grey3: #858585;
$color-grey4: #969696;
$color-grey5: #b8b8b8;
$color-grey6: #e8e8e8;
$color-grey7: #f7f7f7;

// Neutral
$color-black: $color-matt-black;
$color-white: #ffffff;

//
//  Color nesting
//  ---------------------------------------------

$primary__color: $color-black;
$primary__color__dark    : darken($primary__color, 35%);
$primary__color__darker  : darken($primary__color, 13.5%);
$primary__color__lighter : lighten($primary__color, 29%);
$primary__color__light   : lighten($primary__color, 45%);

//$secondary__color: $color-black;
//$secondary__color__dark    : darken($secondary__color, 35%);
//$secondary__color__darker  : darken($secondary__color, 13.5%);
//$secondary__color__lighter : lighten($secondary__color, 29%);
//$secondary__color__light   : lighten($secondary__color, 45%);
//
//$tertiary__color: $color-black;
//$tertiary__color__dark    : darken($tertiary__color, 35%);
//$tertiary__color__darker  : darken($tertiary__color, 13.5%);
//$tertiary__color__lighter : lighten($tertiary__color, 29%);
//$tertiary__color__light   : lighten($tertiary__color, 45%);

//$panel__background-color: $color-black;

//$active__color: $primary__color;
//$error__color            : $color-red10 !default;
