.tbw-form__field {
    position: relative;
    &.success {
        &:after {
            @include icon($icon: checkmark);
            position: absolute;
            right: 10px;
            top: 14px;
        }
    }
}

.tbw-file {
    &__input {
        display: none;
    }

    &__btn {
        @include btn();
        display: inline-flex;
        align-items: center;

        &:before {
            @include icon($icon: upload-white);
            margin-right: 10px;
            transform: scale(0.8);
        }
    }
}