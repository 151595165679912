//
//  Blog Post
//  _____________________________________________

.blog-post-view {

    // Page-builder
    [data-content-type="row"],
    [data-role="row"] {
        &[data-appearance="contained"] {
            margin: 0 auto;
            max-width: $contained__max_width;
        }
    }

    .blog-post-featured-image {
        width: 100%;
    }

    .blog-post-info {
        margin: 0;
        padding: 0;
    }

    .blog-post-header {
        margin: 0 auto;
        max-width: $contained__max_width;
    }

    .blog-post-info-item {
        color: $color-grey4;
        display: inline-block;
        list-style-type: none;
        margin-right: 20px;
    }

    .blog-post-info-item-icon {
        position: relative;
        top: 5px;
        margin-right: 5px;
    }

    .blog-post-title {
        margin-top: 40px;
    }

    .blog-post-content {
        max-width: 890px;
        margin: 0 auto;
        div[data-enable-parallax="0"] {
            margin: 0 !important;  // sass-lint:disable-line no-important
            padding: 0 !important;  // sass-lint:disable-line no-important
        }


    }

    .blog-author {
        margin: 0 auto 30px;
        max-width: $contained__max_width;
        .blog-about-author-content {
            background-color: $color-grey7;
            margin-top: 20px;
            padding: 0 15px 25px;
        }
    }

    .blog-post-tags {
        margin: 0 auto;
        max-width: $contained__max_width;
    }
}
