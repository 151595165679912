
img.pagebuilder-mobile-hidden,
img.pagebuilder-mobile-only {
    display: none;
}

.image-full-width {
    img.pagebuilder-mobile-hidden,
    img.pagebuilder-mobile-only {
        width: 100%;
    }
}

@include max-screen($screen__s) {
    img.pagebuilder-mobile-only {
        display: inline-block;
    }
}

@include min-screen($screen__s) {
    img.pagebuilder-mobile-hidden {
        display: block;
    }
}
