//
//  Typography variables
//  _____________________________________________

//
//  Fonts
//  ---------------------------------------------

//  Font families
$font-family-name__base			: 'Calibri';
$font-family-name__base-fallback: 'Carlito';
$font-family-name__headings     : 'Frutiger';
$font-family__base				: $font-family-name__base, $font-family-name__base-fallback, $font-family__sans-serif;
$font-family__headings			: $font-family-name__headings, $font-family__base;
$font-family__secondary         : 'VinceHand', $font-family__serif;

//  Sizes
$font-size__base				: 16px;
$font-size__l                   : 1.125em;
$font-size__s                   : 0.8125em;

// Weight
$font-weight__heavier           : 600; // 500 doesn't exist for Source Sans Pro font

//  Line heights
$line-height__base				: 1.5;

//  Colors
$text__color					: $color-grey1;
$text__color__intense			: $color-matt-black;
$text__color__muted				: $color-grey3;

//
//  Paragraphs
//  ---------------------------------------------

$p__margin-top                  : 0 !default;
$p__margin-bottom               : 13px;

//
//  Headings
//  ---------------------------------------------

$heading__font-family__base     : $font-family-name__headings;
$heading__font-weight__base     : $font-weight__bold;
$heading__color__base           : $text__color__intense;

$h1__font-size					: 2.8rem;
$h1__font-size-desktop			: 3.6rem;
$h1__font-color                 : $heading__color__base;
$h1__font-weight				: $heading__font-weight__base;

$h2__font-size					: 2.2rem;
$h2__font-size-desktop			: 3rem;
$h2__font-color                 : $heading__color__base;
$h2__font-weight				: $heading__font-weight__base;
$h2__margin-top					: 20px;

$h3__font-size					: 1.8rem;
$h3__font-size-desktop			: 2.3rem;
$h3__font-color                 : $heading__color__base;
$h3__font-weight				: $heading__font-weight__base;
$h3__margin-top					: 20px;
$h3__margin-bottom				: 20px;

$h4__font-size					: 1.6rem;
$h4__font-size-desktop			: 2rem;
$h4__font-color                 : $heading__color__base;
$h4__font-weight				: $heading__font-weight__base;

$h5__font-size					: 1.3rem;
$h5__font-size-desktop			: 1.62rem;
$h5__font-color                 : $heading__color__base;
$h5__font-weight				: $heading__font-weight__base;

$h6__font-size					: 1.4rem;
$h6__font-size-desktop			: 1.2rem;
$h6__font-color                 : $heading__color__base;
$h6__font-weight				: $heading__font-weight__base;

//
//  Borders
//  ---------------------------------------------

$border-color__base             : $color-grey6;
$border-color__darker           : $color-grey5;

//
//  Links
//  ---------------------------------------------

$link__color                    : $text__color;
$link__text-decoration          : underline;

$link__visited__color           : $text__color;
$link__visited__text-decoration : underline;

$link__hover__color             : $text__color;
$link__hover__text-decoration   : none;

$link__active__color            : $text__color;
$link__active__text-decoration  : underline;

//
//  Lists
//  ---------------------------------------------

$list-item__margin-bottom       : 0;
$dt__margin-bottom              : 0;
