//
//  Color picker
//  ---------------------------------------------

$color-picker-size: 27px;

.color-picker {
    margin: 20px 0;

    &__tooltip {
        @include lib-tooltip(
            $_tooltip-position        : top,
            $_tooltip-selector-content: '.color-picker__content',
            $_tooltip-selector-toggle : '.color-picker__toggle',
            $_tooltip-min-width:        0
        );
        display: inline-block;
       
        @include max-screen($screen__s) {
            &:not(:last-child) {
                margin-right: 9px;
            }
        }

        @include min-screen($screen__m) {
            margin-right: $indent__l / 2;
        }

        .color-picker__toggle {
            cursor: pointer;
        }

        &.active {
            cursor: default;
            position: relative;
            pointer-events: none;
            
            &:after {
                @include icon($icon: 'checkmark-white');
                position: absolute;
                top: 10px;
                left: 7px;
            }
        }

        &.color-picker__tooltip--has-border {
            .color-picker__toggle {
                box-shadow: inset 0 0 0 1px $border-color__base;
            }

            &.active {
                &:after {
                    @include icon($icon: 'checkmark');
                }
            }
        }

        .color-picker__content {
            transform: translate(-50%, 0%);
            padding: 8px 16px;
            margin-left: 50%;
            margin-bottom: 8px;
            background-color: $color-matt-black;
            border-color: $color-matt-black;
            color: white;
            white-space: nowrap;
            font-size: 1.7rem;

            &:before,
            &:after {
                left: 50%;
            }

            &::before {
                border-top-color: $color-matt-black;
            }

            &:after {
                border-top-color: $color-matt-black;
            }
        }
    }

    &__toggle {
        display: block;
        width: $color-picker-size;
        height: $color-picker-size;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

}
