.catalogsearch-result-index {
    .page-title-wrapper {
        text-align: center;
        margin-bottom: $indent__m;
    }

    .page-title {
        margin-bottom: 30px;
    }

    #amsearch-result-tabs {
        &.is-hidden {
            display: none;
        }

        .data.item.content {
            .amsearch-item {
                margin-bottom: 10px;
            }
        }
    }

    .column.main {
        width: 100%;
    }

    #layered-filter-block {
        box-sizing: border-box;
        margin: 0 15px;

        @include min-screen($screen__m) {
            margin: 0;
        }
    }

    .amsearch-result-tabs-sidebar {
        box-sizing: border-box;

        @include min-screen($screen__m) {
            width: 25%;
            float: left;
            margin: 0;
        }
    }
}

.search-box {
    #search {
        text-align: center;
        border-bottom: 1px solid black;
    }

    .block.block-search {
        margin: 0 $layout__width-xs-indent;

        @include min-screen($screen__m) {
            margin: 0 auto;
            max-width: 700px;
        }

        .control {
            margin: 0;
        }
    }
}

.result-count {
    width: 100%;
    text-align: center;
    font-weight: 700;
    margin-bottom: $indent__xl;
}

#amsearch-result-tabs {
    width: 100vw;
    margin: 0 -#{$layout__width-xs-indent};

    @include min-screen($screen__m) {
        width: initial;
    }

    .item.content {
        border: none;
        padding: 0;

        @include min-screen($screen__m) {
            padding: 15px;
            border: 1px solid $color-grey6;
            margin-top: 50px; // To hide the border
        }
        
        .search.results {
            box-sizing: border-box;
            margin: 0;

            & > .block{
                padding: 0 15px;
            }

            .products.grid {
                margin: 30px 15px;

                @include min-screen($screen__m) {
                    margin: unset;
                }

                .product-item {
                    padding: 0 20px;
                }
            }

            @include min-screen($screen__m) {
                padding-left: $indent__xl;
                float: left;
                width: 75%;
            }
        }
    }

    .search.results {
        margin: 0 $layout__width-xs-indent;
    }

    .data.item.title {
        background: white;
        border: none;
        margin: 0;
        position: relative;

        &:after {
            @include icon($icon: angle-up);
            position: absolute;
            right: 20px;
            top: 25px;

            @include min-screen($screen__m) {
                display: none;
            }
        }

        &:first-child {
            @include min-screen($screen__m) {
                .switch {
                    border-left: 1px solid $border-color__base;
                }
            }
        }

        .switch {
            cursor: pointer;
            color: $color-black;
            background: white;
            border: none;
            border-top: 1px solid $color-grey6;
            padding: 5px $layout__width-xs-indent;

            @include min-screen($screen__m) {
                font-size: 1.8rem;
                padding: $layout__width-xs-indent $indent__xl;
                color: $color-matt-black;
                border: 1px solid $border-color__base;
                border-left: none;
                background: $color-grey7;
            }

            &:hover {
                background: white;
            }
        }

        .amsearch-results-count {
            &:not(:empty) {
                &:before {
                    content: '(';
                }

                &:after {
                    content: ')';
                }
            }
        }

        &.active {
            background: white;
            position: relative;

            .switch {
                color: $color-grey3;

                @include min-screen($screen__m) {
                    background: white;
                    border-right: 1px solid $border-color__base;
                    border-bottom: 1px solid white;
                    border-left: 1px solid $border-color__base;
                }
            }

            &:after {
                @include icon($icon: angle-down);
                position: absolute;
                right: 20px;
                top: 25px;

                @include min-screen($screen__m) {
                    display: none;
                }
            }

            .amsearch-results-count {
                display: inline;
            }
        }

        &.last {
            border: none;
            margin: 0;
        }
    }
}
