//
//  Warranty claimer
//  ---------------------------------------------

.catalog-product-view {
    .sparepart-form {
        display:none;
        form.formbuilder.form {
            margin: 20px 0;
            border: 1px solid #e8e8e8;
            padding: 25px 10px;
        }
        input[type="radio"] {
            display: none;
        }
    }
}

.warranty-claim {
    .warranty-claim-content {
        border: 1px solid $border-color__base;
        margin: 20px 0;
        padding: 15px;

        .label {
            label.required:after {
                content: " *";
                color: $color-warm-red;
                font-weight: 400;
                font-size: 13px;
            }
        }

        .input-box {
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
            }
        }
    }
}
