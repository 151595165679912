.logo {
    margin: 0 0 10px 0;
    z-index: 0;
}

@include screen($screen__m, $screen__l) {
    .logo {
        width: 15%;
        margin-top: 5px;
    }
}

.ie10,
.ie11 {
    .logo {
        height: 32px;
    }
}

@include max-screen($screen__s) {
    .logo {
        width: 133px;
        height: 25px;
    }
}
