.gallery {
    .gallery__image-list-item--video {
        overflow: hidden;
        opacity: 1;
        transition: opacity 1s ease-in;

        .autheos-product-video {
            width: 100%;
            height: 100%;
        }

        .autheos-videothumbnail {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .autheos-videothumbnail__type-label,
        .autheos-videothumbnail__language-label {
            display: none;
        }
    }

    .gallery__image-list-item--video-not-loaded {
        display: none;
        opacity: 0;
        transition: opacity 1s ease-in;
    }

    #videoplayer_html5_api {
        @include object-fit(cover);
    }

    @include min-screen($gallery-breakpoint__desktop) {
        .gallery__image-list-item--video {
            position: relative;
            height: 0 !important;
            padding-bottom: (9/16) * 100%; // calculate aspect ratio

            .autheos-product-video {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }
    }
}