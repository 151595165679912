.products.wrapper ~ .toolbar .limiter {
    display: none;
}

.toolbar {
    .modes {
        display: none;
    }
}

.limiter {
    .page-products .toolbar & {
        display: block;
        order: 2;
        margin-left: $indent__xl;

        .label {
            font-weight: $font-weight__heavier;
        }
    }
}

//
//  Show per page controls
//  ---------------------------------------------

.toolbar-products {
    display: flex;
    flex-direction: row;
    padding-left: 0;

    .control {
        position: relative;

        .limiter-options {
            min-width: 60px;
        }

        .limiter {
            position: relative;

            .products.wrapper ~ .toolbar & {
                display: none;
            }
        }
    }
}

//
//  Product sorting options
//  ---------------------------------------------

.toolbar-sorter {
    padding: 0;
    position: relative;
    display: flex;

    @include max-screen($screen__l) {
        &.sticky {
            position: fixed;
            top: 0;
            z-index: 1000;
        }
    }

    .sorter-label {
        order: 1;
        position: relative;
        margin-top: $indent__s;
        font-weight: $font-weight__heavier;
        font-size: 1.5rem;
    }

    .sorter-options {
        order: 2;
        min-width: 90px;
        position: relative;
        margin: 0 0 0 $indent__s;
        padding-right: 30px;
    }

    .sorter-action {
        margin-left: $indent__s;
        order: 3;

        &:before {
            padding-top: 5px;
        }
    }

    &:after {
        right: 45px;
    }

    .toolbar-sorter__filter {
        display: flex;
        align-items: center;
        margin: 0 20px 0 0;
        padding: 0;
        background: transparent;
        border: none;
        color: $color-black;
        cursor: pointer;
        line-height: 1;

        strong {
            font-weight: 600;
        }

        @include min-screen($screen__l) {
            display: none;
        }
    }

    .toolbar-sorter__filter-icon {
        display: block;
        width: 18px;
        height: 20px;
        position: relative;
        top: -1px;
        left: -4px;
        background-repeat: no-repeat;
        transform: scale(0.7);
    }
}

.toolbar-amount {
    .page-products .toolbar & {
        display: none;
    }
}

@include max-screen($screen__l) {
    .limiter {
        .page-products .toolbar & {
            margin-left: 0;
        }

        .limiter-options {
            padding-right: $indent__m;
            color: $color-grey1;
            font-size: 1.5rem;
        }

        &:after {
            left: 157px;
        }

        label {
            font-size: 1.5rem;
        }
    }

    .toolbar {
        &-sorter {
            width: 100%;
            margin-left: -#{$indent__xl};
            padding: 0 $indent__m 0 $indent__xl;
            background-color: $color-grey7;
            border: none;

            .sorter-options {
                width: auto;
                margin-left: 0;
                background-color: $color-grey7;
                border: none;
                color: $color-grey1;
                font-size: 1.5rem;
                line-height: 45px;
            }

            .sorter-label {
                display: block;
                margin-top: 10px;
                margin-left: 0;
            }

            &:after {
                left: calc(100% - 90px);
                right: initial;
            }
        }

        &.toolbar-products {
            display: flex;
            flex-direction: column;
            margin: 0 -4px;
            padding: 0 15px;
            text-align: left;
            overflow: hidden;
        }
    }
    .toolbar-sorter__sorter-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-grow: 2;
        text-align: right;
    }
}

@include max-screen($screen__xs) {
    .toolbar-sorter {
        width: 100%;
    }
}
