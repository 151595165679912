//
//  Stock
//  ---------------------------------------------

.product-info-main {
    .stock {
        &.available,
        &.unavailable {
            font-size: 1.3rem;
            font-weight: $font-weight__regular;
            text-transform: none;

            &:before {
                margin-right: 3px;
            }
        }

        &.available {
            &:before {
                @include icon($icon: checkmark);
            }
        }

        &.unavailable {
            &:before {
                @include icon($icon: close);
                transform: scale(0.75);
                position: relative;
                top: 4px;
            }
        }
    }
}
